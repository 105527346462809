let comments = [{
        "I4JZGgwmoi0": 2,
        "60jGMzGQ3IE": 1,
        "TDy0B20kZlY": 2,
        "QfAQkufmjuA": 3,
        "undefined": 3,
        "LEAexG6YkX4": 4,
        "cvtXtJRwO6U": 1,
        "gmWbLpUty9w": 3,
        "iAGX1qUwjus": 3,
        "FvqTcm4hC6o": 3,
        "lSsDsr4WIYY": 3,
        "PI4zVUPluXU": 5,
        "Qlf0lDz9moU": 15,
        "pE1DzZ7Xn9k": 10,
        "5XxtKwf_PdE": 4,
        "nxRR3CBRbVM": 4,
        "0FK0VRk1EQM": 53,
        "tNm8HW8mL5c": 15,
        "ZdL5mbgYe8Q": 8,
        "17iBWOBIakg": 3,
        "BkETsfqp5co": 26,
        "t4EOIs7IYf0": 47,
        "RknpDfZWgFs": 12,
        "55g_205F0r4": 1,
        "cWf6e4abq_4": 310,
        "C6KjJVA_7Lo": 61,
        "EFTs82yZsG8": 1,
        "xbvcODHO8Ig": 1,
        "ZvOo6P0dS8o": 1,
        "QsjpYurZ9zc": 1,
        "1tMWGC2vrLc": 1,
        "hiP4gxbFbtQ": 1,
        "aV3dC1Sjwhw": 2,
        "XU_DyriIqjs": 1,
        "CyPNGyJy7XM": 2,
        "eIWNr0Piz1o": 1,
        "CuA6HuZWEU0": 4,
        "3vJmFuPMmBU": 1,
        "zHSfh87GnFc": 135,
        "2kZZ8GnbURA": 1,
        "zLw_XO50yAo": 3,
        "JABL3qfzuBM": 1,
        "95P_sJFNQw8": 1,
        "pI0j6tYc7j0": 2,
        "yy3aj-SMn28": 2,
        "publishedAt": "2018-07-27T23:51:57.000Z",
        "likeCount": 0,
        "comment": "Man I was just wondering the other day if you still had the DR. Cool.",
        "videoId": "9kyqxCPSboU",
        "authorThumbnail": "https://yt3.ggpht.com/-HiCidbqSFtw/AAAAAAAAAAI/AAAAAAAAAAA/wzbxMcI_T7k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andy Black",
        "commentId": "Ugy1A3DXk2eFQmwJi0Z4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2018-07-24T06:57:03.000Z",
        "likeCount": 0,
        "comment": "Trail 37 is a badass trail!",
        "videoId": "yy3aj-SMn28",
        "authorThumbnail": "https://yt3.ggpht.com/-Pq7iyQeh6fc/AAAAAAAAAAI/AAAAAAAAAAA/2vJe85eQebY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mr. SRG",
        "commentId": "UgwFcteq7jufL9Vw_3J4AaABAg"
    },
    {
        "publishedAt": "2018-05-10T15:03:10.000Z",
        "likeCount": 0,
        "comment": "Nice videos.  Nice riding.  Beautiful trails.  Succinct trail review.  Quiet bike.  Great to see a DR650 getting exercised.  No click bait.  Versus all the ranting opinion videos of beginners who can barely manage a DR down a gravel road, piling on loudness mods and reviewing gear.",
        "videoId": "pI0j6tYc7j0",
        "authorThumbnail": "https://yt3.ggpht.com/-WG9XeBofeAM/AAAAAAAAAAI/AAAAAAAAAAA/xVTvDl7FAt8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Artful Alias",
        "commentId": "UgyCQ1-onY1jxO_ySWl4AaABAg"
    },
    {
        "publishedAt": "2018-04-20T02:50:45.000Z",
        "likeCount": 0,
        "comment": "What a bummer. Broken bones are never any fun.",
        "videoId": "95P_sJFNQw8",
        "authorThumbnail": "https://yt3.ggpht.com/-HiCidbqSFtw/AAAAAAAAAAI/AAAAAAAAAAA/wzbxMcI_T7k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andy Black",
        "commentId": "UgyIlqukJRkBheZ7smJ4AaABAg"
    },
    {
        "publishedAt": "2018-03-23T03:14:27.000Z",
        "likeCount": 0,
        "comment": "Trail&#39;s so steep I felt like I was going to fall over backwards in my chair just from watching this.",
        "videoId": "JABL3qfzuBM",
        "authorThumbnail": "https://yt3.ggpht.com/-HiCidbqSFtw/AAAAAAAAAAI/AAAAAAAAAAA/wzbxMcI_T7k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andy Black",
        "commentId": "UgyqYnaVthlfU6K7x5J4AaABAg"
    },
    {
        "publishedAt": "2018-03-23T01:46:56.000Z",
        "likeCount": 0,
        "comment": "Man that was a steep trail. I bet you went up over 1,000 ft. in elevation on that one.",
        "videoId": "pI0j6tYc7j0",
        "authorThumbnail": "https://yt3.ggpht.com/-HiCidbqSFtw/AAAAAAAAAAI/AAAAAAAAAAA/wzbxMcI_T7k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andy Black",
        "commentId": "Ugzc-UodnufobFrxfK54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2017-11-23T02:32:48.000Z",
        "likeCount": 1,
        "comment": "Just did it yesterday after a downpour the day before. Fun as could be! Controlled slide all the way in, 2 foot deep creek crossing, then 3rd gear pinned to get out toward the northwest. I&#39;m thinking you should try a little harder.. Maybe not so fatal!?!",
        "videoId": "zLw_XO50yAo",
        "authorThumbnail": "https://yt3.ggpht.com/-ETdXTeIBl-8/AAAAAAAAAAI/AAAAAAAAAAA/phvn6Gkd_QA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hondo Flatty",
        "commentId": "UgwBk7TKLjg5sA-rekp4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2017-10-18T06:37:10.000Z",
        "likeCount": 0,
        "comment": "Where is this place. Is it a ohv park?",
        "videoId": "2kZZ8GnbURA",
        "authorThumbnail": "https://yt3.ggpht.com/-b20gUlgH_vM/AAAAAAAAAAI/AAAAAAAAAAA/Bh3f2jKTDaE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steve Mcluckie",
        "commentId": "Ugwkwao1P1b_Z5ifhZh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2017-04-16T19:32:39.000Z",
        "likeCount": 0,
        "comment": "awesome video!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-h70JZeOyGzY/AAAAAAAAAAI/AAAAAAAAAAA/Y8oaz5sQKk8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Redwood Bigfoot",
        "commentId": "UgjPPacFxD3yNXgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2017-04-09T17:36:22.000Z",
        "likeCount": 0,
        "comment": "That wasn&#39;t a crash, it was a minor &#39;lay-down&#39;. I do it all the time!",
        "videoId": "3vJmFuPMmBU",
        "authorThumbnail": "https://yt3.ggpht.com/-EEHWujlK6dw/AAAAAAAAAAI/AAAAAAAAAAA/r1tqwxSb-20/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "DiRty650",
        "commentId": "UgiH3zwEN2UrpHgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2017-03-19T11:49:39.000Z",
        "likeCount": 0,
        "comment": "That trail looks like a lot of fun, nice video! I&#39;m hoping to get up to stonyford this summer when I&#39;m in California. Already have plans to ride up the pch from Santa Barbara and then ride at Carnegie. I have a DR650 too. Can&#39;t wait to come out there!",
        "videoId": "CuA6HuZWEU0",
        "authorThumbnail": "https://yt3.ggpht.com/-UCkJ9a5TTtM/AAAAAAAAAAI/AAAAAAAAAAA/G6KzlGdAdOI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Trevor Jameson",
        "commentId": "Ugi8dFICX4cFsHgCoAEC"
    },
    {
        "publishedAt": "2016-11-30T18:17:24.000Z",
        "likeCount": 0,
        "comment": "I have never taken that trail.. not as bad as I thought it would be. hahahha",
        "videoId": "eIWNr0Piz1o",
        "authorThumbnail": "https://yt3.ggpht.com/-zCrvXsJDZ2A/AAAAAAAAAAI/AAAAAAAAAAA/HxRA_MJT4L8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TWiGs ADV - Keep Off The Pavement",
        "commentId": "UggI5gpR-eZbbngCoAEC"
    },
    {
        "publishedAt": "2016-09-07T23:47:08.000Z",
        "likeCount": 0,
        "comment": "I&#39;m thinking of getting a DR650 for my first bike as well.",
        "videoId": "CyPNGyJy7XM",
        "authorThumbnail": "https://yt3.ggpht.com/-gj-1znL-n3M/AAAAAAAAAAI/AAAAAAAAAAA/NJXxZjF7u18/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Luke Hudgins",
        "commentId": "UghRCqR9NtlVoHgCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2016-06-07T03:26:38.000Z",
        "likeCount": 0,
        "comment": "was that Ron Spini in the bushes??",
        "videoId": "XU_DyriIqjs",
        "authorThumbnail": "https://yt3.ggpht.com/-qm_Yaqr2No8/AAAAAAAAAAI/AAAAAAAAAAA/0uzdvDmxQDI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "david portus",
        "commentId": "Ugh9GyfGsjrv-XgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2016-06-07T03:23:48.000Z",
        "likeCount": 0,
        "comment": "go baby go !!!",
        "videoId": "aV3dC1Sjwhw",
        "authorThumbnail": "https://yt3.ggpht.com/-qm_Yaqr2No8/AAAAAAAAAAI/AAAAAAAAAAA/0uzdvDmxQDI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "david portus",
        "commentId": "UgjVPoY6sOAo6HgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2016-05-12T02:45:44.000Z",
        "likeCount": 1,
        "comment": "I was watching the video and waiting for you to get to that hill!  My brother, cousin and I had a hell of a time getting out of there in 88 or 89.  I was leading and having the same feeling that we were not going to be able to turn around and make it back up.  Those Stonyford lower black diamonds give me nightmares.   Glad you made it out!",
        "videoId": "zLw_XO50yAo",
        "authorThumbnail": "https://yt3.ggpht.com/-bs3J015L0qE/AAAAAAAAAAI/AAAAAAAAAAA/MOr1IC5-A0E/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kyle Sherman",
        "commentId": "UggqLR0BzhTfq3gCoAEC"
    },
    {
        "publishedAt": "2016-05-05T14:20:27.000Z",
        "likeCount": 0,
        "comment": "Demit , so awsome terrain , Like form Polak :D",
        "videoId": "CyPNGyJy7XM",
        "authorThumbnail": "https://yt3.ggpht.com/-rx_As4q7geM/AAAAAAAAAAI/AAAAAAAAAAA/gkbmkCdG-ek/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Masterpiece Collector",
        "commentId": "Ugj9RDDPtsWp_HgCoAEC"
    },
    {
        "publishedAt": "2016-04-14T01:35:08.000Z",
        "likeCount": 0,
        "comment": "Nice trails. Great video. Beautiful scenery.",
        "videoId": "aV3dC1Sjwhw",
        "authorThumbnail": "https://yt3.ggpht.com/-JOVzfTnYwc8/AAAAAAAAAAI/AAAAAAAAAAA/VIHSbX-l6No/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Voyage2NoWher3",
        "commentId": "UgiSqd5x3OyyxHgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2016-04-10T01:36:05.000Z",
        "likeCount": 0,
        "comment": "Yeah that looks fun!",
        "videoId": "hiP4gxbFbtQ",
        "authorThumbnail": "https://yt3.ggpht.com/-BMLIsfSsotA/AAAAAAAAAAI/AAAAAAAAAAA/UMWQQJKGZ0k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "brandtagone",
        "commentId": "UggufjwVv0r1RHgCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2016-04-08T13:03:14.000Z",
        "likeCount": 0,
        "comment": "Great yet another place I gotta ride before I die, nice vid!",
        "videoId": "1tMWGC2vrLc",
        "authorThumbnail": "https://yt3.ggpht.com/-BMLIsfSsotA/AAAAAAAAAAI/AAAAAAAAAAA/UMWQQJKGZ0k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "brandtagone",
        "commentId": "UgjepNtFGENyQXgCoAEC"
    },
    {
        "publishedAt": "2016-02-02T06:33:18.000Z",
        "likeCount": 0,
        "comment": "Too many great memories to count on that mountain.",
        "videoId": "yy3aj-SMn28",
        "authorThumbnail": "https://yt3.ggpht.com/-KlRAFumIzLw/AAAAAAAAAAI/AAAAAAAAAAA/0Z3fEmstsSQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mdemartile",
        "commentId": "Ugj19ZYSvrzCYngCoAEC"
    },
    {
        "publishedAt": "2015-12-23T06:47:32.000Z",
        "likeCount": 1,
        "comment": "Good job on moving it. I found some neck high at middle creek luckily I saw it last second. people that put stuff like this on trails should be dropped on site.",
        "videoId": "QsjpYurZ9zc",
        "authorThumbnail": "https://yt3.ggpht.com/-P3u8PsHSciM/AAAAAAAAAAI/AAAAAAAAAAA/NSRjd98aGnE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rebel tex",
        "commentId": "UggQAWE9ltGSX3gCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2015-11-13T08:51:40.000Z",
        "likeCount": 0,
        "comment": "Good to see others with DR650 based channels. I subscribed. Here is a video of mods I have done to mine if interested. <a href=\"https://www.youtube.com/watch?v=R7htXAg13tM\">https://www.youtube.com/watch?v=R7htXAg13tM<\/a>",
        "videoId": "ZvOo6P0dS8o",
        "authorThumbnail": "https://yt3.ggpht.com/-JOVzfTnYwc8/AAAAAAAAAAI/AAAAAAAAAAA/VIHSbX-l6No/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Voyage2NoWher3",
        "commentId": "Ugjn5zILutGQPHgCoAEC"
    },
    {
        "publishedAt": "2015-10-11T19:52:22.000Z",
        "likeCount": 1,
        "comment": "Hey Ph8tel. We just did this trail on 10/9. Nightmare getting back up is an understatement. A real as whooping physically and one of the stock DRZ&#39;s with us almost could not do it with crappy tire.",
        "videoId": "zLw_XO50yAo",
        "authorThumbnail": "https://yt3.ggpht.com/-TuPnR8a2vO0/AAAAAAAAAAI/AAAAAAAAAAA/wZcbvyZl7MU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mark Pearce",
        "commentId": "UgipKhIUiILrDHgCoAEC"
    },
    {
        "publishedAt": "2015-10-04T09:25:34.000Z",
        "likeCount": 0,
        "comment": "Thanks for watching",
        "videoId": "CuA6HuZWEU0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugh2GNciDNdfnngCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2015-10-03T09:07:57.000Z",
        "likeCount": 0,
        "comment": "Question....why are you coasting most of the downhill?",
        "videoId": "xbvcODHO8Ig",
        "authorThumbnail": "https://yt3.ggpht.com/-RM1MCkucpBg/AAAAAAAAAAI/AAAAAAAAAAA/XSzhiBjsVEo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David Brown",
        "commentId": "Ugg5JFHWMbPQVngCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2015-10-01T18:18:38.000Z",
        "likeCount": 0,
        "comment": "Awesome!  I need to go riding bad. I can feel it down in my plums.",
        "videoId": "EFTs82yZsG8",
        "authorThumbnail": "https://yt3.ggpht.com/-4v1geDu0bWM/AAAAAAAAAAI/AAAAAAAAAAA/QlMXIIAL5i4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Swizzle556",
        "commentId": "Ugi7SZqHjSTBAXgCoAEC"
    },
    {
        "publishedAt": "2015-09-08T05:07:04.000Z",
        "likeCount": 0,
        "comment": "Nice ride",
        "videoId": "CuA6HuZWEU0",
        "authorThumbnail": "https://yt3.ggpht.com/-BZ0pE52f7Kw/AAAAAAAAAAI/AAAAAAAAAAA/qhle7HkaamY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Matt McManiac",
        "commentId": "Ugja2N2n5W6Z_3gCoAEC"
    },
    {
        "publishedAt": "2015-07-07T22:16:23.000Z",
        "likeCount": 0,
        "comment": "many thanks :)",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-iRIPgDKHefw/AAAAAAAAAAI/AAAAAAAAAAA/06Ixp4LfgMU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "brett silver",
        "commentId": "Ugidi4tRWsDnLHgCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2015-06-18T13:43:24.000Z",
        "likeCount": 0,
        "comment": "Low-life hybrids!  Wish I could nuke you out of the surface of Earth.  What a lousy country with fucking morons that leads them.  Letting people eat animals, you are SOOOO disgusting you all make me want to puke in your leader&#39;s mouth, he might like it.  Would you like some too you stupid fucking hybrids of humans?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NipYAycJon0/AAAAAAAAAAI/AAAAAAAAAAA/kwUnC4jFjY4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "God TheFather",
        "commentId": "UggS2xpYY4MgwXgCoAEC"
    },
    {
        "publishedAt": "2015-05-14T06:38:26.000Z",
        "likeCount": 0,
        "comment": "Thanks for the tour. Too bad you didn&#39;t have another rider to chat with about the trail. I&#39;ll check the map to see where these trails are. Hope to ride Stoneyford this year.",
        "videoId": "CuA6HuZWEU0",
        "authorThumbnail": "https://yt3.ggpht.com/-JvLcbFha0bI/AAAAAAAAAAI/AAAAAAAAAAA/8D_OdvAU2WI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "osimnod",
        "commentId": "UgiQ4O6TEcsgJngCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2015-05-07T02:15:16.000Z",
        "likeCount": 0,
        "comment": "What kind of vid/cam do you use ?",
        "videoId": "55g_205F0r4",
        "authorThumbnail": "https://yt3.ggpht.com/-6_xdZHLT-bs/AAAAAAAAAAI/AAAAAAAAAAA/ApLDaS1oxQw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gravel1stSt.last",
        "commentId": "UghG7VJaWHozI3gCoAEC"
    },
    {
        "publishedAt": "2014-12-01T18:33:01.000Z",
        "likeCount": 0,
        "comment": "I shot this video back in Fat San China around 1997ish. All three guys were ranked as Sifu. My point is, all three of these guys were experts, doing the same form in completely different ways. Keep your Gung Fu fluid, there is no perfect way.",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UghjKLpKu_lRI3gCoAEC"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2014-11-29T11:43:19.000Z",
        "likeCount": 0,
        "comment": "Guy on the right was the best for me. He had the best balance of speed control and mind focus intent. Middle guy a bit to slow going in general not so highly focused, although slower can be better! Guy on the left appeared better due to the speed and technicality but a closer look excessive movement and jerkyness!",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-83tgP77jmUU/AAAAAAAAAAI/AAAAAAAAAAA/2RyZloBa-88/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Pasco David",
        "commentId": "Ugjpt-2_5p6D8XgCoAEC"
    },
    {
        "publishedAt": "2014-09-23T06:21:45.000Z",
        "likeCount": 0,
        "comment": "His speed and seemingly effortless flowing movement shows what effect practice has. Making self-defense movement second nature is important. I&#39;m a martial artist and was fortunate to find a great Sifu to learn from. The main system is Chinese martial arts (shaolin, taichi, bagua, etc), but we study techniques from Filipino and Indonesian(Silat) martial arts, because not only are they effective but they maintain an artistic quality to them by studying human movement/body and exploring all possibilities.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-bBNigVWOfL4/AAAAAAAAAAI/AAAAAAAAAAA/VwaXeGx5xWg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andrew Inman",
        "commentId": "Ugj6UWQnRd01hXgCoAEC"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2014-07-10T01:16:59.000Z",
        "likeCount": 0,
        "comment": "Scrub to <a href=\"http://www.youtube.com/watch?v=t4EOIs7IYf0&amp;t=3m12s\">3:12<\/a> to see the ball point pen",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-OR-yhI2GD8Q/AAAAAAAAAAI/AAAAAAAAAAA/cwDqAwv4E3k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Pablo Scourge",
        "commentId": "UgiFlnCTrSJch3gCoAEC"
    },
    {
        "publishedAt": "2014-07-02T03:30:19.000Z",
        "likeCount": 1,
        "comment": "This shit is the most logical martial art ever invented.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-LWAXSTVpgpM/AAAAAAAAAAI/AAAAAAAAAAA/9cBluEgImx8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bowmanpwner",
        "commentId": "UggZfCvQueUq13gCoAEC"
    },
    {
        "publishedAt": "2014-01-11T02:44:09.000Z",
        "likeCount": 0,
        "comment": "Excelent video, please more, greetings from Chile.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-3Prb86wrAF0/AAAAAAAAAAI/AAAAAAAAAAA/_Fn2okt1QK8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Wing Chun La Florida",
        "commentId": "UgjFnygNQXXtgHgCoAEC"
    },
    {
        "publishedAt": "2013-05-21T15:51:25.000Z",
        "likeCount": 0,
        "comment": "Umm no it&#39;s not the same. Dogs are nasty. They&#39;re sniffing each others butt and eating shit. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VuuOYQWXgX0/AAAAAAAAAAI/AAAAAAAAAAA/GO84DSSnHbo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Cookie Dough",
        "commentId": "UgwjI8tXlt8hP6eAq_Z4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2013-05-14T16:15:16.000Z",
        "likeCount": 0,
        "comment": "But boiling live lobster is cool right?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-KU6TNUFKR5Y/AAAAAAAAAAI/AAAAAAAAAAA/qZOAukRPGkg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kobil Shakur",
        "commentId": "UgxMeefjbm5Y8xJTxmx4AaABAg"
    },
    {
        "publishedAt": "2013-03-24T12:42:33.000Z",
        "likeCount": 0,
        "comment": "Sure you can eat animals, but you have to treat them like they are alive, untill you kill them in a painless way, boiling live puppies isnt right.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-w-EANjZ_nSg/AAAAAAAAAAI/AAAAAAAAAAA/eh8FH5HbU64/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mx5Wagon",
        "commentId": "Ugx73Sgqv0l33YgNTcV4AaABAg"
    },
    {
        "publishedAt": "2013-03-21T09:35:23.000Z",
        "likeCount": 0,
        "comment": "Ok that&#39;s good, i was merely bringing up the fact that you cant go hating on people for eating an animal that you weren&#39;t brought up viewing as food.\nCompletely agree, the way these animals are treated in china is appalling and needs to be brought to a stop same with the way other animals in western countries are treated in slaughterhouses. \n\nok, but you still cannot be abusing people who eat this meat though, only the ones who are actively abusing the animals. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-os4UvMJKojw/AAAAAAAAAAI/AAAAAAAAAAA/1G-A7usiow8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lomo Leeks",
        "commentId": "Ugzh6tAXjxYAXZExQM54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2013-03-21T02:13:39.000Z",
        "likeCount": 0,
        "comment": "That&#39;s a horrible thing to say, you eat cow and pig right? People keep pigs as pets, they are greatly more intelligent than dogs, why is it ok to eat them but horrible to eat dog?\n\nDon&#39;t come here abusing and wishing death on another race because of what they eat, take a long look at your own meat diet. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-os4UvMJKojw/AAAAAAAAAAI/AAAAAAAAAAA/1G-A7usiow8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lomo Leeks",
        "commentId": "Ugzxr3v9V4MEogSHpYF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2013-03-16T19:27:30.000Z",
        "likeCount": 0,
        "comment": "You guys saw the one handed guy? What was his style? And who the fuck was the last guy, the old one?",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-7Hkirr0gpJc/AAAAAAAAAAI/AAAAAAAAAAA/ZkRfHPD06NI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "DieAlphaEnte",
        "commentId": "UgyDim2wqDHYMWk6rip4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2013-03-16T19:24:39.000Z",
        "likeCount": 0,
        "comment": "You guys saw the one handed guy? What was his style? ",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-7Hkirr0gpJc/AAAAAAAAAAI/AAAAAAAAAAA/ZkRfHPD06NI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "DieAlphaEnte",
        "commentId": "UgyGX83GNeUDKf1I15Z4AaABAg"
    },
    {
        "publishedAt": "2013-02-27T17:01:29.000Z",
        "likeCount": 0,
        "comment": "They often torture the dogs. They skin them alive because it&#39;s easier to skin them alive. That should not be allowed. Anyone who tortures an animal for any reason should be given the same treatment. You can end their lives in a kind way and then eat them if you believe in that. I do not. Dog were bred to be human companions. That is not the same as cattle that are bred to eat. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mtj57TQd7KU/AAAAAAAAAAI/AAAAAAAAAAA/ioqaysdgV8s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lynn Hahn",
        "commentId": "Ugx7Dp3nrBXsacO7Z0R4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2013-01-15T12:58:22.000Z",
        "likeCount": 0,
        "comment": "well you simply dont eat anything that eats meat !!!! is gross and unhealthy.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mAaNAJ26dMI/AAAAAAAAAAI/AAAAAAAAAAA/OjX74CQKC6I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahmad Faris",
        "commentId": "UgwQdHvz8WCT7ANmC0F4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2013-01-15T12:56:01.000Z",
        "likeCount": 0,
        "comment": "yah but you never eat something that eats meat!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mAaNAJ26dMI/AAAAAAAAAAI/AAAAAAAAAAA/OjX74CQKC6I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahmad Faris",
        "commentId": "UgzC-vxyU9cTzMZ3JmZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2013-01-10T23:37:30.000Z",
        "likeCount": 0,
        "comment": "interesting looking WC. I like it!!!",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-HDt6CBayLuw/AAAAAAAAAAI/AAAAAAAAAAA/YntOo7_v-yY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Rich Verdugo",
        "commentId": "Ugx7z0pwy7hVTrU3dwF4AaABAg"
    },
    {
        "publishedAt": "2013-01-06T22:41:47.000Z",
        "likeCount": 0,
        "comment": "french pudel - LFMAO",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-S290XOs0xX8/AAAAAAAAAAI/AAAAAAAAAAA/F1LWLVD_lfo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Naproxenum",
        "commentId": "UgwGAgrS_fq04gNbCoJ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2012-12-29T14:53:01.000Z",
        "likeCount": 0,
        "comment": "We will wait for you in hell and gangrape you! And stuff your mouth with the best BBQ dog meat!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Ley7KBYvlDM/AAAAAAAAAAI/AAAAAAAAAAA/qBkzwNihGkk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "LIONELSMITS",
        "commentId": "UgzssLvWRCGRY98s-TZ4AaABAg"
    },
    {
        "publishedAt": "2012-11-11T19:17:37.000Z",
        "likeCount": 0,
        "comment": "You people are going to hell for doing this sick disgusting heartless crime to God&#39;s creation &gt;:(",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-3uF9dURev0M/AAAAAAAAAAI/AAAAAAAAAAA/QtHxx5gAIJ4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Cat",
        "commentId": "UgxOHzFE8VawPd3cWW94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2012-11-10T01:15:24.000Z",
        "likeCount": 0,
        "comment": "Cannibal? Wow people on the internet get less and less intelligent everyday",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-KlR2AfXYPy8/AAAAAAAAAAI/AAAAAAAAAAA/qDnUbroOesM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TheBighouse19",
        "commentId": "UgxcKGe7zZsXYsc_ErJ4AaABAg"
    },
    {
        "publishedAt": "2012-11-04T04:55:43.000Z",
        "likeCount": 0,
        "comment": "To people saying it&#39;s fine.. The reason peopple don&#39;t like this is because it is frowned upon in U.S.A personally I think it is ok to eat them but look up how the kill. It is like kfc with chickens. Plus most cows are raised to be killed and westerners have been killing cows forever",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ierm6eBGluQ/AAAAAAAAAAI/AAAAAAAAAAA/Lf9fuXEirkU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ugly.uncle.pickle",
        "commentId": "UgxJTkfAJxBDydPxxqd4AaABAg"
    },
    {
        "publishedAt": "2012-10-12T16:05:32.000Z",
        "likeCount": 0,
        "comment": "dog are practically useless to an asian, utillity wise, sure, they guard the house but that all they do in those part of the world. A cow is useless to a westerner (other than meat and milk) as most task are performed by machine and tractor, however, they are held in higher regard in Asia because they provide milk, plow the field and pull wagons which otherwise, farming machine are not available to most asian country.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-RDNyLxIS0pU/AAAAAAAAAAI/AAAAAAAAAAA/N2_C9BPSgFY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Arturo Siew",
        "commentId": "UgxuxGWN093H75Wr5Ed4AaABAg"
    },
    {
        "publishedAt": "2012-09-01T06:28:06.000Z",
        "likeCount": 0,
        "comment": "Fuck those people eat dog and other kind of meat",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-WCg01kS0fqY/AAAAAAAAAAI/AAAAAAAAAAA/bApcaqzExJw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "karwan ilami",
        "commentId": "UgzWBhC6tpEIB3sOrOV4AaABAg"
    },
    {
        "publishedAt": "2012-08-24T13:57:39.000Z",
        "likeCount": 0,
        "comment": "They eat dogs, same reason why people eat chicken and various types of meat.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-RhfWC1Dq_jg/AAAAAAAAAAI/AAAAAAAAAAA/HWQQNfdo6VE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "solovengy",
        "commentId": "UgyNqML1-PsaCtcgI5J4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2012-08-11T05:31:29.000Z",
        "likeCount": 0,
        "comment": "i was excited on about china and alot of it until i heard this!!. thats messed up! fuck you! :p",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4BjvyMHvEY0/AAAAAAAAAAI/AAAAAAAAAAA/ScWj5jFm2kU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bobby choch",
        "commentId": "Ugy8aF8EeuXAKQcwLz14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2012-08-11T05:30:34.000Z",
        "likeCount": 0,
        "comment": "thats fucken disgusting thats fucked up!! how could you eat dogs  you know how nice those dogd can be..makes me sick",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4BjvyMHvEY0/AAAAAAAAAAI/AAAAAAAAAAA/ScWj5jFm2kU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bobby choch",
        "commentId": "UgxS_2hApeLPnPrC74p4AaABAg"
    },
    {
        "publishedAt": "2012-08-01T08:00:24.000Z",
        "likeCount": 0,
        "comment": "People who beef and pork while insulting dog eaters are the highest form of self righteous hypocrite ignorant idiots in this world.\r\nCows obey voice command, pull plows, love and care for their young and feel pain too.\r\nSo if you can eat a cow why can&#39;t Asians not raise dogs for food in their own land.\r\nStupid ignorant dog advocates.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TBy9c8Gmop0/AAAAAAAAAAI/AAAAAAAAAAA/i3UrBGHI9z4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "emanueldedios",
        "commentId": "UgwJYmPxUiAlezS3IlZ4AaABAg"
    },
    {
        "publishedAt": "2012-07-29T11:50:25.000Z",
        "likeCount": 0,
        "comment": "Slame onu",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-YmLxToAPvvk/AAAAAAAAAAI/AAAAAAAAAAA/t2i0Xgoy-U8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Charles Sahan",
        "commentId": "Ugxbms9-TLPjUiOY1P94AaABAg"
    },
    {
        "publishedAt": "2012-07-27T00:50:22.000Z",
        "likeCount": 0,
        "comment": "what theeeeeeeeee you know what i m going to write",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-OX6P7Q3DcK4/AAAAAAAAAAI/AAAAAAAAAAA/5UdwSZegoSc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Sami Ullah",
        "commentId": "UgzesM8FsQqz8jro1hB4AaABAg"
    },
    {
        "publishedAt": "2012-07-12T16:01:22.000Z",
        "likeCount": 0,
        "comment": "Nasty shit,",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-UZYcNnMKZuA/AAAAAAAAAAI/AAAAAAAAAAA/7Yc-0pwDFXk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "idaly hdez",
        "commentId": "UgxYz6Ha5L2zcg9k2Qx4AaABAg"
    },
    {
        "publishedAt": "2012-06-23T20:19:12.000Z",
        "likeCount": 0,
        "comment": "so people eat animals, they&#39;re not your pets like the hamburgers, pork and chicken which also weren&#39;t your pets.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-yCK0DlT-ab0/AAAAAAAAAAI/AAAAAAAAAAA/Q3T97w4GvGw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Rachel Crylinds",
        "commentId": "Ugxk2VpIwC2zN0tWUVt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2012-05-19T04:20:35.000Z",
        "likeCount": 0,
        "comment": "What the Dick!!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P_K-ivvGsSc/AAAAAAAAAAI/AAAAAAAAAAA/VYFkyuDepvg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "MexGaming",
        "commentId": "UgxLwYqEIlp5kRg7y354AaABAg"
    },
    {
        "publishedAt": "2012-04-23T08:22:28.000Z",
        "likeCount": 0,
        "comment": "tuncanaso67 and ho8734 ha ha ha the idiot and the retard going off each other. You&#39;re both a waste of time. Learn to write/speak proper English first before you shame yourself commenting on things beyond the capacity of your moronic brains hah ha ha.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgzmInHNGdnvCWyt8_14AaABAg"
    },
    {
        "publishedAt": "2012-04-13T15:52:35.000Z",
        "likeCount": 0,
        "comment": "Dear fans,The WC chi sau is very powerful but not really. The really WC  is very flexible is not hard. their CHI SAU  is over the shoulder and  no standard. I hope the WC fans can told them.It is wrong CHI SAU method thank very much.   14/04/2012",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-kdET0yQ14Uc/AAAAAAAAAAI/AAAAAAAAAAA/8m5MpguCpAo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Leung S.Y.K",
        "commentId": "UgzFICBAVzeX4sPyUXx4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2012-03-24T06:22:20.000Z",
        "likeCount": 0,
        "comment": "are you talking to me too?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgyNpsasYggVsp65EjF4AaABAg"
    },
    {
        "publishedAt": "2012-03-22T05:32:29.000Z",
        "likeCount": 0,
        "comment": "Again learn english, or else go home, you stupid immigrant. ha haha",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "Ugwhp81WqVhsTa2d6zF4AaABAg"
    },
    {
        "publishedAt": "2012-03-11T10:30:28.000Z",
        "likeCount": 0,
        "comment": "@ho8734 ha ha ha h where have you been. We surely need a clown. Piece of advise, go to an English Learner Class for Immigrant Dummies. Learn the difference between typos and real errors so you&#39;ll know when to give proper criticism. You&#39;re just making yourself look like a clown you dummy ha ha ha ha.\r\nBetter yet, go home to wherever country you come from. You&#39;re a useless piece of shit and a burden to USA tax payer you moron ha ha ha ha.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgwQPtEnlPGT7tSG_a54AaABAg"
    },
    {
        "publishedAt": "2012-03-07T18:11:37.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka lolz. good come back.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "Ugwt9nS8myjJv45ADaZ4AaABAg"
    },
    {
        "publishedAt": "2012-03-07T18:04:49.000Z",
        "likeCount": 0,
        "comment": "@ho8734 Goathard is a goat who is a Retard like you ha ha haha what a dummy.\n\nLook in your browser and see the Spell Check feature, that causes the Red line in your comment you dummy. I saw that but don&#39;t have time to go back. Typos are not real error you moron. \n\nGo home. Idiots like you are bringing future generation of Americans down. hah ahaha ha.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgxyQlB8OK7uKfEqr3p4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2012-03-06T01:35:05.000Z",
        "likeCount": 0,
        "comment": "@ho8734 So now where&#39;s your POINT, admit it you retard, you just didn&#39;t know where the Unlock Caps is in your own kayboard ha ha haha  what an idiot.\nI have seen a lot of morons in the net but nothing beats you ha hahaha.\n\nEver heard of Spell Check? Yeah I saw the read line you retard, but don&#39;t have time to come back. Typos are not real errors you dummy.\n\nGo home, your putting Real Americans in a bad light you shameless low income Immigratn parasite.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "Ugy-eTYOi1ezTR6TUE14AaABAg"
    },
    {
        "publishedAt": "2012-03-05T08:42:52.000Z",
        "likeCount": 0,
        "comment": "@ho8734 ha ha ha What a moron, you&#39;re like a retarded pre schooler who was jumping with joy to catch someone else&#39;s minor error. Yup my spelling isn&#39;t perfect but my english is not shamefully pregnant of error like yours, you dummy. \r\nYou shamelessly force yourself to come to USA, at least try to learn English. If you can&#39;t, then stay away from adult discussions, you&#39;re just making yourself a laughing stock. Now go fuck a donkey you asshole ha ha haha.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgzA4B-Hx5GM23i9oDV4AaABAg"
    },
    {
        "publishedAt": "2012-03-05T08:04:38.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka no i am owing you \nyou keep saying the same thing like an idiot ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgyKg8hzDpfl-oIEceJ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2012-03-05T05:00:50.000Z",
        "likeCount": 0,
        "comment": "est amuy bueno, muy lindo el laburo, y re simpatica la animacion! :)",
        "videoId": "17iBWOBIakg",
        "authorThumbnail": "https://yt3.ggpht.com/-qixfYGOPGq4/AAAAAAAAAAI/AAAAAAAAAAA/Mo5DhvZrrJk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "valentin colazo",
        "commentId": "Ugw9gWCQtezC9dHkx9F4AaABAg"
    },
    {
        "publishedAt": "2012-03-02T04:51:36.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka the religion of terrorism is saying i have low IQ lol \nyou stinky muslims are all watched by the U.S government they just arrested 11 of you terrorists last week  \nyou motherfuckers smell like shit take a shower",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgzCl48zJnH4_0dyin14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2012-03-02T00:39:14.000Z",
        "likeCount": 0,
        "comment": "@ho8734 What? Immigrants made the USA? What a moron. The settlers and pioneers made the USA. Recent Immigrants like you have no participation in nation building. You are unwelcomed parasites who got there only because your momma provided sexual comfort for the horny GI Joes while they were fighting for &quot;democracy&quot; \nLook at yourself in the mirror. YOu don&#39;t belong there. No matter what you do, paint your face white your still a parasite. Real Americans don&#39;t judge others like you.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "Ugxr4E8X4FDCfszv2kB4AaABAg"
    },
    {
        "publishedAt": "2012-03-01T21:49:28.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka you dumb stinky muslim immigrant ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgwZQFbk8VvCNOCnlM14AaABAg"
    },
    {
        "publishedAt": "2012-03-01T21:28:22.000Z",
        "likeCount": 0,
        "comment": "@ho8734 When you reach Higschool you&#39;ll learn that when the REal Americans get into the New Found Land, there was no country. The natives were nomadics and wondered around following the buffalo trails. The Settlers and Pioneers built that country you ignorant moron. \nHow shameless are you to equate yourself with them. You got there because your momma prostituted herself to US soldiers in your country, you arrived there unwanted and unneeded. You&#39;re a useless parasite. Go home u Immigrant",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgxM6hwZzSGC96VHAz14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2012-03-01T07:29:49.000Z",
        "likeCount": 0,
        "comment": "@ho8734 Nope, that&#39;s the logic of those who forces themselves to be there like you. How shameful for you to assert that those who built that nation are Immigrants like you? They were pioneers, settlers, they built that country that was populated by nomadic Indians. You arrived there after everything was built and nobdy wanted you to go there. You are an Immigrant, they are real Americans no matter how liberals have twisted the definitons. Go home you thick faced\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgxKG41v6ns_etN_v3t4AaABAg"
    },
    {
        "publishedAt": "2012-03-01T07:23:29.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka America was found by immigrants everyone in America is immigrant except Native Indians learn some fucking history ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgyYPO7t-lJ8bKi7Wip4AaABAg"
    },
    {
        "publishedAt": "2012-03-01T07:18:39.000Z",
        "likeCount": 0,
        "comment": "@ho8734 You don&#39;t understand. The settlers and earlier Americans built what is now the USA and they come from Europe at a time when America needed new comers to cultivate and develop the land. They are the Real Americans. Recent immigrants like you has no part in nation building and are not needed nor wanted and don&#39;t belong in that place.  And you are giving all Americans a bad name because of your arrogant behavior. Real Americans understand cultural diversity.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgzUvxKMhuc2hIvem6F4AaABAg"
    },
    {
        "publishedAt": "2012-02-23T20:23:31.000Z",
        "likeCount": 0,
        "comment": "@purplemutantas dude they lie just to make everyone stop eating animals \nmay be smarter than cats but not dogs \ndogs protect people and they are used in finding drugs and other stuff \nMuslims and Jews don&#39;t eat them because they are disgusting or whatever\ni eat pigs too ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgwlwyNHGwHlYTHtGUR4AaABAg"
    },
    {
        "publishedAt": "2012-01-27T21:48:37.000Z",
        "likeCount": 0,
        "comment": "@gamhananalanka America was found by immigrants \nand dog eaters are fucking gay cunts ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgxyZhHewZpAy-rX3D94AaABAg"
    },
    {
        "publishedAt": "2012-01-26T07:21:30.000Z",
        "likeCount": 0,
        "comment": "The idiots here who leave harsh words for dog eaters are giving Americans a bad name since people assume them to be Americans. But in truth these people are Low Live Immigrant parasites who thrive on the US welfare system. They are the kind of people who has no capacity to understand a simple concept of Cultural Gaps. Main stream Americans likes to travel and to know foriegn culture and they respect other Nations and culture.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgxDs8k23Zwdpe_kHn94AaABAg"
    },
    {
        "publishedAt": "2012-01-23T10:24:17.000Z",
        "likeCount": 0,
        "comment": "@ho8734 Evident from your filthy language, you&#39;re the kind of people who promote dog advocacy. Uneducated low life trash talker who cannot stay within issue. Don&#39;t even bother to respond, I wouldn&#39;t stoop down to your level.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NBgXD3Krv1s/AAAAAAAAAAI/AAAAAAAAAAA/V1O6xHxFbfw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamhananalanka",
        "commentId": "UgzXwl-jabmEwo8uuQl4AaABAg"
    },
    {
        "publishedAt": "2011-12-18T06:28:56.000Z",
        "likeCount": 0,
        "comment": "@ho8734 I agree",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-pf5A7P_xkmo/AAAAAAAAAAI/AAAAAAAAAAA/EwS4Gk1a21I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Перкеле Vittupää",
        "commentId": "Ugxj4bXzokpwprwitct4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-12-18T06:27:40.000Z",
        "likeCount": 0,
        "comment": "I seriously hate Asians if they do this in places that don&#39;t eat dog. Coming to countries and eating   poor little puppys!?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-pf5A7P_xkmo/AAAAAAAAAAI/AAAAAAAAAAA/EwS4Gk1a21I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Перкеле Vittupää",
        "commentId": "UgwAgiNWK0A1VLA7nTN4AaABAg"
    },
    {
        "publishedAt": "2011-12-15T20:04:37.000Z",
        "likeCount": 0,
        "comment": "@ho8734 hey check your mom&#39;s annus, dog AIDS is more viscious ha ha ha ha ha.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgzYsbG-CNxLSclOa3x4AaABAg"
    },
    {
        "publishedAt": "2011-12-14T23:45:55.000Z",
        "likeCount": 0,
        "comment": "@villacustal you laugh like that when you see arab muslims fucking your mother \nshe is got aids and std \ntake her to a doctor or save your time kill her and kill yourself too",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "Ugz9_WjYdGYKZ4NZASR4AaABAg"
    },
    {
        "publishedAt": "2011-12-07T10:35:41.000Z",
        "likeCount": 0,
        "comment": "how is a dog life higher than cow or pig. just because you dress it up and lick your face doesn&#39;t make it special.\r\nSimple math. Cows, God for Hindus food for us.\r\nDogs pets for us food for Filipinos and vietnamese\r\nWorld peace. Why fight.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Tvs4MMD9z4Q/AAAAAAAAAAI/AAAAAAAAAAA/a61mSvIalaI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lakansorayo",
        "commentId": "UgwcXqmqXiebyrLqHGV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-12-06T01:27:06.000Z",
        "likeCount": 0,
        "comment": "Somoe people are just conditioned to think that certain animals are not eatable. Like how Hindus think cows are Sacred. If Westerners can raise cows for food then why can&#39;t their pet dogs be raised for food by Filipinos and Vietnamese?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-hFBVWZbzEwM/AAAAAAAAAAI/AAAAAAAAAAA/Ra6UX7f1ejw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "eutix",
        "commentId": "UgxqfrigvEHOxGboi6p4AaABAg"
    },
    {
        "publishedAt": "2011-12-06T01:22:39.000Z",
        "likeCount": 0,
        "comment": "@villacustal absolutely right. respect for other cultures is what westerners are lacking. they are swimming in their own arrogance.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/--KgpUioK79w/AAAAAAAAAAI/AAAAAAAAAAA/U9xXT2NQK3Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ambrosiobertodazo",
        "commentId": "UgxL_EuYzcaq567fKlB4AaABAg"
    },
    {
        "publishedAt": "2011-12-05T15:54:23.000Z",
        "likeCount": 0,
        "comment": "Different cultures have different values,same applies with food.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-H8fdHBG6Ufk/AAAAAAAAAAI/AAAAAAAAAAA/fIltyTIic00/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "meadowlane37",
        "commentId": "UgzZBjiyO8TErPRPThp4AaABAg"
    },
    {
        "publishedAt": "2011-12-02T23:46:05.000Z",
        "likeCount": 0,
        "comment": "@villacustal chicken, pigs, cows. rabbits are fucking different you can&#39;t teach them to not take a shit in your house they don&#39;t understand like dogs or cats you idiot ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-P1PadzsJQbo/AAAAAAAAAAI/AAAAAAAAAAA/mg9vqTYkKCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James Black",
        "commentId": "UgxvuGsfSyRw85WqKOB4AaABAg"
    },
    {
        "publishedAt": "2011-11-26T22:08:09.000Z",
        "likeCount": 0,
        "comment": "@reyle200 He wasn&#39;t saying much of anything, Jake (cam op now﻿ with ILM) was making sure he wasn&#39;t hurt too bad. ",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxTBcHuAd1ZU727qd94AaABAg"
    },
    {
        "publishedAt": "2011-11-26T22:04:26.000Z",
        "likeCount": 0,
        "comment": "@tvboyaa again, SNOW HELMETS WEREN&#39;T AROUND THEN. This was shot over a decade ago.  We were just tougher I guess. ",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgzXxdhgp1JbVYsg2XJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-11-26T18:10:14.000Z",
        "likeCount": 0,
        "comment": "@ph8tel did you ever think, hmm, this really hurts my head; maybe i should wear a helmet? dumb shit",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-RVq1IiqaN5I/AAAAAAAAAAI/AAAAAAAAAAA/PDDIjFbBCfQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "alex",
        "commentId": "Ugy6etCdEGOrvwwHoUd4AaABAg"
    },
    {
        "publishedAt": "2011-11-15T12:48:03.000Z",
        "likeCount": 0,
        "comment": "伄山？",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/--7U6dpSA6wc/AAAAAAAAAAI/AAAAAAAAAAA/zCdgqMHD68U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "seng ip",
        "commentId": "Ugz0ljlGTJfQXrMt0554AaABAg"
    },
    {
        "publishedAt": "2011-11-07T01:52:06.000Z",
        "likeCount": 0,
        "comment": "@villacustal Yeah it does. Dogs &gt; Cows",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-_jsjsLhE_Es/AAAAAAAAAAI/AAAAAAAAAAA/bj8_bc6mZrw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kyo",
        "commentId": "UgzYN7-p3PoMhkiQSf14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-10-28T03:40:56.000Z",
        "likeCount": 0,
        "comment": "0:22 YARD SALE!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-AjLrgRTOMGk/AAAAAAAAAAI/AAAAAAAAAAA/z6GVQyUhSmw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Austin Holgate",
        "commentId": "UgxZVtzdX1pnmEzxDeB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-10-28T03:40:51.000Z",
        "likeCount": 0,
        "comment": ":22 YARD SALE!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-AjLrgRTOMGk/AAAAAAAAAAI/AAAAAAAAAAA/z6GVQyUhSmw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Austin Holgate",
        "commentId": "UgzPdyTderT8H1XD8x94AaABAg"
    },
    {
        "publishedAt": "2011-10-26T00:16:27.000Z",
        "likeCount": 0,
        "comment": "@villacustal Dogs were domesticated to track prey and provide early warning of danger. Chickens on the other hand were domesticated to produce food. Same with cows. People are disgusted by other seeing dogs eaten because they truly are man&#39;s best friend, from the very beginning of human existance they were man&#39;s best friend. I have two dogs, and they&#39;re like members of my family. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-9KVqkAQlN84/AAAAAAAAAAI/AAAAAAAAAAA/INBgBj2-GHI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "AntiTroll101",
        "commentId": "Ugz-lUBEysVmhMGu2_J4AaABAg"
    },
    {
        "publishedAt": "2011-10-24T04:35:21.000Z",
        "likeCount": 0,
        "comment": "Sound effects are ghat.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XRJHajSS3Q8/AAAAAAAAAAI/AAAAAAAAAAA/DpJFgEIYVW4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "H Siu",
        "commentId": "Ugw3KZG7hZYXKPCyO5t4AaABAg"
    },
    {
        "publishedAt": "2011-10-20T18:41:14.000Z",
        "likeCount": 0,
        "comment": "@Mr4433 No one used helmets back then. We shot this around 2000.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugw3g11t7qfzUE_9Bol4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-10-19T10:50:52.000Z",
        "likeCount": 0,
        "comment": "Why in the shit would you not wear a helmet? ",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Z7jKBVpGRm4/AAAAAAAAAAI/AAAAAAAAAAA/QUSvHdi7-gA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mr4433",
        "commentId": "UgwKxhoxIXQRMPM43ul4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-10-17T03:13:08.000Z",
        "likeCount": 0,
        "comment": "Hast ami me dolio CSM!! \n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-xnpr5JYci2M/AAAAAAAAAAI/AAAAAAAAAAA/JS3USIEnTy8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "José Gabriel",
        "commentId": "UgwZznCWJvGMc-PYeWF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-10-06T19:51:47.000Z",
        "likeCount": 0,
        "comment": "Hey! That guy&#39;s face! He will die in less than 7 days!...Joking. XD",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-6RGeo-7vAf4/AAAAAAAAAAI/AAAAAAAAAAA/XF40uqfnyFM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "peposo7",
        "commentId": "UgwHcsWR5soXGVbBfb14AaABAg"
    },
    {
        "publishedAt": "2011-10-01T10:31:02.000Z",
        "likeCount": 0,
        "comment": "sick twisted dog eaters",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-UzaNzco_roI/AAAAAAAAAAI/AAAAAAAAAAA/OX5zKcrlWOc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Emma Smith",
        "commentId": "Ugz392ZFqAzPMGb7X154AaABAg"
    },
    {
        "publishedAt": "2011-09-25T17:30:17.000Z",
        "likeCount": 0,
        "comment": "@eyjobling pretty stupid to do anything on skis or a board without a helmet. and actually when people think they are good enough to not need one, the better you get the more you should wear it. when your better you go faster and jump higher. it only gets more risky and many things can happen that people cant control",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-bDnv1jFz6OU/AAAAAAAAAAI/AAAAAAAAAAA/BrbAgoN-wvg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TangeClown",
        "commentId": "Ugz9T5iuXgnTpR88zfF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-09-12T05:22:32.000Z",
        "likeCount": 0,
        "comment": "What about the cows who plow fields and build the first railroads, bridges, pulled the first pully in Mesopotamia, we never recognize their contribution to humanity. And here a dog wag it&#39;s tail an dlick your face and it become special? That&#39;s not fair. All animals are equal, just because cows don&#39;t wag their tails doesn&#39;t make it inferior to dogs. If you eat beef you have no business complaining why others eat dogmeat, its hypocrisy.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-XUenrD3u1XE/AAAAAAAAAAI/AAAAAAAAAAA/SGJVEEy_Mz0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "aljasmid",
        "commentId": "UgxSD2wlia0g3o2aee14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-09-11T09:04:41.000Z",
        "likeCount": 0,
        "comment": "Looks disgusting, but dogs just deserve to DIE!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-N4niOrwye_4/AAAAAAAAAAI/AAAAAAAAAAA/c6ekEBY-Pwo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TheToucanButter",
        "commentId": "UgzLOyM_cs70KPUA34p4AaABAg"
    },
    {
        "publishedAt": "2011-09-07T00:47:12.000Z",
        "likeCount": 0,
        "comment": "@Hilokilo06 Whatever you say go ahead. I&#39;m tired of you. Now go leave alone.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgwGk-UpbuRSQ_llTiN4AaABAg"
    },
    {
        "publishedAt": "2011-09-07T00:29:58.000Z",
        "likeCount": 0,
        "comment": "@Hilokilo06 OMG you have a very poor reading comprehension. I said Youtube don&#39;t allow kids below 16. Since you say she is just a gilr, it implies she is below 16 so she must have lied when she made her account. I don&#39;t know where you come from, obviously you&#39;re an immigrant to the USA. Consider using a translator before you make an interpretation. It&#39;s time consuming to argue in circles with some one like you who cannot understand very simple English.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgwivmzNlFkkZZo7p_Z4AaABAg"
    },
    {
        "publishedAt": "2011-09-06T07:20:55.000Z",
        "likeCount": 0,
        "comment": "@Hilokilo06 I didn&#39;t say she is 5 yrs old but that you and her talk like 5 yrs old. Just read your own post and hers. Too short and can&#39;t even make it right. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgyaP2OjbFHyvO21-et4AaABAg"
    },
    {
        "publishedAt": "2011-09-06T03:51:46.000Z",
        "likeCount": 0,
        "comment": "@Hilokilo06 She&#39;s either a retard with a 5 yrs old IQ like you or a liar. Minors don&#39;t get access to YOutube unless they lie. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgzjMqAQbGUbTWlZIel4AaABAg"
    },
    {
        "publishedAt": "2011-08-31T00:57:22.000Z",
        "likeCount": 0,
        "comment": "just try to keep your mouth closed",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-NEWQq-YTMzc/AAAAAAAAAAI/AAAAAAAAAAA/AueqGanfnQ0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "1rockcut",
        "commentId": "Ugybu3r60ar6ZbQFlL94AaABAg"
    },
    {
        "publishedAt": "2011-08-24T04:54:54.000Z",
        "likeCount": 0,
        "comment": "hijos de puta\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-soaMppcmHLY/AAAAAAAAAAI/AAAAAAAAAAA/3WuPi43PGwg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Erica Alaniz",
        "commentId": "UgyNuIYUD7zFjY9QE3J4AaABAg"
    },
    {
        "publishedAt": "2011-08-20T23:16:48.000Z",
        "likeCount": 0,
        "comment": "@QuantumQuacks  Fuck U\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-D2nmhOiCPrk/AAAAAAAAAAI/AAAAAAAAAAA/VdTg0ISOiHU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Change Me",
        "commentId": "UgymDUupgS2bs6bFEY14AaABAg"
    },
    {
        "publishedAt": "2011-07-30T08:11:38.000Z",
        "likeCount": 0,
        "comment": "what a load of shit\n",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-TzNmqbSETMY/AAAAAAAAAAI/AAAAAAAAAAA/fBWAv8bz4Zg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Patrick Barber",
        "commentId": "Ugz3nbgOVYuDdni_QrF4AaABAg"
    },
    {
        "publishedAt": "2011-07-28T04:51:27.000Z",
        "likeCount": 0,
        "comment": "LOL i laughed so hard",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-_Hu0IQQd904/AAAAAAAAAAI/AAAAAAAAAAA/pMxVv5LRzZI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Matthew Murphy",
        "commentId": "Ugy9EVEcqPrUyjWVkdp4AaABAg"
    },
    {
        "publishedAt": "2011-07-27T23:51:10.000Z",
        "likeCount": 0,
        "comment": "it should be called pet food",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-miJ3K13Hh9Q/AAAAAAAAAAI/AAAAAAAAAAA/KM7WgjS1c6I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Charlie who",
        "commentId": "UgyNoRhuGM8sNEQe0Dx4AaABAg"
    },
    {
        "publishedAt": "2011-07-21T06:25:13.000Z",
        "likeCount": 0,
        "comment": "Dogs are no different than cows etc just because Americans think they are cute.\n\nDog attacks on humans are the highest than any other animals.\n\nTo see footage of dogs attacking humans go to my channel and click on video Re: Filipinos eat dog.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-168TvJbaN90/AAAAAAAAAAI/AAAAAAAAAAA/06SqA6Bx4HE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "padukamahasari",
        "commentId": "Ugylmms5Mj9Hs7iS2Xp4AaABAg"
    },
    {
        "publishedAt": "2011-07-10T22:26:19.000Z",
        "likeCount": 0,
        "comment": "@sonia57591 You&#39;re an idiot. I say cows instead of beef or pig instead pork because I&#39;m saving space. Apparently you&#39;re not aware of the characer limit because you never typed more than one line, a product of your limited pea sized brain. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "UgxLWItieyhgp-3_l6R4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-07-07T09:05:31.000Z",
        "likeCount": 0,
        "comment": "@420kittygirl \nGirlygirl, are u not able to read? to understand?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-AkbCdsUhkB0/AAAAAAAAAAI/AAAAAAAAAAA/m6ZmlKV4Xuo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cuddler",
        "commentId": "UgwHkClkC3XswzqwuXl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-07-06T14:09:25.000Z",
        "likeCount": 0,
        "comment": "@psycokatlady \nYoure a girl? youre dumb as a boy",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-AkbCdsUhkB0/AAAAAAAAAAI/AAAAAAAAAAA/m6ZmlKV4Xuo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cuddler",
        "commentId": "Ugxn35W--o-gOqzhCCh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-07-04T14:14:31.000Z",
        "likeCount": 0,
        "comment": "Chi-Sao is no free fight! Since I do not know if that was a `training´ or a contest, I should not write about it. But I do it despite:\nThe better should always save his opponent and he also should stick to controlling techniques. If that was a competitive chi-sao-exercise, they should have made it not from the poon-sao-like position. Nevertheless it is good to see, how much people enjoy wingchun whatsoever.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-EYzax_yX_ss/AAAAAAAAAAI/AAAAAAAAAAA/Wn6Zqml4RXk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "11Kralle",
        "commentId": "UgzGS1yuFbcAogsjBpt4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-07-02T01:33:44.000Z",
        "likeCount": 0,
        "comment": "@wotagirlie If you had a cow (bull) as a security animal it could chase down, stomp flat, and then gore a robber, lets see a dog do that ! Eat the dog, save the cows. BTW Did you know that cows are a sacred animal? You fucking barbaric ogre !",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4fw8mFzcPWU/AAAAAAAAAAI/AAAAAAAAAAA/pw6rVfmv5QY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "A Mouse",
        "commentId": "Ugy_l0CtH632wLR9hyt4AaABAg"
    },
    {
        "publishedAt": "2011-07-02T01:25:34.000Z",
        "likeCount": 0,
        "comment": "@catlover1968 ....FYI...Dogs love killing cats... Just something for you to think about while you watch this video... :)",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4fw8mFzcPWU/AAAAAAAAAAI/AAAAAAAAAAA/pw6rVfmv5QY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "A Mouse",
        "commentId": "UgxFDDUAXJL71Jl_LMl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-07-02T01:20:20.000Z",
        "likeCount": 0,
        "comment": "@psycokatlady The inebriation of two whole cities?! That sounds fuckin awesome!! I wish I was there ! ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4fw8mFzcPWU/AAAAAAAAAAI/AAAAAAAAAAA/pw6rVfmv5QY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "A Mouse",
        "commentId": "UgzUTh1Rx7TaAGuKikx4AaABAg"
    },
    {
        "publishedAt": "2011-06-26T02:21:29.000Z",
        "likeCount": 0,
        "comment": "@QuantumQuacks agree with you its fine to eat dog. however if the animal isn&#39;t bred to be farmed but is bred as a pet, is loyal and goes out of its way to please it&#39;s owner, then is sold to a butcher by this &#39;best friend&#39; just because it stopped being a puppy, then that shit&#39;s wrong. I have no moral high-ground and I have nothing against ppl eating farmed dog, but eating someone&#39;s pet is fucked, regardless of what species it is.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ke4aP3v5GLc/AAAAAAAAAAI/AAAAAAAAAAA/ZJroBjhnzlM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Eitzo",
        "commentId": "UgzJpfn8tDnHSnT8w3B4AaABAg"
    },
    {
        "publishedAt": "2011-06-17T21:11:12.000Z",
        "likeCount": 0,
        "comment": "it&#39;s funnnnnnnnnnnnnnnyyyyyyyyyyyyyyy\r\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-e-SXphKN2RU/AAAAAAAAAAI/AAAAAAAAAAA/ZYNo7oSQVsk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kasey Durm",
        "commentId": "UgyN0HPelQMNEgUzsXt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-06-12T00:52:29.000Z",
        "likeCount": 0,
        "comment": "That&#39;s it I no longer eat Chinese food here in U.S.A \nI have seen my meat a lil different and it grosses me out to think I am  eating dog  meat. YUCK!! ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-guDZDsks0C0/AAAAAAAAAAI/AAAAAAAAAAA/ZvyNWPt-0bY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "JDIC18",
        "commentId": "Ugzjep15ku-j5lUXaHN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-06-11T12:29:58.000Z",
        "likeCount": 1,
        "comment": "minute 0:43: Man in the black shirt... ¿snake yiu choi wing chun?... is spectacular",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-jiGdFxHRDIg/AAAAAAAAAAI/AAAAAAAAAAA/rNcegwjagOo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tie shan kao",
        "commentId": "Ugzjt0faVgxD7PZL5LF4AaABAg"
    },
    {
        "publishedAt": "2011-06-11T06:12:23.000Z",
        "likeCount": 0,
        "comment": "LOL\r\ni was expecting people to be grown to these customs, and to also see people disagreeing to it.\r\nAnd, people have different veiws everything. But is it the best if you guys refrain yourselves from abusive languages, every one has points of veiws.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-6O6bLxqGYJo/AAAAAAAAAAI/AAAAAAAAAAA/Dl0mRFe0myE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "hotshiz911",
        "commentId": "UgyPF5DwHy4FkHHzi3V4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-06-09T07:16:29.000Z",
        "likeCount": 0,
        "comment": "How you expect?! China MOSTLY EAT DOGS! I&#39;m MOSTLY PISSED OFF OF CHINA! Although I&#39;m Chinese BUT I REALLY HATE CHINA!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ZCSpXs0_guE/AAAAAAAAAAI/AAAAAAAAAAA/AfXuR3Y9SXk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Nigel Kronkberry",
        "commentId": "UgzWjDwafMJatgW5Fid4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-06-06T01:19:51.000Z",
        "likeCount": 0,
        "comment": "HEARTLESS BASTARDS!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ABVC7grNkwU/AAAAAAAAAAI/AAAAAAAAAAA/RbPQlD_DUDI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "catlover1968",
        "commentId": "UgyvGjshZLhWQxuMsGR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-06-02T16:23:15.000Z",
        "likeCount": 0,
        "comment": "0:50 WTF? He tried to ride a tree branch!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-D8ojxtca54Q/AAAAAAAAAAI/AAAAAAAAAAA/QpSPHblwIs4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Alex Kazhdan",
        "commentId": "UgwWaeSbgrcgrIWSMlJ4AaABAg"
    },
    {
        "publishedAt": "2011-06-01T03:57:11.000Z",
        "likeCount": 0,
        "comment": "i almost twisted my knee the first time i tried snowboarding. been loving it since then",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-0EObGAh4nUs/AAAAAAAAAAI/AAAAAAAAAAA/DjfbiQF9xjw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Alban Musa",
        "commentId": "UgzSVkL8EmvxRMFl7iZ4AaABAg"
    },
    {
        "publishedAt": "2011-05-31T04:13:59.000Z",
        "likeCount": 0,
        "comment": "Thumbs up for the bails, not the sound effects.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-jVNKnLpSvxs/AAAAAAAAAAI/AAAAAAAAAAA/D-nl9QGc274/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "AvsJoe",
        "commentId": "Ugzp1qw9XNFVmpEkSS14AaABAg"
    },
    {
        "publishedAt": "2011-05-26T16:18:11.000Z",
        "likeCount": 0,
        "comment": "sounds like amped 3\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-tXILLkeueTg/AAAAAAAAAAI/AAAAAAAAAAA/aQpgMkh1o1M/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David",
        "commentId": "UgyF2IG8ZR7JdFN8bAx4AaABAg"
    },
    {
        "publishedAt": "2011-05-23T19:25:21.000Z",
        "likeCount": 0,
        "comment": "i wanna stab these people.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ef5lg4lZAcQ/AAAAAAAAAAI/AAAAAAAAAAA/72U_O3mRchE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "klimtkiller",
        "commentId": "UgwuJazRTDlilh_fUy14AaABAg"
    },
    {
        "publishedAt": "2011-05-23T16:33:34.000Z",
        "likeCount": 0,
        "comment": "@QuantumQuacks stfu dude, its a fucking dog, don&#39;t need to explain u why its fucked up, even thais people stay away from that shit. If u dont understand this, u got shit 4 brains dude.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-zlDuAp-I5Hg/AAAAAAAAAAI/AAAAAAAAAAA/E8o88P4b6p0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ivan Wuddhisukhum",
        "commentId": "UgwOiWFOik2zkyzHcaZ4AaABAg"
    },
    {
        "publishedAt": "2011-05-20T00:29:03.000Z",
        "likeCount": 0,
        "comment": "@wotagirlie again another rude comment from you, do you just go around looking for videos about China, so you can make negative comments. Again your a bitch realator in the Bahamas, which is a resort country without a real history or culture, how dare you.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-T_bcJePJeBc/AAAAAAAAAAI/AAAAAAAAAAA/NYkOvzCCwxY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "wingwaabuddha",
        "commentId": "UgzFuRDhuwH0oa-dYWV4AaABAg"
    },
    {
        "publishedAt": "2011-05-03T15:21:48.000Z",
        "likeCount": 0,
        "comment": "@tucsonpersonified No, he is the brother of Remy. ",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-SrD4Sh_vt-I/AAAAAAAAAAI/AAAAAAAAAAA/dk5Vem6nsSI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "303acidxtc",
        "commentId": "UgxG4OI_zco-oeknk254AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-05-03T13:58:37.000Z",
        "likeCount": 0,
        "comment": "FAIL!\n",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-u9i-xjf1_PI/AAAAAAAAAAI/AAAAAAAAAAA/CzfxXR4D2Uw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ShootoGreat",
        "commentId": "UgzKXyL1A3LwzosU7Vd4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-04-24T02:46:42.000Z",
        "likeCount": 0,
        "comment": "Is he the son of Remy?  I use to train with Remy in Flint, Mi.\n",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-yRPbtJTJotM/AAAAAAAAAAI/AAAAAAAAAAA/6tiWBE29t0g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tucsonpersonified",
        "commentId": "UgwYf-TO90JljoWTYIF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-04-23T01:09:59.000Z",
        "likeCount": 0,
        "comment": "Taste Like Chicken!! jajajaaa",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-UjwGoFDovbo/AAAAAAAAAAI/AAAAAAAAAAA/YMiL0uKz6dw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "fleiva30",
        "commentId": "UgytGdkeon25rbaKdKR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-04-22T21:53:49.000Z",
        "likeCount": 0,
        "comment": "im confused, i mean i would never eat dog but isnt it like eating cow or chicken or fish? so its cruel to eat dog but not a chicken? ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-uNwibe96uIM/AAAAAAAAAAI/AAAAAAAAAAA/LCjfn7N14zY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jojo x",
        "commentId": "Ugw6CRXdo1mglRYAzqR4AaABAg"
    },
    {
        "publishedAt": "2011-04-22T08:27:13.000Z",
        "likeCount": 0,
        "comment": "@kazakh08The world would be peaceful without you\n\nHindus don&#39;t eat Cows, Muslims don&#39;t eat Pigs.\n\nSome people pets the rabbits, some people the chicken, some even the Iguanas.\n\nBut none of them get bothered if some people eat Cows, Pigs, rabbits etc.\n\nBut if Americans don&#39;t eat dog, they want to force it to the whole world that Dogs are not food.\n\nDogs isn&#39;t different from any animal. Just because they were trained to do things in USA﻿ doesn&#39;t elevate them higher than Cows or any other animals.\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5-Jhm_jGR-Y/AAAAAAAAAAI/AAAAAAAAAAA/pHOlZQjiRtg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "villacustal",
        "commentId": "Ugzic1JspYWl7bhJFLN4AaABAg"
    },
    {
        "publishedAt": "2011-04-20T03:50:03.000Z",
        "likeCount": 0,
        "comment": "@ajatkinson2004 They usually start with sticks to learn the movements and applications, then go to knives, then open hands, then steel weapons like swords.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-74Ws4M1qUfA/AAAAAAAAAAI/AAAAAAAAAAA/aYSusUnzvzI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Oneris",
        "commentId": "UgzitSKVPo7wpx85qfx4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-04-13T21:13:43.000Z",
        "likeCount": 0,
        "comment": "@aljasmid I do know now you think i will still not know after 4 months? you crazy",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-LQgGBXnkaOE/AAAAAAAAAAI/AAAAAAAAAAA/-zclQiDezTE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "alipouya",
        "commentId": "UgyJ9nlia6ygLwWwc1p4AaABAg"
    },
    {
        "publishedAt": "2011-04-13T06:16:26.000Z",
        "likeCount": 0,
        "comment": "@alipouya you must be uneducated not to know that yours is not the only culture in this world.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-XUenrD3u1XE/AAAAAAAAAAI/AAAAAAAAAAA/SGJVEEy_Mz0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "aljasmid",
        "commentId": "UgzOJ6G-ItKWJOliqqB4AaABAg"
    },
    {
        "publishedAt": "2011-04-09T03:50:59.000Z",
        "likeCount": 0,
        "comment": "0:23 there goes hit mittens :o",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-MuYijMUycZw/AAAAAAAAAAI/AAAAAAAAAAA/jRosE5kMpvs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "eehan11",
        "commentId": "UgxGZRi5LiXzV6v28JR4AaABAg"
    },
    {
        "publishedAt": "2011-04-03T16:22:31.000Z",
        "likeCount": 0,
        "comment": "@alipouya Filipinos and Koreans.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Gzck-57FV6o/AAAAAAAAAAI/AAAAAAAAAAA/f5ngSCTKVVo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Johnydrumbola",
        "commentId": "UgxPx5hBnAZDNUG31yF4AaABAg"
    },
    {
        "publishedAt": "2011-03-26T17:40:27.000Z",
        "likeCount": 0,
        "comment": "i hope your plane crashed  and you all died ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-qeFwSfbygMA/AAAAAAAAAAI/AAAAAAAAAAA/d43sP9GapFs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "blablabla746",
        "commentId": "Ugzhd71Uz1bDXslYxL54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-03-24T07:08:55.000Z",
        "likeCount": 0,
        "comment": "@QuantumQuacks \nIt is, when you know how these dogs are kept and killed before being eaten.  Inform yourself first ok?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-fR66uVTw51o/AAAAAAAAAAI/AAAAAAAAAAA/zEWZCL4Ls-Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Trovatore75",
        "commentId": "UgyuTgDH9fE-SfMZteZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-03-24T01:56:57.000Z",
        "likeCount": 0,
        "comment": "@ajatkinson2004\r\nBallpoint pen seems pretty practical to me. Open your eyes and look at the applications, not the sticks. All martial arts become more practical that way. If you look close, you&#39;ll realize he didn&#39;t even need to use the pen. He could&#39;ve used his hands, or (like I&#39;d do) his shoe. Whatever you got available to you, right? I recommend doing research. Most people on youtube won&#39;t be able to help you and the majority of the ones that will will feed you a line of BS.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-fHxgWitAPgA/AAAAAAAAAAI/AAAAAAAAAAA/lxylGQ5Owm4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Banoffee",
        "commentId": "UgxE6eMAVvvPOsCx1Y94AaABAg"
    },
    {
        "publishedAt": "2011-03-21T16:03:43.000Z",
        "likeCount": 0,
        "comment": "@gingey123 \nI&#39;ve heard (I don&#39;t know if it&#39;s entirely true or not) that the best masters have all immigrated to the West  due to the harshness of the Communist Regime . \nAnd that the Chinese now, they don&#39;t care much about tradition. All they care about now is making money and living in a westernized way (working in an office, having a good car, a big tv, smoking, drinking, etc). \n",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-MVHZZM0Z6Eg/AAAAAAAAAAI/AAAAAAAAAAA/1-yO2W39y-s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mottahead",
        "commentId": "UgyZRv-yEI5hOGOh3cV4AaABAg"
    },
    {
        "publishedAt": "2011-03-14T20:20:42.000Z",
        "likeCount": 0,
        "comment": "I feel so bad for all these people cause I just broke my leg because someone hit me at camp fortune and if the person who hit me reads this I hope he burns in hell.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-zBps_1p7HsY/AAAAAAAAAAI/AAAAAAAAAAA/nciGnJd0T5s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hugh Xie",
        "commentId": "Ugyb_vkmfd1A3vR1_dp4AaABAg"
    },
    {
        "publishedAt": "2011-03-14T01:52:57.000Z",
        "likeCount": 0,
        "comment": "@MTERM775 animals have feelings too",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-hC1KJkMmOtk/AAAAAAAAAAI/AAAAAAAAAAA/RZyaNC6Fdf0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kapsi",
        "commentId": "Ugyv6UZ28QIAgOzwfPR4AaABAg"
    },
    {
        "publishedAt": "2011-03-14T01:19:24.000Z",
        "likeCount": 0,
        "comment": "@MTERM775 yes, you are wrong",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-hC1KJkMmOtk/AAAAAAAAAAI/AAAAAAAAAAA/RZyaNC6Fdf0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kapsi",
        "commentId": "UgzAFTE-D4MUzUfuoLh4AaABAg"
    },
    {
        "publishedAt": "2011-03-12T00:40:17.000Z",
        "likeCount": 0,
        "comment": "0:23 i&#39;m good at that",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-g9DKpf63m0E/AAAAAAAAAAI/AAAAAAAAAAA/AIFo9YDYjcs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SimenMagnussen",
        "commentId": "UgxwQhTC0dui2dsgcz94AaABAg"
    },
    {
        "publishedAt": "2011-03-10T23:37:14.000Z",
        "likeCount": 0,
        "comment": "I hate the fact that the chinese eat dog, but stop being ignorant fucking bitches learn about culture we eat things they charish, but trufully the chinese people that get dogs are cruel in the way we dont suppurt animal abuse but they dont have a law against it. the sad thing is that many places in china they cook the dog ... ALIVE they skin them alive it takes over 20 to make a fur jacket the worst part is the dog is still alive after they are skinned sign the petition ptroa.co.il/mainpage.php",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Utd-RaPpxPo/AAAAAAAAAAI/AAAAAAAAAAA/OyeTxD0v2B0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "darkriderkid",
        "commentId": "UgxD_wQAgWNori6U9ll4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-03-10T04:42:35.000Z",
        "likeCount": 0,
        "comment": "Lol the gun sounds made it 10 times better!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-LZOJQ89go1c/AAAAAAAAAAI/AAAAAAAAAAA/h0B2aT5aWvI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Collin Hudson",
        "commentId": "UgxC7r-n2H-sff3wnb94AaABAg"
    },
    {
        "publishedAt": "2011-03-09T16:01:50.000Z",
        "likeCount": 0,
        "comment": "wyglądają jak patałachy",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-AKCCCWkb648/AAAAAAAAAAI/AAAAAAAAAAA/CDT_bkaN9xk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Assmen12",
        "commentId": "UgwXmUliYJRXzx89usd4AaABAg"
    },
    {
        "publishedAt": "2011-03-09T06:36:39.000Z",
        "likeCount": 0,
        "comment": "Great sound effects and well timed",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-yIy9fS90DXc/AAAAAAAAAAI/AAAAAAAAAAA/_pC8RjIlVg4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "skawtaa",
        "commentId": "UgyaW134Bis6beeRlBd4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-03-07T14:43:11.000Z",
        "likeCount": 0,
        "comment": "@18mokeymo \nEXACTLY!  They eat anything! ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-DXD-Japeh3s/AAAAAAAAAAI/AAAAAAAAAAA/KbW-d7WQAtI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "snugglyaquarius79",
        "commentId": "Ugx33FQkxhLsxFxZ46Z4AaABAg"
    },
    {
        "publishedAt": "2011-03-04T23:38:59.000Z",
        "likeCount": 0,
        "comment": "this is why you wear a helmet",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-GeLr2XTZZHM/AAAAAAAAAAI/AAAAAAAAAAA/q4TUq5OpT3w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "johnny collins",
        "commentId": "UgwP9emJowBPiDp5l5t4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-03-01T19:53:20.000Z",
        "likeCount": 0,
        "comment": "@lacki1991 What about 0:22?",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-RvuCBwJmKSA/AAAAAAAAAAI/AAAAAAAAAAA/hn6Lel5ER5Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Henk De Vries",
        "commentId": "Ugy8YnLDi7SP_9yJNER4AaABAg"
    },
    {
        "publishedAt": "2011-03-01T00:06:20.000Z",
        "likeCount": 0,
        "comment": "0:22 is the best bail ..right up there with 0:58\n\nPs..love the sound track",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-TV5MazMmQc0/AAAAAAAAAAI/AAAAAAAAAAA/Rse_hms-TKA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jake Hyde",
        "commentId": "Ugx-RGj0p5wgvqML6F94AaABAg"
    },
    {
        "publishedAt": "2011-03-01T00:01:42.000Z",
        "likeCount": 0,
        "comment": "0:38 that looked so jank.\r\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Vm01Z6w_AHo/AAAAAAAAAAI/AAAAAAAAAAA/S7H9riQTTM8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pyroician126786",
        "commentId": "Ugxb9OkvBKrTFkWQRqh4AaABAg"
    },
    {
        "publishedAt": "2011-03-01T00:01:05.000Z",
        "likeCount": 0,
        "comment": "0:38 that looked so jank.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Vm01Z6w_AHo/AAAAAAAAAAI/AAAAAAAAAAA/S7H9riQTTM8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pyroician126786",
        "commentId": "UgwwrzIPnQCzPXYnlmp4AaABAg"
    },
    {
        "publishedAt": "2011-02-19T13:36:50.000Z",
        "likeCount": 0,
        "comment": "it&#39;s pretty stupid to go at these mountains without a helmet",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XrNde6PrjD0/AAAAAAAAAAI/AAAAAAAAAAA/Ji3KnK12mh0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "eyþór smári",
        "commentId": "UgwGZDo1Qxd5yY_3G9p4AaABAg"
    },
    {
        "publishedAt": "2011-02-19T13:36:25.000Z",
        "likeCount": 0,
        "comment": "it&#39;s pretty stupid to go at these mountains without a helmet ",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XrNde6PrjD0/AAAAAAAAAAI/AAAAAAAAAAA/Ji3KnK12mh0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "eyþór smári",
        "commentId": "Ugz8AtsdpWVp2rgn0O14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-02-18T16:02:17.000Z",
        "likeCount": 0,
        "comment": "Is escrima practical in street defense though? Do they teach defense without weapons?",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-4rn8LUF6bYk/AAAAAAAAAAI/AAAAAAAAAAA/Rdk7MAxF2nA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ajatkinson2004",
        "commentId": "UgyrZVxjGOED3o2IJ6R4AaABAg"
    },
    {
        "publishedAt": "2011-02-17T14:15:42.000Z",
        "likeCount": 0,
        "comment": "His brother Remy had done a whole slew of seminars with Wally Jay and i can see his influence on all the Presas brothers styles.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-I11qWBgZlLQ/AAAAAAAAAAI/AAAAAAAAAAA/5RA9A_gpGrE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Doug Grun",
        "commentId": "UgyL4ykA4SZ1pTfBuot4AaABAg"
    },
    {
        "publishedAt": "2011-02-17T04:02:32.000Z",
        "likeCount": 0,
        "comment": "So much tension. Both guys end up wrestling with each other.",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-R_HJ2184zPA/AAAAAAAAAAI/AAAAAAAAAAA/Z-zPGB7Jglg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TangoLima1",
        "commentId": "UgwcAU4cI-jctxcsRjJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-02-13T00:57:40.000Z",
        "likeCount": 0,
        "comment": "Huh? what is the matter with you all (white boys)?!!!!!   fuck u!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-g2jhLQiF9b8/AAAAAAAAAAI/AAAAAAAAAAA/_FZw1Bvwl10/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Melody Veylupek",
        "commentId": "Ugw9XIKYd_GOar9i0Nt4AaABAg"
    },
    {
        "publishedAt": "2011-02-07T06:24:53.000Z",
        "likeCount": 0,
        "comment": "Love his passion, you can see how excited he gets the more he goes on. ",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-NztRy1fGAas/AAAAAAAAAAI/AAAAAAAAAAA/bBOhg15cqoM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Aaron Patacsil",
        "commentId": "Ugy1MvW3XEn9KBSSh714AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-02-06T02:11:39.000Z",
        "likeCount": 0,
        "comment": "nice sound effects!!!! lol u timed them just right",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Cv4pPWgGuQY/AAAAAAAAAAI/AAAAAAAAAAA/wfJ3xq7-I_o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tyler Kopek",
        "commentId": "UgwbzJUWO1jyQxtbn0h4AaABAg"
    },
    {
        "publishedAt": "2011-01-31T02:08:16.000Z",
        "likeCount": 0,
        "comment": "dude sick vid but fuckin annoying gayass soundtrack",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Zgr4K0jJVLo/AAAAAAAAAAI/AAAAAAAAAAA/-Ch1JwYIyzM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jacotaco69",
        "commentId": "UgxzDvaP3Fw5Z-AHPmR4AaABAg"
    },
    {
        "publishedAt": "2011-01-29T17:42:12.000Z",
        "likeCount": 0,
        "comment": "what DONT they eat 0_0",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-0rQWc9zhUSs/AAAAAAAAAAI/AAAAAAAAAAA/2hBciJBWXFk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Missael Giron-DeLaCruz",
        "commentId": "UgygipLOU0PIMBk_17N4AaABAg"
    },
    {
        "publishedAt": "2011-01-24T01:55:32.000Z",
        "likeCount": 0,
        "comment": "@gameofdeath4288 When I shot this they seemed fine with each other. They were at the academy at the same time to see Chris Chan who was visiting from California.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwAJ4eQgbZZxwpbHJN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-01-23T20:59:12.000Z",
        "likeCount": 0,
        "comment": "..and on a side note i really want to learn this style, good demonstrations all around, its amazing how times have changed from robes and beards to black ties and keys jingling. i plan on coming to china to learn this style and any other style my western mind can&#39;t fathom, im just wondering what martial arts is like over there, controlled and money-making or are u able to find a place where it is its own being (like a school/teacher off the beat and track)?",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-AMBJh7xI-Cc/AAAAAAAAAAI/AAAAAAAAAAA/2SpANcsAGH4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bombb82",
        "commentId": "Ugz0yHek34PF3l3ldPJ4AaABAg"
    },
    {
        "publishedAt": "2011-01-23T20:48:47.000Z",
        "likeCount": 0,
        "comment": "@ChenChanming channelling chi i personally believe is a neccessity in any form. even in the the more raw fight styles like muay-thai and boxing(cockfighting) you can still channel chi to better your strength, focus, speed, balance, etc.. though i find when im practicing a more technical martial art more suited for energy distribution its easier to hone my chi and &quot;lock&quot; it better, i feel it come undone more-so when i practice the brute warrior force of muay thai.. more training i suppose.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-AMBJh7xI-Cc/AAAAAAAAAAI/AAAAAAAAAAA/2SpANcsAGH4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bombb82",
        "commentId": "Ugx-_GhvNsGFFCR2yoR4AaABAg"
    },
    {
        "publishedAt": "2011-01-20T18:04:49.000Z",
        "likeCount": 0,
        "comment": "0:23 crazy helicopter spin lol XD",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-G-zCs28-hag/AAAAAAAAAAI/AAAAAAAAAAA/V0Ks-KlrTNg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Elev8ed Potential",
        "commentId": "UgwU3Ad3NZlqmlZLSkp4AaABAg"
    },
    {
        "publishedAt": "2011-01-18T11:18:28.000Z",
        "likeCount": 0,
        "comment": "Take a﻿ deep breath\r\n\r\n2Clap your hands twice\r\n\r\n3think﻿ of someone you﻿ like\r\n\r\n4copy this﻿ to 2 videos\r\n\r\n5 look at your﻿ hands\r\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-O9ZWTGy0nEw/AAAAAAAAAAI/AAAAAAAAAAA/fCZ2FAA7hC4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ismo Laitela",
        "commentId": "UgzENAOPLhgbNJNgaOx4AaABAg"
    },
    {
        "publishedAt": "2011-01-10T05:24:50.000Z",
        "likeCount": 0,
        "comment": "@slashd \r\nYou have to realize how slow he is really going in order to show the technique. No technique, and I mean NO technique, will work if you don&#39;t move quickly and decisively. I have used some of what is shown at speed and believe me it does work. As for other takedowns, if you train to know what you are seeing then you can react accordingly. Having fought wrestlers who train for sport, it is different when you are trying to kill them. Reality versus sport. Big difference.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-z-HGMmM0kWg/AAAAAAAAAAI/AAAAAAAAAAA/lNH0Q98LXRc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tarnshadowhawk",
        "commentId": "UgxKtlbmt6CMKrFmo9F4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-01-08T02:17:08.000Z",
        "likeCount": 0,
        "comment": "well now ive seen it all. The only thing worse than their technique is what the hell the guy on the right is wearing. what in the hell was he thinking to wear panties? no really.....that makes me mad, and i dont know why, but it does..... fucking what the fuck???",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-rRDjP2aEzYw/AAAAAAAAAAI/AAAAAAAAAAA/78RldavLhZM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "KiD Vicious",
        "commentId": "UgwQuS_HdyP7b1jint94AaABAg"
    },
    {
        "publishedAt": "2011-01-07T02:08:08.000Z",
        "likeCount": 0,
        "comment": "@QuantumQuacks its not about how they eat dogs its about how they treat them before they eat them you ignorant fool so shut the fuck up",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Rnv5fb-zPpU/AAAAAAAAAAI/AAAAAAAAAAA/SgsXUTuzpjg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Diixi",
        "commentId": "Ugym1K8zPtWXo7Bxr0p4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2011-01-07T00:29:39.000Z",
        "likeCount": 0,
        "comment": "I WENT BIG AND I WENT HOME!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Z8XVTuZNYQo/AAAAAAAAAAI/AAAAAAAAAAA/d1u5AilpTTo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "MasterNoremac77",
        "commentId": "UgzUFhAOXj6UwQ2zyhN4AaABAg"
    },
    {
        "publishedAt": "2011-01-06T20:02:31.000Z",
        "likeCount": 0,
        "comment": "Thats why I ALWAYS wear a helmet...",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-MKVVbrJj4kM/AAAAAAAAAAI/AAAAAAAAAAA/a7Jtrop4CMI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Oligarchy",
        "commentId": "UgxRmvvEHKXnnI68HeJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-01-05T17:39:32.000Z",
        "likeCount": 0,
        "comment": "@ChakraSony Not the uploader, but maybe I can help?\nI don&#39;t practice this style of escrima but I do practice another style (Bahala Na Giron Arnis Escrima). We practice defending against strikes from all angles so that we would be ready in a real fight.\nGranted in a real fight strikes will come faster but this is a demo, it&#39;s not unusual for the strikes to be slower. They may practice against faster strikes (I certainly do). Also in a street fight your opponent is likely to telegraph his punches.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-FQRK9SO9I1w/AAAAAAAAAAI/AAAAAAAAAAA/6iY9FC9EiYY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RememberJudas314",
        "commentId": "UgzIJJ5X12a5-MvgVhR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-01-04T16:33:23.000Z",
        "likeCount": 0,
        "comment": "Ever wonder where guinea pigs got their name...? Where their from the locals gut&#39;um ram a stick up their asses spin&#39;um over the fire pit and eat&#39;um for dinner... same with pigeons...! pigeons are delicious....!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NLsVwF2UTmg/AAAAAAAAAAI/AAAAAAAAAAA/I-Po9NrYUBk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lemmyliquor",
        "commentId": "Ugz4y21Eqm1Ew6S4Htl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2011-01-04T16:32:01.000Z",
        "likeCount": 0,
        "comment": "@alipouya ever wonder where guinea pigs got their name...? Where their from the locals gut&#39;um ram a stick up their asses spin them over the fire and eat&#39;um for dinner... same with pigeons...!  pigeons are delicious....!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NLsVwF2UTmg/AAAAAAAAAAI/AAAAAAAAAAA/I-Po9NrYUBk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lemmyliquor",
        "commentId": "UgyMMko1U40ox2DSeJ94AaABAg"
    },
    {
        "publishedAt": "2011-01-02T04:48:39.000Z",
        "likeCount": 0,
        "comment": "haha nice like the gun effect on the video",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-8K2Z9Dy2y8I/AAAAAAAAAAI/AAAAAAAAAAA/EhgU1mxNiS4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jorge Lara",
        "commentId": "Ugw8rK2XgkAXZ8CCuXB4AaABAg"
    },
    {
        "publishedAt": "2010-12-24T11:46:03.000Z",
        "likeCount": 0,
        "comment": "u eat enything that move",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-OmFiIiMfCYI/AAAAAAAAAAI/AAAAAAAAAAA/I_vaR3rEs_0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Yohnnn Verrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrjew",
        "commentId": "Ugz8yueL4tkAU8yAM4J4AaABAg"
    },
    {
        "publishedAt": "2010-12-24T11:45:44.000Z",
        "likeCount": 0,
        "comment": "fucking daft homofobic chinks how u like it if we eat you",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-OmFiIiMfCYI/AAAAAAAAAAI/AAAAAAAAAAA/I_vaR3rEs_0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Yohnnn Verrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrjew",
        "commentId": "UgxPP2ggNgYw3jQ5GD14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-12-23T13:43:40.000Z",
        "likeCount": 0,
        "comment": "fate prima a buttarvi senza snowboard!  ahahahahah\r\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Anxg_tYfaqI/AAAAAAAAAAI/AAAAAAAAAAA/KKeftDTr0j0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Pils Pereira",
        "commentId": "UgyUYqmWoNY2xpG0OSJ4AaABAg"
    },
    {
        "publishedAt": "2010-12-19T17:29:42.000Z",
        "likeCount": 0,
        "comment": " stop dumb people ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-dWDONDO-egI/AAAAAAAAAAI/AAAAAAAAAAA/AGaqqrSOLBA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "denise reyes",
        "commentId": "UgzolKkC8rb4QJZ51UZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-12-11T14:08:04.000Z",
        "likeCount": 0,
        "comment": "Rest In Peace Great Grandmaster Ernesto Presas! He was truly a amazing teacher and had great passion on hes art. He will be missed",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-KZ6lF_3Ty7E/AAAAAAAAAAI/AAAAAAAAAAA/p6jPNrdLL4Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "orgea",
        "commentId": "UgzQ9JBOLRsC7nft05d4AaABAg"
    },
    {
        "publishedAt": "2010-12-10T20:19:28.000Z",
        "likeCount": 0,
        "comment": "@ph8tel i thought that&#39;s where these effects came from... :P",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-HCj1FAgdSNk/AAAAAAAAAAI/AAAAAAAAAAA/-Upkuullabo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Chris",
        "commentId": "Ugzy9RRveV17FWkj6ZZ4AaABAg"
    },
    {
        "publishedAt": "2010-12-08T17:03:49.000Z",
        "likeCount": 0,
        "comment": "Pity about the stupid sound effects.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-_wP-yNP2pJI/AAAAAAAAAAI/AAAAAAAAAAA/UZi9FHkWl2c/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "OPOCHKA",
        "commentId": "Ugwq9F9DXt9t6R4MCwZ4AaABAg"
    },
    {
        "publishedAt": "2010-12-07T09:04:13.000Z",
        "likeCount": 0,
        "comment": "I like last:D",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-LsB_8aDQ7DU/AAAAAAAAAAI/AAAAAAAAAAA/Ge1Y7L9xwbA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gusts Jermacans",
        "commentId": "Ugx9dim0zZcBd7dbGYt4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-12-04T16:43:03.000Z",
        "likeCount": 0,
        "comment": "@ilovecreampie2 Did you Read my comment? Who The Fuck Eats A Dog? You Idiot. Im Not from ISRAEL or whoever fucking eats dogs.  I bet you just said &quot;you&quot; Because your people eats Dogs. AND dogs are for pets NOT EATING!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-LQgGBXnkaOE/AAAAAAAAAAI/AAAAAAAAAAA/-zclQiDezTE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "alipouya",
        "commentId": "Ugzb4y2VEkXBNUrDXrB4AaABAg"
    },
    {
        "publishedAt": "2010-12-04T13:01:17.000Z",
        "likeCount": 0,
        "comment": "@alipouya you",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-e1duLddfIUg/AAAAAAAAAAI/AAAAAAAAAAA/zIMMCe27FYE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "soohyun kim",
        "commentId": "Ugyg0NGKRFS5tqlPzg54AaABAg"
    },
    {
        "publishedAt": "2010-12-03T00:13:57.000Z",
        "likeCount": 0,
        "comment": "@kwansao - Well,...anything is possible . Funny thing is , except for the missing Bong Sao section , everything else is Yiu Choi - same as what I learned ,though it was done very quickly -in a hurry and without frills . -Regards",
        "videoId": "nxRR3CBRbVM",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugx1iYKxbwjDyki-QnR4AaABAg"
    },
    {
        "publishedAt": "2010-12-02T16:32:57.000Z",
        "likeCount": 0,
        "comment": "@sjander29 you know i think that some people actually perform it that way...",
        "videoId": "nxRR3CBRbVM",
        "authorThumbnail": "https://yt3.ggpht.com/-a7sqxAtZzFU/AAAAAAAAAAI/AAAAAAAAAAA/0cRiwku5EuY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "US Wing Chun Kung Fu Academy",
        "commentId": "Ugyc4zCm1vFvaIQvVjR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-12-02T16:29:23.000Z",
        "likeCount": 0,
        "comment": "hey your site is down now, i can&#39;t see how it ends!  lol.   i recognize him. love it.",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-a7sqxAtZzFU/AAAAAAAAAAI/AAAAAAAAAAA/0cRiwku5EuY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "US Wing Chun Kung Fu Academy",
        "commentId": "UgzWF98CIlJ6MNZoqqF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-29T21:05:11.000Z",
        "likeCount": 0,
        "comment": "Who The Fuck Eats a Dog?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-LQgGBXnkaOE/AAAAAAAAAAI/AAAAAAAAAAA/-zclQiDezTE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "alipouya",
        "commentId": "UgwXkTvgFbgQhG_lpDJ4AaABAg"
    },
    {
        "publishedAt": "2010-11-28T15:33:24.000Z",
        "likeCount": 0,
        "comment": "gotta say this ruled.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-90vE3CBjrh0/AAAAAAAAAAI/AAAAAAAAAAA/UfAeLzU1LzU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Shark Vs Whale",
        "commentId": "UgyJ0QwPmW5YsdVGOUN4AaABAg"
    },
    {
        "publishedAt": "2010-11-24T22:14:50.000Z",
        "likeCount": 0,
        "comment": "funny as fuck",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-rK4fU5LjQ-c/AAAAAAAAAAI/AAAAAAAAAAA/rQmxdFzgDS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kyle mark",
        "commentId": "UgzSGdtuhFlHtpykyRB4AaABAg"
    },
    {
        "publishedAt": "2010-11-21T16:29:23.000Z",
        "likeCount": 0,
        "comment": "Islam is human eating:\n\nAsia Bibi, a Christian wife and mother of five, was sentenced to death this week in Pakistan with a fine of $700 (=2.5 years&#39; wages) for the crime of blaspheming the Prophet Mohammed, because she responded to her muslims&#39; relentless pressuring her to convert to Islam by by explaining how Jesus had died on the cross and asking what Mohammed had done for them.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-DDwToG2FAdA/AAAAAAAAAAI/AAAAAAAAAAA/LuqRKXEEyps/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Grace Bride",
        "commentId": "UgyNl92pLDY7ZZpmTnh4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-21T16:06:09.000Z",
        "likeCount": 0,
        "comment": "Dog eating is considered inhuman. What about stoning women to death, bombing both muslims and non-muslims into pieces in Islam? ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "Ugx-gw-H4KUCbhNTg1V4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T17:12:27.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling btw you E-X-P-O-S-E-D urself and your PAUL\n\nROFL \n=D",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugy3AL7lolK9owST0rR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-20T17:11:32.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling \n&quot;Muslims are proved liars and believing into a mohammedan lie.&quot;\nproved by whome????  LOLZ by haterzzzzz \no sorry i didnt knew that there was a BENCH MARK for it\nBARK me NEVERRRrrrr\\\ni think your the one who barked in this video. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgyXN6-u-mYJp12Qxx94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T17:08:24.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling JESUS(AS) OR MUHAMMAD(SW) in OT 6 of 6\n4) &quot;he shall speak unto them all that I shall command him&quot;. In the Noble Quran, Prophet Muhammed (pbuh) was commanded to say: \n&quot;Say: He is Allah, the One! Allah, the eternally Besought of all! He begetteth not nor was\nbegotten. And there is none comparable unto Him.&quot; (Surah 112:1-4)\nCONCLUSION. The prophet mentioned in Deut 18:18 CAN NEVER EVER be Jesus but rather the Last Prophet of God, Muhammed (pbuh).\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgygKRqdMNceBibmRtp4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T17:08:03.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling JESUS(AS) OR MUHAMMAD(SW) in OT 5 of 6\n3) &quot;will put my words in his mouth&quot;. Angel Jibreel literally put the words of God in Prophet Muhammed&#39;s (pbuh) mouth by telling him the words like &quot;iqraa!&quot; or READ! And he will just repeat after him. Jesus as god does not even need to be put words in his mouth - he has the words already BUILT-IN.\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugzw2UNKAtLp9stBK1Z4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T17:07:18.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling JESUS(AS) OR MUHAMMAD(SW) in OT 4 of 6\nJesus was rejected by his own people - &quot;He came unto his own, but his own received him not.&quot; John 1:11. Moses and Muhammed brought new laws for their people. Jesus according to the Bible did not bring any new laws. (Mathew 5:17-18). Moses and Muhammad encountered their enemies and were victors. Jesus DID NOT, they crucified him. Moses and Muhammad were normal human. Jesus according to you is god.\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugx-XmFxpwTE6Vz_hmN4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T17:06:40.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling JESUS(AS) OR MUHAMMAD(SW) in OT 3 of 6\n2) &quot;like unto thee&quot;, i.e. Moses. Let&#39;s compare Moses VS Jesus, Moses VS Muhammed. Moses and Muhammed born naturally from father and mother. Jesus unnaturally from mother ONLY. Moses and Muhammed married and have children. Jesus NO! Moses and Muhammad died natural deaths. Jesus crucified and arose 3rd day. Moses and Muhammed were accepted as Prophets by their people in their lifetime.\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgwB5iuMZ3d-i-zMx1B4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T17:06:05.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling JESUS(AS) OR MUHAMMAD(SW) in OT 2 of 6\n1) &quot;Prophet from among their brethren&quot;. Abraham has two sons, Ishamel (first-born) and Isaac. The former&#39;s descendants are the Arabs of which Prophet Muhammed (pbuh) sprung. The latter&#39;s descendants are the Israelis of which Moses came. Therefore, the Arabs and Israelis are brethren through Abraham. If God means Jesus or any Israeli prophet, he should have said instead &quot;from among themselves&quot;. So, 1 POINT for Prophet Muhammed (pbuh).\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgwD-DO6kX_kBDB7HD54AaABAg"
    },
    {
        "publishedAt": "2010-11-20T17:04:37.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling  JESUS(AS) OR MUHAMMAD(SW) in OT 1 of 6\nyou say jesus(AS) was prophecised in OT.\ni find it in Deut 18:18: &quot;I will raise them up a Prophet from among their brethren, like unto thee, and will put my words in his mouth; and he shall speak unto them all that I shall command him.&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugz1EHdZUwLXdCAE2HZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:59:36.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nNow i know you don&#39;t know Christianity at all! Catholics are NOT Christians, for they don&#39;t follow the Bible. The POPE is blasphemy.\n\nThe Purpose of Debate is to find out the Truth. Debate only makes sense when both debaters respect facts and figures. Muslims are proved liars and believing into a mohammedan lie. Thus debating with muslims makes no difference from barking back at a mad dog.\n\nNow I have decided to shut my door at your mad barks.\n\nTake care, have a nice day barking :))",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgxGjdjQPBkCZMwk3IZ4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:58:36.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling LOLZ massiah and the prophet.\nok i will tell you about the OT as well WHAT it was prophecised.\nlets begin shall we\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgyEfw1QbQwD4wlB7ax4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:55:23.000Z",
        "likeCount": 0,
        "comment": "@GhostofBattosai he is nt even a mambo-jambo master",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugx1XqWBRuLp4ag9wWd4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-20T16:53:41.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nApparently you are not able to understand God&#39;s Word in the Holy Bible. I suggest you to buy a MacArthur Study Bible, so that you may live.\n\nMark 10:18 Jesus said this to show people: &quot;why do you call me good yet you don&#39;t believe that I am God, the Son of God and the Messiah, as prophesied to you in the old testament for 1500 years?&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgzczSm1eIeiaOJyNyB4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-20T16:53:07.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling LOLZ you wish\nSame old same old.\nyou people only have Blame Blme Blame.\nif you think you are right wh is that NOT a single Trinitarian of PAUL couldnt answer him.\neven your pope refused to come to debate. \nI WONDER WHY?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugx6aZPZtDFTZW58yzt4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:50:20.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling BTW no hard feelings from my side\nand \nIF\nyou want to research and gain nowledge go to these channel\n/user/BeholderGuard\nand\n/user/BibleQuiz62\nBibleQuiz62 is newly convert. he is very very nice person. we respected him before he accepted Islam as he was an organizer of BiBLE quiz a Beholderguards channel.\n\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugxbplrqz1l3u0YsVF54AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:47:49.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07 \n\nGod gave Ahmed Deedat what he asked for in 1996: completely mute &amp; paralyzed for 9 years before death finally claimed him, after he foolishly &amp; vigorously attacked the Bible for 11 years!\n\nNow it is even worse for him: he is now being burn in hell for his grave sins. I feel sorry for him, though he is still yelling he is the winner in hell.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgxZo2RF-qD0Ta0SJkl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-20T16:43:06.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling O WOW a household\nnow dats another one AGAIN\nlets analyze it:\n1=GOD\n1=AGAIN GOD\n1=AGAIN AGAIN GOD\nbut stil there is only one god. So isn&#39;t GOD contradicting by saying only one god\nalso in\n&quot;Jesus said to him, &quot;Why do you call me good? No one is good except God alone&quot; Mark 10:18\nhe is saying GOD alone is good why did he not said about himsel.\nWOW imagine god saying he is NOT GOOD.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugyl1NnJxT_sQk5JHOB4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:36:12.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nyou finally get it: 1+1+1 = 1\n\none husband + one wife = one household\n\none husband + one wife + one child = one household\n\none husband + one wife + n children = one household",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgxGtNH4o_ox0kDzYf54AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:33:59.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nAisha said, &quot;The Apostle of Allah ... RAPED me when I was 9 years old.&quot; (Abu Dawud, Vol. 2, #2116; Tabari, vol 7, P7; vol 9, P 131; SAHIH MUSLIM VOL 2, #3309)",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "Ugz3pD-MxklBmTGp6aB4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:32:49.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling Deedat (mey Allah bless him) he was the biggest EXPOSER of the followers of PAUL and haters\nyou people are scared of what he has done and that is he TOTALLY exposed your LIES\nLOLZ\nnot a single TRINITARIAN matched him",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugwqz0ECcdOBeljOSjd4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:30:55.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling \nrape and murder part  i already refuted ur claim\ndont expose urself more\nbut it still remains 1+1+1=1\nwow now dat is amazing",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgwCMZRYC9TtxAxAgLd4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:28:36.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling instead of you trying to make a PERSON(WIFE) a better person you simply ignore her doings and without giving her advice.\nBut we arfe forced to give and take advice.\nunlike others we donot follow blindly",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgxpcM4md173hhdwWWh4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:25:17.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nDeedat never said he was a man, so we all know he was a woman :)))\n\nMohammed was a false prophet, a pedophile, a rapist, a murderer, a liar ... :))) you cannot tell evil from good, or good from evil",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgyAwaUcWCJrXNBijnJ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:23:10.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nI don&#39;t rape or have sex with my wife. \n\nI make LOVE with my wife. Love is mutual. I make sure my wife enjoy my LOVE!\n\nSex without love is lust, sex without your wife&#39;s willingness is rape.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgyXwKAzkcIo_jSO-Lx4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:20:03.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07 \n\nYes. I will hug my whore wife, pray for her &amp; wait patiently for her return. If she deserves punishment, let God do it. Because I have made the commitment to her before God. My wife and I are ONE before God and cannot be separated!\n\nWe, human being, are a big whore against God. Yet God Himself still came down from heaven, died in our place to take away our sins, so that we may live with Him forever.\n\nLearn about LOVE from God, Jesus Christ! You big whore!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgxRI6G17RvzMXetD9d4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:15:38.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling \nLOLZ christ hahahahaha\nyou people are TERMITES \nfollower of FALSE apostle PAUL who invented CHRISTIANITY\nLOLZ\nJesus(AS) never ever in his whole life said he is god OR he even said 1+1+1 = 1\nLOLZ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugw_V1xuScUM1i6QJoF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:13:21.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nGood for you! Islam is cancer, that&#39;s why it grows fast! Each new born in a muslim family is a new cancer cell :))\n\nThe world is dying because of the islam cancer. The only CURE for the world is Christ&#39;s return, and thrown all muslims into the pit as promised in Ezekiel 36.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgylNsQdYlS7ndcMiQZ4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:08:50.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling and still Islam is the fastest growing religion.\nand mostly in europe and Usa it is increasing.\n\nLOLZ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugyz7AwhXRTUzF7rrPR4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T16:07:42.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling again side tracking.\n(SIGH) same old HATERS. \nyou said every1 knows about what Islam did. KILL &amp; RAPE\nLOLZ\ni think you forgot to read in the quran which says that who ever kills a single human kills all humanity\nand about the RAPE: i think you also forgot to read that ADULTRY is the biggest sin in our religion. we can have intercourse with only our wife SO HOW COME WE CAN DO RAPE.\nLOLZ\nHATER = LIARS, LIARS and LIARS",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugw5CyQWWgNlhJBQm9F4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-20T16:03:19.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling \n2:98\n&quot;Whoever is an enemy to Allah and His angels and apostles, to Gabriel and Michael,- Lo! Allah is an enemy to those who reject Faith.&quot;\n\nso you want me to say if ur enemy of mine i should be ur friend inspite of ur grudge etc",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugz-UlODTLRDMkDBsXN4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:58:12.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling all these verses are answerable. and many have answered them\nE.G.\n2:191-193\nIn this verse it was ordered to muslims that as the non-muislim did to you when you accepted ISLAM when they became your sworn enemies and they killed your brothers and sisters so shall you if they still want to be your enemies. It was seen in makkah conquest that the Holy prophet announced that those who will lay down there weapons they are forgiven and those who do not then they will be dealt with.\n",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgxTp4cKydTGn25OoyN4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:53:46.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling o yes these verses\nnow you have changed the subjects to war.\nVERY NICE OF YOU LIAR.\nit is a same old tactics by you HATERS whenever your true face is about to be blown you change the topic ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgzJu9YjA4ez_65rNpd4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:49:40.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nthere are tons of muslim garbage, &amp; tons of islamic poisons, to turn the entire world into a graveyard. ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgzqJSulNJ6HfRF6HWd4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:41:13.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling ROFL\nagain lied\ni said show me a SINGLE verse from the QURAN. \nthere are tons of weak hadith. \nbut Quran is un corrupted till today. \nLOLZ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgzHHVowIMlIb2EjKSB4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:27:33.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\n&quot;If you see a woman in front she is a devil.&quot; \u2013\u2013 Muhammad (Ghazali p.2.26)\n\n\u201cA woman is the string of the devil.\u201d \u2013\u2013 Muhammad(Ghazali p.3.87)\n\nSahih Muslim 8.3240, 3242, Sunaan Abu Dawud 2.11.2146 says: &quot;A woman advances and retires in the shape of a devil.&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgwQXds1X-8hXZ3Bc_l4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T15:22:37.000Z",
        "likeCount": 0,
        "comment": "@ahsanit07\n\nFrom Muslim Number 1032\nAbu Dharr reported: The Messenger of &#39;Allah said: When any one of you stands for prayer and there is a thing before him equal to the back of the saddle that covers him and in case there is not before him (a thing) equal to the back of the saddle, his prayer would be cut off by (passing of an) ass, woman, and black Dog ... I asked the Messenger of Allah as you are asking me, and he said: The black dog is a devil.\n\nGoogle &quot;islam and animals&quot; &quot;muhammad and dogs&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "Ugz3EgpGfhGRmcsAKel4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T14:04:38.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling LOLZ an another lie of urs.\nBLACK DOGS\nROFL------------i think its one of ur beliefs not OURS\nShow me a single verse which says these things.\nall ur lies and fraud",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "UgwsiTYOmUzpayo3ihN4AaABAg"
    },
    {
        "publishedAt": "2010-11-20T14:02:38.000Z",
        "likeCount": 0,
        "comment": "@Mohammediscalling LOLZ u wish women to be devil.\nand ur lieing about Islam.\nIn Islam was the religion which stoped female burrial at the time of birth.\nIslam is the one which gave inheritace to women.\nAND most of all In islam it s said &quot;HEAVEN is under the feet of YOUR MOTHER&quot;\nDon&#39;t tell me that ur mother is not WOMAN",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MXTAQwL4Fd4/AAAAAAAAAAI/AAAAAAAAAAA/Jx3N-h6fCxc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ahsan Siddiqi",
        "commentId": "Ugz5shB0PyWTyHs_npR4AaABAg"
    },
    {
        "publishedAt": "2010-11-19T22:26:26.000Z",
        "likeCount": 0,
        "comment": "In Asian countries/cultures, dogs/cats are not pets. They are working animals: dogs guard your property; cats catch the mouses. In islam, dogs and women are devils, especially black dogs.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt__Mm4JQ6w/AAAAAAAAAAI/AAAAAAAAAAA/wymHhmMaCy0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mad Muhammad",
        "commentId": "UgwQO9pcuG_1GijFAah4AaABAg"
    },
    {
        "publishedAt": "2010-11-18T13:56:19.000Z",
        "likeCount": 0,
        "comment": "OMG.....",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-sKhOHPSOato/AAAAAAAAAAI/AAAAAAAAAAA/2oaeWfv-zm4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "zuliza8582",
        "commentId": "Ugw3SEU29BtG0DV4LVx4AaABAg"
    },
    {
        "publishedAt": "2010-11-09T03:06:19.000Z",
        "likeCount": 0,
        "comment": "@tomwalker389 jajajajaja you got lucky that he does not listen to you ... this man fight since you were in your father&#39;s eggs",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-nzFdEj9EJ08/AAAAAAAAAAI/AAAAAAAAAAA/kqRJQukIZgY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tutanota",
        "commentId": "UgzjSHtDlWilzfASWzt4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-11-08T08:43:57.000Z",
        "likeCount": 0,
        "comment": "@rickeygentile \nWell if you are what you eat, what do you eat then?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-wR4kczkqYCM/AAAAAAAAAAI/AAAAAAAAAAA/drCdldV4BBY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Malice3hree",
        "commentId": "UgxIZ5BFJ0gVElZmREB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-11-03T12:38:40.000Z",
        "likeCount": 0,
        "comment": "O mah gawd I love his accent!",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-ZXIqwP6BJFk/AAAAAAAAAAI/AAAAAAAAAAA/CzmM82vGsLg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SHUTxUPfoo",
        "commentId": "UgwOPLOkTHd3jYxvZCJ4AaABAg"
    },
    {
        "publishedAt": "2010-11-01T19:08:28.000Z",
        "likeCount": 0,
        "comment": "in soviet russia the snow rides you",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-2tIt-7VSDQo/AAAAAAAAAAI/AAAAAAAAAAA/UxYEGqUgF6A/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Denisenko",
        "commentId": "Ugxw5R1Ng-XBnPPCywt4AaABAg"
    },
    {
        "publishedAt": "2010-10-28T18:40:45.000Z",
        "likeCount": 0,
        "comment": "0:13 boom headshot",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Gx8A308JV44/AAAAAAAAAAI/AAAAAAAAAAA/ycqEZZb4ADI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "A.M. WYO",
        "commentId": "UgzU3Hx8FcGdx95rJUt4AaABAg"
    },
    {
        "publishedAt": "2010-10-19T14:08:27.000Z",
        "likeCount": 0,
        "comment": "i reckon lollypop face is gonna fuck him up",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-7plc9Sb8E6k/AAAAAAAAAAI/AAAAAAAAAAA/vv2hdjKqpRs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jnb888",
        "commentId": "UgwSLWe5kkz_Q0aylNx4AaABAg"
    },
    {
        "publishedAt": "2010-10-16T16:00:52.000Z",
        "likeCount": 0,
        "comment": "It looks good but i&#39;m wondering how effective it is when in a streetfight the opponent fakes a punch, does a groin kick or just does a wrestler leg takedown.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-B3n__NwYq_Q/AAAAAAAAAAI/AAAAAAAAAAA/cmaVtHrN18o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "slashd",
        "commentId": "UgzL_3lytmj1j3VmhHJ4AaABAg"
    },
    {
        "publishedAt": "2010-10-12T15:29:45.000Z",
        "likeCount": 0,
        "comment": "omg this looks badass",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-I6hPRqSLkJY/AAAAAAAAAAI/AAAAAAAAAAA/h_-kCuRge04/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Obsidian1392",
        "commentId": "UgxPvshOb32i6YxmLW54AaABAg"
    },
    {
        "publishedAt": "2010-10-09T19:48:07.000Z",
        "likeCount": 0,
        "comment": "diskasting",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-fWawWXMUb-c/AAAAAAAAAAI/AAAAAAAAAAA/Q7Em5So335s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hkitty1907",
        "commentId": "Ugzq1A7WC7KSpRKpeq54AaABAg"
    },
    {
        "publishedAt": "2010-10-06T04:52:27.000Z",
        "likeCount": 0,
        "comment": "yay i got 26 kills 3 deaths in that video\r\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-ouc7xFcT9eg/AAAAAAAAAAI/AAAAAAAAAAA/l0_B0ULp1cg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "yohan coderre",
        "commentId": "Ugx-auSd6OWPYRjQszd4AaABAg"
    },
    {
        "publishedAt": "2010-10-01T14:20:08.000Z",
        "likeCount": 0,
        "comment": "das sieht i.wie nach pussyfight aus xD",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-rIT9KfFKMrA/AAAAAAAAAAI/AAAAAAAAAAA/6cWqahE_MQw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TarkBounce",
        "commentId": "Ugx5pTXbcSev47RZ0kp4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-09-30T09:58:16.000Z",
        "likeCount": 0,
        "comment": "Do they fight in some kind of mixed martial arts tournament? Recently I&#39;m very interested in WC, however, I&#39;d like to see them in practice before I start learn it. I&#39;m sure, that there are a lot of WC students on you tube, who had the same doubt as me. ",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-ahQxqTlTV6w/AAAAAAAAAAI/AAAAAAAAAAA/ECFnpsTnpRc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rudystrong1",
        "commentId": "UgyvTGzFaEuTzCzbM-F4AaABAg"
    },
    {
        "publishedAt": "2010-09-29T00:42:42.000Z",
        "likeCount": 0,
        "comment": "looks ok. when i visit vietnam or china i guese im going to order one.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-zPkGqq6UuyM/AAAAAAAAAAI/AAAAAAAAAAA/3ju_zd5Qazs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Peter Chau",
        "commentId": "UgxJAOXEOEWrVhLJbfh4AaABAg"
    },
    {
        "publishedAt": "2010-09-22T21:00:00.000Z",
        "likeCount": 0,
        "comment": "i could not bring myself to eat dog&#39;s no matter were it&#39;s at.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MAOR7y7RfHI/AAAAAAAAAAI/AAAAAAAAAAA/EpgYpoltA_o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xxconaxx360",
        "commentId": "UgxShmshXJs_nq8QUWt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-09-17T00:21:21.000Z",
        "likeCount": 0,
        "comment": "ok fuck that was GREAT!!! sick vid",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-RynzOpm_8Js/AAAAAAAAAAI/AAAAAAAAAAA/F3KUUcTi1Fk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "streetracer13440",
        "commentId": "UgzsKuGYw3dTJXSDH_F4AaABAg"
    },
    {
        "publishedAt": "2010-09-16T11:21:42.000Z",
        "likeCount": 0,
        "comment": "the 2nd guy obviously shouldnt take jumps...",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-mWkeZLx1auQ/AAAAAAAAAAI/AAAAAAAAAAA/2T5ZJnel6Uc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Marcus",
        "commentId": "UgxFZRUvDJFhmgAlV314AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-09-06T18:38:03.000Z",
        "likeCount": 0,
        "comment": "What&#39;s with the guy in his underwear?",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-n6uMQo4sD1k/AAAAAAAAAAI/AAAAAAAAAAA/K028YBsGrQY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "WingChunLover2",
        "commentId": "Ugx7GIVitvHG1rycSLR4AaABAg"
    },
    {
        "publishedAt": "2010-09-05T10:49:43.000Z",
        "likeCount": 0,
        "comment": "those white cunts are a disgrace to white people",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-8ok-S92V7ZY/AAAAAAAAAAI/AAAAAAAAAAA/LBXdA1otrIk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lynz B",
        "commentId": "Ugz5U-NG4Uz4yfRgfsh4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-09-01T11:38:23.000Z",
        "likeCount": 0,
        "comment": "have u guys seen in america they eat rattle snake? so its same shit different smell",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-xOPuzJwavKA/AAAAAAAAAAI/AAAAAAAAAAA/wIOlSQN0U5w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ss Cc",
        "commentId": "Ugz1OyGPkPxbK3uh2-V4AaABAg"
    },
    {
        "publishedAt": "2010-08-27T02:04:25.000Z",
        "likeCount": 0,
        "comment": "At the end, Ip Chun, Chris Chan, Shawn Rawcliffe.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-a7sqxAtZzFU/AAAAAAAAAAI/AAAAAAAAAAA/0cRiwku5EuY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "US Wing Chun Kung Fu Academy",
        "commentId": "UgzvV4rpTamGXRDXsu14AaABAg"
    },
    {
        "publishedAt": "2010-08-26T11:34:16.000Z",
        "likeCount": 0,
        "comment": "@DevizeProductions ill dig shit outta ur mams ass.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-QXNy2AjVAQc/AAAAAAAAAAI/AAAAAAAAAAA/V-rZIZQa5zM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "random broadcast",
        "commentId": "Ugx1N83KTLbMOvGpG5x4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-08-23T07:34:55.000Z",
        "likeCount": 0,
        "comment": "@popo9023 eat ur mum yum yum!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-QXNy2AjVAQc/AAAAAAAAAAI/AAAAAAAAAAA/V-rZIZQa5zM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "random broadcast",
        "commentId": "UgwBbdXnSWIVeEzIP2V4AaABAg"
    },
    {
        "publishedAt": "2010-08-23T07:34:18.000Z",
        "likeCount": 0,
        "comment": "@Westwoodboy1 you mean &quot;as delicious as it looks i wish to taste some too&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-QXNy2AjVAQc/AAAAAAAAAAI/AAAAAAAAAAA/V-rZIZQa5zM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "random broadcast",
        "commentId": "UgzPHKsho9Jl2WQ2ycF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-08-23T07:32:22.000Z",
        "likeCount": 0,
        "comment": "great! nice to see some open minded HEALTHY americans in this video. the rest of you can fuck off to burgerking!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-QXNy2AjVAQc/AAAAAAAAAAI/AAAAAAAAAAA/V-rZIZQa5zM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "random broadcast",
        "commentId": "UgyPCsAikwQvDUP-SOJ4AaABAg"
    },
    {
        "publishedAt": "2010-08-14T22:50:29.000Z",
        "likeCount": 0,
        "comment": "lmfoa :23 is the best ever",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-xjo-y_8n45s/AAAAAAAAAAI/AAAAAAAAAAA/7mIB8tgOXE4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ben Dvorak",
        "commentId": "UgxkI63HEcb48sO6Uxl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-08-14T03:47:34.000Z",
        "likeCount": 0,
        "comment": "葉正 師傅 功力很強 !!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-AxJ16T49jtE/AAAAAAAAAAI/AAAAAAAAAAA/2A8cunf4Gn8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "亞歷氏劉師父",
        "commentId": "Ugz0purIGwLAbepYVEJ4AaABAg"
    },
    {
        "publishedAt": "2010-08-05T12:25:48.000Z",
        "likeCount": 0,
        "comment": "I think this is the result of the Culture revolution in the 60s by that stupid Mao. The true form would have being loss if not for Ip Man running away to Hong Kong in the 50s. ",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-mkNjhaaFs0s/AAAAAAAAAAI/AAAAAAAAAAA/maADnVK_Nl0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "icemaneu",
        "commentId": "UgyXashskUyRRq2TAmB4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-08-02T08:50:17.000Z",
        "likeCount": 0,
        "comment": "let me ask you...what is the different between dogs, pigs, chicken, or any other living animal?..people have pigs as their house pet and yet we eat pork...just because we live in so call rich country where others live in poor country...i think you would eat dogs too if you&#39;re in their shoes..",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-E0A2KNBQ96k/AAAAAAAAAAI/AAAAAAAAAAA/v7KpVwsZzlY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ryn7yen2",
        "commentId": "Ugw3VV6t0N_fcG0vfaZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-07-21T14:03:18.000Z",
        "likeCount": 0,
        "comment": "have you seen some of the shit they serve in western restaurants ? just as bad as dog ! ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-n0Cmu9x0IwM/AAAAAAAAAAI/AAAAAAAAAAA/uphM4Mx5--w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "itchcitizen2",
        "commentId": "Ugz-KuojumWqlEGmNE54AaABAg"
    },
    {
        "publishedAt": "2010-07-21T01:36:10.000Z",
        "likeCount": 0,
        "comment": "Ohh my god the guy in black looked the ring...",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-LVvq7SPWnj4/AAAAAAAAAAI/AAAAAAAAAAA/1stgxMMY_uk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cave321",
        "commentId": "UgyDyMen4FzrpQC1uXd4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-07-18T06:46:00.000Z",
        "likeCount": 0,
        "comment": "@jarren97 there are plenty of places in SouthEast China that speaks Cantonese besides Hong Kong...and incase you&#39;ve been living underneath a rock since 1997, it&#39;s been 13 years since Great Britain returned Hong Kong back to China...btw, I&#39;m Cantonese myself.\n\nBut let&#39;s not debate this and get back to the fight shall we!",
        "videoId": "ZdL5mbgYe8Q",
        "authorThumbnail": "https://yt3.ggpht.com/-Q64xYn-Hz1U/AAAAAAAAAAI/AAAAAAAAAAA/TZ2V7_7QmBI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tovopro",
        "commentId": "UgxL5MgV4lsTToQrWzh4AaABAg"
    },
    {
        "publishedAt": "2010-07-12T22:09:15.000Z",
        "likeCount": 0,
        "comment": "I knew it, the cantos... they eat everything with 4 legs except the table",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-tFl-wQzWPXU/AAAAAAAAAAI/AAAAAAAAAAA/uxuXscjlj-o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bbly096",
        "commentId": "UgwqDl4bvD1jjF9lEaF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-07-11T16:36:11.000Z",
        "likeCount": 0,
        "comment": "As cruel as it is, what does dog meat taste like??",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-oBzyJ2X6exk/AAAAAAAAAAI/AAAAAAAAAAA/iSP7XyP9A_Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Westwoodboy1",
        "commentId": "Ugw14XdUjI3jGanefvJ4AaABAg"
    },
    {
        "publishedAt": "2010-07-11T01:51:13.000Z",
        "likeCount": 0,
        "comment": "that last crash looked fun",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Fn2PUkoXIWQ/AAAAAAAAAAI/AAAAAAAAAAA/gGT8oLL0hlM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pdawg66",
        "commentId": "UgxenhmMvv9U8NZS9RF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-07-09T17:03:06.000Z",
        "likeCount": 0,
        "comment": "how do you fight someone with a black hole for a head?!",
        "videoId": "5XxtKwf_PdE",
        "authorThumbnail": "https://yt3.ggpht.com/-XlHamgLqY-Y/AAAAAAAAAAI/AAAAAAAAAAA/w7oZD9jmgk0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "heckald",
        "commentId": "Ugz38TQJdNdx30aNv8Z4AaABAg"
    },
    {
        "publishedAt": "2010-07-06T20:47:21.000Z",
        "likeCount": 0,
        "comment": "i really like the forward energy on the middle guy.",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-ANzmw-1RqFM/AAAAAAAAAAI/AAAAAAAAAAA/iQF0ZQA2J60/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mike vernon",
        "commentId": "Ugx0OQh7V6flNKCokZJ4AaABAg"
    },
    {
        "publishedAt": "2010-07-06T16:35:30.000Z",
        "likeCount": 0,
        "comment": "@snakeincloud i agree the guy on the left has knowledge.",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-ANzmw-1RqFM/AAAAAAAAAAI/AAAAAAAAAAA/iQF0ZQA2J60/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mike vernon",
        "commentId": "UgwYhtcqk_Ls_1GXkLN4AaABAg"
    },
    {
        "publishedAt": "2010-07-04T02:42:44.000Z",
        "likeCount": 0,
        "comment": "he def. knows what he&#39;s doing. you can tell by the flow of his set.",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-D7a5OMHZ-ps/AAAAAAAAAAI/AAAAAAAAAAA/1LBTr4ui1hI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "WCAM",
        "commentId": "UgxXRpywNChCQJ4N8494AaABAg"
    },
    {
        "publishedAt": "2010-06-30T00:15:26.000Z",
        "likeCount": 0,
        "comment": "hahaha i like that baby crying thrown in there...\nword",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-3FrM_QEcD2s/AAAAAAAAAAI/AAAAAAAAAAA/fOl4bfWlW9w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kelso",
        "commentId": "UgzM7gjEeY7l7dwfmLh4AaABAg"
    },
    {
        "publishedAt": "2010-06-22T15:36:45.000Z",
        "likeCount": 0,
        "comment": "wow, i&#39;m glad my Sifu is my Sifu...\nthese vary much from Yip Man&#39;s version and i see a lot of details lacking.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-p-9Yr69MhYw/AAAAAAAAAAI/AAAAAAAAAAA/dsP0j_KknDk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "superhumansight",
        "commentId": "UgywQoM-zT9xgFWfQg14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-06-14T17:17:07.000Z",
        "likeCount": 0,
        "comment": "Not only Chinese eat dog, Koreans also and Vietnamese! American eat rabbit and that is a pet like a dog or cat! Crazy Western double standards! Americans always point the finger of blame at other nations and never look at themselves! Westerners should look at their own cruel traditions like fox hunting in England! Don&#39;t keep pointing a BLOODY finger at Chinese and talking about blood on Chinese hands! ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Lt55VfbRNfU/AAAAAAAAAAI/AAAAAAAAAAA/1USEXFw20Uw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dragonzrkool88",
        "commentId": "Ugy7oRlArJki9lev_SB4AaABAg"
    },
    {
        "publishedAt": "2010-06-12T00:28:57.000Z",
        "likeCount": 0,
        "comment": "Yes.....We eat a lot of dogs. And cats,too. Especially on your &quot;Thanks giving&quot; day. Besides, turkeys are our favorite pets. We regard turkeys as human&#39;s best friends. In China only the most uneducated eat turkeys....",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-bdrkO4XOSbE/AAAAAAAAAAI/AAAAAAAAAAA/qq_TvNRC7Wk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "CHballer1",
        "commentId": "UgzzEFD2GAH4RYQdnYp4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-06-07T12:18:06.000Z",
        "likeCount": 0,
        "comment": "When can we start eating humans? ",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-wCCRzLMZbDw/AAAAAAAAAAI/AAAAAAAAAAA/dfVdYlitv4Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xTheZomBieQueenx",
        "commentId": "UgxthG8c8ESVgNyapGV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-06-07T02:47:15.000Z",
        "likeCount": 0,
        "comment": " 1:02 brake dancing master!\n",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BNM4LZ_5Y_E/AAAAAAAAAAI/AAAAAAAAAAA/_xtfabDbb7s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mayowa Dawodu",
        "commentId": "Ugy56dIZyaRicF7UdLV4AaABAg"
    },
    {
        "publishedAt": "2010-06-05T17:13:00.000Z",
        "likeCount": 0,
        "comment": "GodDAMN.  He makes it looks so easy.  Smooth as silk.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-ck85Av-hoLc/AAAAAAAAAAI/AAAAAAAAAAA/gz7y2m66zUo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "skraddypoo",
        "commentId": "UgwN9-275NGIh4lct0p4AaABAg"
    },
    {
        "publishedAt": "2010-06-03T12:31:33.000Z",
        "likeCount": 0,
        "comment": "useless stationary shit, gives Wing Chun a bad name.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-TrGEESQ8uwc/AAAAAAAAAAI/AAAAAAAAAAA/lKhdJmTCvPg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bhibatsu",
        "commentId": "UgyPuo0m0kuwZJrzQIZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-05-28T18:03:28.000Z",
        "likeCount": 0,
        "comment": "YA MON ME LEARNIN DEM WING CHUN, ME GON BE BATTY MON. CUYAAH! at 2:30",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-45lcGhb0Vkw/AAAAAAAAAAI/AAAAAAAAAAA/XzyVFEJSAbE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "aznprc4lyfe",
        "commentId": "UgwUT8iCVQKxSbA3sT54AaABAg"
    },
    {
        "publishedAt": "2010-05-26T14:01:04.000Z",
        "likeCount": 0,
        "comment": "so it is a dog eat dog  world.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-MfRFDAjNu_8/AAAAAAAAAAI/AAAAAAAAAAA/eFue6boxcc8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "antero pineda",
        "commentId": "UgzztEYajnLCIfSOcyt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-05-25T02:00:05.000Z",
        "likeCount": 0,
        "comment": "Most people that look at this video will go. DISGUSTING EVIL VILE PERSON!!!11!!!!!1!! Like a retard. I hope they realize that dog has been a type of food for several countries. Past as well, considering the Aztec ate them, so why not more. So yeah, PETA folks that think this is animal abuse. Please take a good look at yourself and... FUCK YOUR OWN FACE!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-G21jgLGXU5k/AAAAAAAAAAI/AAAAAAAAAAA/jEC1fht3in0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "feichen1997",
        "commentId": "Ugxk-i-RagpZVe6lb6p4AaABAg"
    },
    {
        "publishedAt": "2010-05-15T12:56:44.000Z",
        "likeCount": 0,
        "comment": "unless i&#39;m mistaken, the guy talking to Ip Ching at the end of the vid is GM Chris Chan. (I can&#39;t remember his Chinese name)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-L-nuMLbbwIg/AAAAAAAAAAI/AAAAAAAAAAA/opr7x8ELgN8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mike lindemann",
        "commentId": "UgwS_yeCUl6kEACS36h4AaABAg"
    },
    {
        "publishedAt": "2010-05-06T10:27:35.000Z",
        "likeCount": 0,
        "comment": "oh so this is how they craft these things, interesting",
        "videoId": "17iBWOBIakg",
        "authorThumbnail": "https://yt3.ggpht.com/-e7iMmM6EarA/AAAAAAAAAAI/AAAAAAAAAAA/WtGNEk3Gmb0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "poon",
        "commentId": "UgzQ-5IvvK3T5D7vBbZ4AaABAg"
    },
    {
        "publishedAt": "2010-05-06T09:50:34.000Z",
        "likeCount": 0,
        "comment": "@Blackhawkflygirl Actually,if u are in the food industry...u know that&#39;s how they prepared food all around the world..like chicken suffer the same,workers throw them into boiling water,remove the feathers with hand....just cow and pig arent small enough to do so...u can check out how KFC making ...I sure u will quit eating as well...so....go VEGAN BITCH",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NFiT0ZbscM0/AAAAAAAAAAI/AAAAAAAAAAA/HmIEwnfjEaQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "dimitrilevampire",
        "commentId": "UgzXXdKU9yVyJ9AxYHJ4AaABAg"
    },
    {
        "publishedAt": "2010-05-06T05:16:19.000Z",
        "likeCount": 0,
        "comment": "@himalayanspirit you might have found the answer to this, but Wing Chun is naturally an internal art fused with the external form.  In a way, this is defined as internal in that you use the least amount of energy to perform the most effective attack.  It&#39;s easiest to understand when comparing Wing Chun to Choi Lee Fut.  Choi Lee Fut has a lot of external power, but little internal aspect - you&#39;re more likely to run out of energy.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qxDo9X3mo18/AAAAAAAAAAI/AAAAAAAAAAA/xq9hHjzyIEQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "fdesa12",
        "commentId": "Ugxmw9YMPzVcxzfhqwB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-04-29T21:21:55.000Z",
        "likeCount": 0,
        "comment": "i would like to see you watch when they kill the dogs and eat it afterwards.... YOU DONT EVEN WANT TO KNOW HOW THEY KILL THEM! Believe me, cows do not suffer a death like the dogs do...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-RxM75dsrvqo/AAAAAAAAAAI/AAAAAAAAAAA/Tb6fetzA8sk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Emma Kemppainen",
        "commentId": "Ugz3_y8YnyAl1ec9Oot4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-04-27T22:51:08.000Z",
        "likeCount": 0,
        "comment": "wtf!!! i hope you burn in hell for this\ndogs are pets not food!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ojEwupUyGL4/AAAAAAAAAAI/AAAAAAAAAAA/YncCozOaaEg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Geovanni Diamond",
        "commentId": "Ugzc-8KFOK_XfHuBvXV4AaABAg"
    },
    {
        "publishedAt": "2010-04-25T01:18:37.000Z",
        "likeCount": 0,
        "comment": "fuk fuk fuk ppl who eat doggy",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-jdGUJ5TpvFE/AAAAAAAAAAI/AAAAAAAAAAA/YauZd7Tjibw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ezatt1995",
        "commentId": "UgyHrd59djy4ReCQ9sZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-04-20T11:59:18.000Z",
        "likeCount": 0,
        "comment": "@benter1 This is my video, I shot it and your comment cracked me up. THAT IS EXACTLY WHAT THE MENU SAID. So funny. We also did not choose it, our local host  insisted. We also eat snakes, bugs, cow guts, brains and more. All great!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugx5rMIbSpicRg0m_KZ4AaABAg"
    },
    {
        "publishedAt": "2010-04-15T22:16:37.000Z",
        "likeCount": 0,
        "comment": "@stickpiethere They actually weren&#39;t. That was an editing trick. I had to cover up a camera move so I inserted a cutaway shot. Everyone was stunned watching the demo. He was just stopping by and wasn&#39;t supposed to perform. If I remember correctly, the demo subjects  weren&#39;t even his students.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwgRyeaN6h_bOEj7Z14AaABAg"
    },
    {
        "publishedAt": "2010-04-15T08:13:42.000Z",
        "likeCount": 0,
        "comment": "@lopoex ng concept nito ay i matay ng opponent mo before mag gawa siya ng move",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-9DX2pNRU_oo/AAAAAAAAAAI/AAAAAAAAAAA/WHiLT0j6zlQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bowow0807",
        "commentId": "Ugxo9blkCL0tQZ9myvh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-04-11T01:11:34.000Z",
        "likeCount": 0,
        "comment": "Fuck u smell like dog!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Hi9-4PNsFR8/AAAAAAAAAAI/AAAAAAAAAAA/lOLcKtGt_vk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "thar4u",
        "commentId": "UgxtnKSdJdXq50AX4-d4AaABAg"
    },
    {
        "publishedAt": "2010-04-10T07:06:58.000Z",
        "likeCount": 0,
        "comment": "Remember I was here.Thai",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-1C7Z6OAfPRg/AAAAAAAAAAI/AAAAAAAAAAA/ol7EKzxerTA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "universetechnique",
        "commentId": "Ugwews4T_gi7bxb2y3h4AaABAg"
    },
    {
        "publishedAt": "2010-04-09T21:46:02.000Z",
        "likeCount": 0,
        "comment": "After eating dog meat, you will fuck all night. Eat it then you will believe it .",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Gzdw6HNMPK4/AAAAAAAAAAI/AAAAAAAAAAA/1usqd6D8RYA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "hoac nguyen",
        "commentId": "UgzgOt-oXQA_DEhOzHl4AaABAg"
    },
    {
        "publishedAt": "2010-03-26T01:43:25.000Z",
        "likeCount": 0,
        "comment": "@mynameisvallu Nintendo 64 Army Men Sarge&#39;s Heroes circa 1998.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwZsA6eOG_Fpe-JqtV4AaABAg"
    },
    {
        "publishedAt": "2010-03-25T23:57:41.000Z",
        "likeCount": 0,
        "comment": "the turn and use of the elbow as a strike invites a proper VT response when both hands are used to simply turn the elbow user with no available followup. Turning the body and using the elbow is more of a Hung Gar technique and violates the two hands useful straight on the centerline VT principle.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-mFKI8STRaJQ/AAAAAAAAAAI/AAAAAAAAAAA/Zg9Tz3pluYs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tom Chi",
        "commentId": "UgzykGWSfsTaZGFufMt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-03-18T14:43:04.000Z",
        "likeCount": 0,
        "comment": "Fuck all this china pigs!!! I hope more earthquakes happened in china to kill all this inhuman pigs.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-tgdCIKoP_3E/AAAAAAAAAAI/AAAAAAAAAAA/hcSXCa8Hzic/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David Baker",
        "commentId": "UgznGNo-jJqxIzvD6-14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-03-17T19:17:42.000Z",
        "likeCount": 0,
        "comment": "0:35 looks amazingly fun!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-2jHcPQaOLJQ/AAAAAAAAAAI/AAAAAAAAAAA/27LAVX4e45Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "spunkeh100",
        "commentId": "UgzARATp65OBtJ-JshF4AaABAg"
    },
    {
        "publishedAt": "2010-03-13T16:58:49.000Z",
        "likeCount": 0,
        "comment": "i love the sound effects",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-_xp6T2acLYI/AAAAAAAAAAI/AAAAAAAAAAA/e0Vzy9wvLxs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Anthony Lombardi",
        "commentId": "UgwWNF7bYKUpSairY1V4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-03-07T18:03:32.000Z",
        "likeCount": 0,
        "comment": "or you can kiss them!!!!!!!!!!!\nfave martial art ever.\nI want to go to the phillipines to learn that.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-Drkef2yDyVU/AAAAAAAAAAI/AAAAAAAAAAA/7CklrBN-a_4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Cillik1",
        "commentId": "UgxTDNNGRAFV8LT7KoF4AaABAg"
    },
    {
        "publishedAt": "2010-03-04T19:28:18.000Z",
        "likeCount": 0,
        "comment": "Sound effects FTW",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-a2evi-XhRDU/AAAAAAAAAAI/AAAAAAAAAAA/ZvdZa4JI6IQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Get Clucky",
        "commentId": "Ugy2QNdomxRJreoPmVF4AaABAg"
    },
    {
        "publishedAt": "2010-03-01T12:41:19.000Z",
        "likeCount": 0,
        "comment": "watch rumble in the bronx \njackie chan uses there wing chun on the wooden dummy and against the gangsters there",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-Vuvax2S-7_Y/AAAAAAAAAAI/AAAAAAAAAAA/2QqnBHXYNmQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "wingchun91",
        "commentId": "UgxRw0Tfqsbc0m4lGVh4AaABAg"
    },
    {
        "publishedAt": "2010-03-01T12:40:04.000Z",
        "likeCount": 0,
        "comment": "you are right but jackie chan also uses wing chun in a movie watch the movie rumble in the brons there he just uses wing chun also he demonstrates wing chun on the wooden dummy",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-Vuvax2S-7_Y/AAAAAAAAAAI/AAAAAAAAAAA/2QqnBHXYNmQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "wingchun91",
        "commentId": "UgzTdO_hE4hD7FEkpnx4AaABAg"
    },
    {
        "publishedAt": "2010-02-28T23:07:49.000Z",
        "likeCount": 0,
        "comment": "I was cracking up watching this because he was getting more and more excited as he was demo&#39;ing and he started moving faster and faster and talking faster and faster.\n\nGreat demo.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-aRjqQBc8Ni0/AAAAAAAAAAI/AAAAAAAAAAA/xaoOlWE93gE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "KillerSalmon",
        "commentId": "Ugxy8nsRgCzQOrFkPUR4AaABAg"
    },
    {
        "publishedAt": "2010-02-28T15:56:52.000Z",
        "likeCount": 1,
        "comment": "I thought this was great except the rude people eating whil GM Presas was teaching. It&#39;s like they were wasting their time.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-JeaALMetzDo/AAAAAAAAAAI/AAAAAAAAAAA/R9xu3Wj7dxU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Francis Serrano",
        "commentId": "UgzR7PIhZ53dt5P3QjR4AaABAg"
    },
    {
        "publishedAt": "2010-02-25T11:47:04.000Z",
        "likeCount": 0,
        "comment": "It`s Foshan / Fatshan &quot; Yiu Choi &quot; Line . Better late than never .",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgxUuWrzfAltPAochMp4AaABAg"
    },
    {
        "publishedAt": "2010-02-24T12:47:21.000Z",
        "likeCount": 0,
        "comment": "ow 0:46 down the stairs ouch haha",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-g-Ypr8sHiKg/AAAAAAAAAAI/AAAAAAAAAAA/ydJuKD97kDY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "7e8t9",
        "commentId": "Ugx9dLAZ2NHqFbHXVHV4AaABAg"
    },
    {
        "publishedAt": "2010-02-23T23:38:12.000Z",
        "likeCount": 0,
        "comment": "hey uncle chen go add your own sauce you laugh like a serial animal killer..",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ojL5C3enqCQ/AAAAAAAAAAI/AAAAAAAAAAA/AxiA3mdEGjU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "buzzwolf",
        "commentId": "Ugy3XGZ501YI8O-HT9t4AaABAg"
    },
    {
        "publishedAt": "2010-02-19T17:18:17.000Z",
        "likeCount": 0,
        "comment": "@Lee94503 I don&#39;t think so, so much would have to be watered down that it wouldn&#39;t look like the real thing.  I mean I&#39;ve seen real fencing and the Olympic stuff makes a game of TAG look aggressive.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-RCpJchuVcJY/AAAAAAAAAAI/AAAAAAAAAAA/h9WIH04dMfI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "victorrain",
        "commentId": "UgwTv_B_dYHNgeoOsgh4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-02-19T11:58:40.000Z",
        "likeCount": 0,
        "comment": "where did u get the soundtrack? plz reply!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-flaKWUX_VsA/AAAAAAAAAAI/AAAAAAAAAAA/IfFgqD8S_P0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mynameisvallu",
        "commentId": "Ugxd19ofV0Sm6Q_f0bx4AaABAg"
    },
    {
        "publishedAt": "2010-02-16T23:52:10.000Z",
        "likeCount": 0,
        "comment": "epic faliure.... for the snowboarders lol",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Za21SkzS7Ic/AAAAAAAAAAI/AAAAAAAAAAA/x6mpwGXr_Is/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Joshua Fridinger",
        "commentId": "Ugx8G7D8HXlcRNe54s14AaABAg"
    },
    {
        "publishedAt": "2010-02-14T22:26:01.000Z",
        "likeCount": 0,
        "comment": "hahaha",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-q02RVaeNQ2o/AAAAAAAAAAI/AAAAAAAAAAA/UzN_c5h7oJY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Erik Magnusson",
        "commentId": "Ugz6IBHK89FBZEOTjHR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-02-13T03:58:57.000Z",
        "likeCount": 0,
        "comment": "It was a common neck grab. No harm done . They do all the time . It`s just one way to show that he has gotten thru , and the custom is , to pause and acknowledge the Score . Great stuff , actually !",
        "videoId": "5XxtKwf_PdE",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugw4mM_xAU7ATQAxu3l4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-02-11T01:04:54.000Z",
        "likeCount": 0,
        "comment": "They have just all had to cultivate a culture of secrecy for a long ,long time- be it under the Ching Dynasty , then the Japanese , then Mao. then the Cultural Revolution , etc.,etc.. Just don`t expect to roll into Foshan , all wide-eyed , and sincere , and then expect to find them shovelling it out on every street corner for Free - LOL !",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgwWQRIGGjOTZQe0Q1J4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-02-11T00:57:27.000Z",
        "likeCount": 0,
        "comment": "Great Stuff ! I`d like to see much more of this Foshan Wing Chun .  The last Sifu was Sifu Mak . His Kung Fu is fantastic ! Apparently , this Demo had Wing Chun men from all over S. China . God only knows what secrets they still maintain . You can be sure that they have different Jong Kuen/4th Forms , Bamboo Dummy Sets , Double Butterfly Knife Forms , Essential Supplementary Sets that they have handed down , or that they have developed , and all sorts of cool secret stuff . -Con`t....",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgzeMcY4DupRS2Sxu3h4AaABAg"
    },
    {
        "publishedAt": "2010-02-09T19:19:56.000Z",
        "likeCount": 0,
        "comment": "@lacki1991 hahahaahhahah",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-QtFdqYcKID0/AAAAAAAAAAI/AAAAAAAAAAA/ViJ8e9bPfm4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Killermatt",
        "commentId": "UgwhZHbyyI3iYM9wZwF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-02-04T20:11:19.000Z",
        "likeCount": 0,
        "comment": "I love this video. Good quality! Nice sound effects and it is good timed to! Sweet! I also enjoyd it because of all the jurks falling as fuck! hahahahaha. oh! look there! hahahahah.!\n\nNice Work dude!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-AQs-gPAFLHg/AAAAAAAAAAI/AAAAAAAAAAA/QYT49aOIGUw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "William",
        "commentId": "UgzlhwCFNy34PaYBALJ4AaABAg"
    },
    {
        "publishedAt": "2010-02-01T04:45:43.000Z",
        "likeCount": 0,
        "comment": "rofl awesome sounds",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-GFfHHaMQCgg/AAAAAAAAAAI/AAAAAAAAAAA/5-OGW1kRkc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mideviltimes",
        "commentId": "UgyArywPM9ssE7hZZdh4AaABAg"
    },
    {
        "publishedAt": "2010-01-28T01:28:49.000Z",
        "likeCount": 0,
        "comment": "the 2nd one and the last one are best",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-wxxxKZZZ00I/AAAAAAAAAAI/AAAAAAAAAAA/vmEvBTRWlvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "piecehomie8",
        "commentId": "UgxwFmBq5d9QeTqzj6F4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-01-27T11:14:44.000Z",
        "likeCount": 0,
        "comment": "lol!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-zmO_JNkodaA/AAAAAAAAAAI/AAAAAAAAAAA/Fnxk_ktlrFI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "woksin",
        "commentId": "UgwUdusL6lAZRm76zER4AaABAg"
    },
    {
        "publishedAt": "2010-01-26T12:15:17.000Z",
        "likeCount": 0,
        "comment": "lol",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/--uqn9reiIHw/AAAAAAAAAAI/AAAAAAAAAAA/ZZtaMfBtCzc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SEVA26",
        "commentId": "UgxguDH7b4u0w7apjXp4AaABAg"
    },
    {
        "publishedAt": "2010-01-23T20:55:28.000Z",
        "likeCount": 0,
        "comment": "dog is delicious",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-FMbeqV4-G7o/AAAAAAAAAAI/AAAAAAAAAAA/Giwk-YH-Oc8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ancalites",
        "commentId": "UgyYU1WnHtRkDCjsnLF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2010-01-18T04:33:12.000Z",
        "likeCount": 0,
        "comment": "lol, but i do feel sorry for those people. i like the one at 0:17!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-VkYzZWgtmMQ/AAAAAAAAAAI/AAAAAAAAAAA/5KJRHh3l6Pk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "MultipleNaruto",
        "commentId": "UgyheAyfof3tqkPHw8Z4AaABAg"
    },
    {
        "publishedAt": "2010-01-16T13:25:56.000Z",
        "likeCount": 0,
        "comment": "u got to be shitting me i cant belive it they eating dogs",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-8XXMJK0hjAI/AAAAAAAAAAI/AAAAAAAAAAA/qGZ3B-V-Vx0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "krish lingam",
        "commentId": "Ugx6_2LZ8BaVoNPxZwl4AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:59:34.000Z",
        "likeCount": 0,
        "comment": "not chi sau",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "UgwzG6PpEi3Y9VkJ--B4AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:59:28.000Z",
        "likeCount": 0,
        "comment": "rough fighting",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "Ugx09aD9ovYbczRJul94AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:46:48.000Z",
        "likeCount": 0,
        "comment": "go to mainland china and you will see all the crouching tigers and hidden dragons of chinese martial arts. the world of martial arts is big my friend and you are but a drop in the ocean",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "UgxwpbjrzU3QAMK11a94AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:38:28.000Z",
        "likeCount": 0,
        "comment": "@egglot12 that was wooden man form",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "Ugyi1SQC9h_Q4Dw50NF4AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:37:51.000Z",
        "likeCount": 0,
        "comment": "@himalayanspirit in wing chun, there is no chi training,",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "UgwzctGCWvmpa6Mto-x4AaABAg"
    },
    {
        "publishedAt": "2010-01-13T02:37:18.000Z",
        "likeCount": 0,
        "comment": "@chunks321 thats because its guang dong",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-4N1RWAheias/AAAAAAAAAAI/AAAAAAAAAAA/bP57Y87ftS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rekcufeht",
        "commentId": "UgxHPZgiaXmBFFbLGI94AaABAg"
    },
    {
        "publishedAt": "2010-01-10T15:38:25.000Z",
        "likeCount": 0,
        "comment": "sure ouch",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/--3joy9ksc1w/AAAAAAAAAAI/AAAAAAAAAAA/l9QekjvAlOY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Robert Bergman",
        "commentId": "Ugx_zP2EjNS_8YGxBwV4AaABAg"
    },
    {
        "publishedAt": "2010-01-10T10:21:19.000Z",
        "likeCount": 0,
        "comment": "I see what you are saying .... but Brandon Lee was 8 years old when his father died.... Ip Ching and Ip Chun trained over 10 years with there father... Do you see where I am coming from now... Most Sifi&#39;s including I would imagine would put more effort into thier siblings training...Also lets not forget they are 50% Ip Man... :-)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-FXrbnRYUcO0/AAAAAAAAAAI/AAAAAAAAAAA/boVybJTXCK4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Oakley",
        "commentId": "Ugwz9nuUf0hYAxpNKyx4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-01-10T10:13:00.000Z",
        "likeCount": 0,
        "comment": "Sorry start is Dan Chi then Chi Sao ... on my last comment. this is more than was on the last clip? Great Footage! Sifu Rawcliff .... one of my UK favs there too!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-FXrbnRYUcO0/AAAAAAAAAAI/AAAAAAAAAAA/boVybJTXCK4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Oakley",
        "commentId": "UgwzzpMmFCirGlZ9CJ94AaABAg"
    },
    {
        "publishedAt": "2010-01-04T02:42:33.000Z",
        "likeCount": 0,
        "comment": "I like the last one : )",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-tX2VsTla3J0/AAAAAAAAAAI/AAAAAAAAAAA/ycxXSXv5Emk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Will Betts",
        "commentId": "Ugx1nmd2fRP0nfTrkB54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2010-01-03T18:26:54.000Z",
        "likeCount": 0,
        "comment": "yeah...??? but you only think its gross cuz americans keeps dogs as pets, but you have to think about it, we eat animals too, some even say that us americans eats cows,",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-0pYbOwoOybI/AAAAAAAAAAI/AAAAAAAAAAA/eREQzf6wMrI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "shaochia vang",
        "commentId": "Ugx3IbrDMzDNLtFJOvp4AaABAg"
    },
    {
        "publishedAt": "2010-01-02T07:43:14.000Z",
        "likeCount": 0,
        "comment": "@mideast22 They eats humans too...I heard they eat human kids, in some parts of china.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-w5GGb1QdG5Q/AAAAAAAAAAI/AAAAAAAAAAA/lppJDbr97CU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Desucof K",
        "commentId": "UgzkjJpu0_XYk6FIs5x4AaABAg"
    },
    {
        "publishedAt": "2009-12-30T21:53:04.000Z",
        "likeCount": 0,
        "comment": "This is dusgusting",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TJFDByF53ww/AAAAAAAAAAI/AAAAAAAAAAA/zWh1mAKKTLM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pam869shut834",
        "commentId": "UgyQY3h8Zh9Vf4HcLDd4AaABAg"
    },
    {
        "publishedAt": "2009-12-30T13:00:55.000Z",
        "likeCount": 0,
        "comment": "wow, even a ballpen can be a dangerous weapon",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-U-_qmJwWkPU/AAAAAAAAAAI/AAAAAAAAAAA/VXwxlSxcaA0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "sgtjoe2008",
        "commentId": "Ugz5lwBOHsRDHvf1InZ4AaABAg"
    },
    {
        "publishedAt": "2009-12-27T21:56:30.000Z",
        "likeCount": 0,
        "comment": "Nintendo 64 Sarge&#39;s Heros.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxmdGUFL7nVvEHGR2Z4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-12-25T00:08:39.000Z",
        "likeCount": 0,
        "comment": "I think you miss the point of Grand Master  ... It&#39;s not Ip Ching&#39;s ability to fight I seek it&#39;s his knowledge of the system he teaches.... He&#39;s nearly 80 I for one dont expect him to be a Shaolin Warrior? I respect him greatly as he doesnt call himself Grand Master he calls himselve Sifu .... Please respect the Elders of our System out of repect of the art of Wing Chun.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-FXrbnRYUcO0/AAAAAAAAAAI/AAAAAAAAAAA/boVybJTXCK4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Oakley",
        "commentId": "UgzDpe1yZo3i937SyUB4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-12-24T23:29:38.000Z",
        "likeCount": 0,
        "comment": "I think that escrima should be an olympic sport! what do you think?",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-yS5JH7HB548/AAAAAAAAAAI/AAAAAAAAAAA/XO3bQ2lJHgQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lee94503",
        "commentId": "UgyGIGmZTGUlU2ZaAzF4AaABAg"
    },
    {
        "publishedAt": "2009-12-13T04:45:00.000Z",
        "likeCount": 0,
        "comment": "chinese eat everything, american do anything",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-aYgt55rTCKI/AAAAAAAAAAI/AAAAAAAAAAA/5YqPVLMNmlo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "haodi13",
        "commentId": "UgzVviXBD1x1iAsHTKZ4AaABAg"
    },
    {
        "publishedAt": "2009-12-11T01:55:45.000Z",
        "likeCount": 0,
        "comment": "i wish i never saw this. my dog saved my life.i was asleep and  my house was on fire and my dog woke me up. now i find people laffing about eating them, sorry this has just made me feel sick",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-xEGJ_5VKVCE/AAAAAAAAAAI/AAAAAAAAAAA/z0KM3QHbC0I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "davebrdshw",
        "commentId": "UgwE28ZNUWr8U64TsA54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-12-08T00:21:07.000Z",
        "likeCount": 0,
        "comment": "Are you sure he&#39;s never been in a fight in his entire life?? If so I&#39;d say its common sense for any good martial artist to avoid confrontation.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-fa4iabm0TA4/AAAAAAAAAAI/AAAAAAAAAAA/11PqIIRcCoQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Walterpeck",
        "commentId": "UgyEKucVJEHMQGnWEY14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-12-07T15:47:42.000Z",
        "likeCount": 0,
        "comment": "have you even met his sons in order to know if they have been in real combat?  I bet you haven&#39;t!!\n\nI have met them and they have been in some issues in their lives so shut up before you make stupid statements.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-HzI-DTixDog/AAAAAAAAAAI/AAAAAAAAAAA/IZNPpto3X48/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jedimodus",
        "commentId": "UgyiFlkdm0rfrev96ZF4AaABAg"
    },
    {
        "publishedAt": "2009-12-07T06:39:25.000Z",
        "likeCount": 0,
        "comment": "he was talking in English believe it or not but yo just need to get past the Filipino Accent.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-FQCCXlI4JFI/AAAAAAAAAAI/AAAAAAAAAAA/yRHR6tj3RIQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "powghee",
        "commentId": "Ugziof8yeCWNphQPq3h4AaABAg"
    },
    {
        "publishedAt": "2009-12-02T23:15:00.000Z",
        "likeCount": 0,
        "comment": "Okay here my comment worth.. Ip Ching is a great Wing Chun Sifu he studied under his father Yip Man since 1959 after coming from Mainland China to HK. He has massive respect in the WC world. This is actually Dan Chi not Chi Sao with some Bong Lap drill. Wing Chun is a technical art of fighting with defence in coordination with attack. I believe like his father Ip Ching doesn&#39;t see himself as Grand Master of Wing Chun his Students and their Students see him as the Grand Master of his Lineage.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-FXrbnRYUcO0/AAAAAAAAAAI/AAAAAAAAAAA/boVybJTXCK4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Oakley",
        "commentId": "UgyRZ3rmKICWnRvZ0ad4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-12-02T19:34:34.000Z",
        "likeCount": 0,
        "comment": "Um, I think its funny that Indians worship cows, and its really no reason for me not to eat beef. As for pigs, muslims do not eat swine because of religous beliefs as well.\r\nBeing that the CCP doesn&#39;t allow religion, we should mention that both of the foods you mentioned are eaten in China. In fact, pork is a Chinese mainstream favorite. So in essence, Im really fucking confused as to what the hell your talking about. Your defending dog and cat eating and slamming the US for eating beef &amp; pork?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VEVeyT4SM0M/AAAAAAAAAAI/AAAAAAAAAAA/sHgWZVJDwcQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Greg M",
        "commentId": "UgytaUki2oZBCwN6Q6d4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-12-02T16:32:33.000Z",
        "likeCount": 0,
        "comment": "the sad politics are that the children of a Sifu are senior to every student that Sifu has. that can often be a good formality. but even BBC and everyone who speaks of Ip Man&#39;s kids promote them like they are now the $#!+.\nthey are just the kids. \nBrandon Lee was not on Bruce Lee&#39;s level.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-p-9Yr69MhYw/AAAAAAAAAAI/AAAAAAAAAAA/dsP0j_KknDk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "superhumansight",
        "commentId": "Ugz-C_OKzYPhiDrDxCp4AaABAg"
    },
    {
        "publishedAt": "2009-12-02T06:10:42.000Z",
        "likeCount": 0,
        "comment": "Their not products dumb fuck, their bacteria that very occasionally (As in maybe one incident every few years) show up in screening (That China doesn&#39;t have). The chance in the US to be afflicted and harmed by these bacteria are so slim, its about a 1 in 1,000,000,000chance... And if you were to contract these bacterium, its about a week of flu-like symptoms, OH NO..\r\n\r\nSo I&#39;ll take my chances here, and leave it up to you guys to eat dogs and cats. And I don&#39;t give a fuck what Indians eat. Idiot",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VEVeyT4SM0M/AAAAAAAAAAI/AAAAAAAAAAA/sHgWZVJDwcQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Greg M",
        "commentId": "UgzLlQNCDbfDUBwmq1d4AaABAg"
    },
    {
        "publishedAt": "2009-12-02T02:43:44.000Z",
        "likeCount": 0,
        "comment": "Lol, most of the poisonous food in the US is the shit that comes from communist China. They don&#39;t go for &quot;The kill&quot;, they have the best, most stringet food safety standards on Earth. So I&#39;ll leave it up to the Asians to eat man&#39;s best friend. Sick shit.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VEVeyT4SM0M/AAAAAAAAAAI/AAAAAAAAAAA/sHgWZVJDwcQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Greg M",
        "commentId": "UgyjhqfreK8SYLzi4et4AaABAg"
    },
    {
        "publishedAt": "2009-11-30T04:02:12.000Z",
        "likeCount": 0,
        "comment": "Lol, you dumbass. I realize that its Americans sitting down at the table.. But they had to go to CHINA to get served dog.. Their trying to be brave and try it. We don&#39;t serve or eat dog in the US.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VEVeyT4SM0M/AAAAAAAAAAI/AAAAAAAAAAA/sHgWZVJDwcQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Greg M",
        "commentId": "Ugx3EZazuCWP13vZxSt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-11-30T03:33:24.000Z",
        "likeCount": 0,
        "comment": "IDIOT!!! take a close look at the video.. those are americans eating dogs!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5Awm9gv9A1c/AAAAAAAAAAI/AAAAAAAAAAA/VAZuryZ2YIk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "zofronio",
        "commentId": "UgwgTt2nk4GIzqdqFw14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-11-30T03:31:32.000Z",
        "likeCount": 0,
        "comment": "you must have drank a lot of horse sperm and you might be using them as lotion. HAHAHAHA!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5Awm9gv9A1c/AAAAAAAAAAI/AAAAAAAAAAA/VAZuryZ2YIk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "zofronio",
        "commentId": "UgyAZrwb5EjP5kiJpQ14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-11-30T03:30:19.000Z",
        "likeCount": 0,
        "comment": "Man&#39;s best friend and white woman&#39;s BEST LOVER!! HAHAHAHA!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5Awm9gv9A1c/AAAAAAAAAAI/AAAAAAAAAAA/VAZuryZ2YIk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "zofronio",
        "commentId": "UgxzLueojHE0ie410QZ4AaABAg"
    },
    {
        "publishedAt": "2009-11-29T07:42:32.000Z",
        "likeCount": 0,
        "comment": "Sick goddamn Asians",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-VEVeyT4SM0M/AAAAAAAAAAI/AAAAAAAAAAA/sHgWZVJDwcQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Greg M",
        "commentId": "Ugxn4G805_KF0fF_EsR4AaABAg"
    },
    {
        "publishedAt": "2009-11-23T04:26:30.000Z",
        "likeCount": 0,
        "comment": "0:48 epic fail",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-4tdwgDYnNl0/AAAAAAAAAAI/AAAAAAAAAAA/Mu1rG-ANajo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Nathan Masters",
        "commentId": "Ugz6vp5mfxsmjoeHGPt4AaABAg"
    },
    {
        "publishedAt": "2009-11-23T04:26:18.000Z",
        "likeCount": 0,
        "comment": "0:48 epic fail",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-4tdwgDYnNl0/AAAAAAAAAAI/AAAAAAAAAAA/Mu1rG-ANajo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Nathan Masters",
        "commentId": "UgxQRj1PKjBORBaVpo14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-11-22T03:34:50.000Z",
        "likeCount": 0,
        "comment": "@neoarcadezr  and what about white women? FUCKING DOGS AND HORSES then posting them in the internet. And there are even white women drinking horse sperm.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5Awm9gv9A1c/AAAAAAAAAAI/AAAAAAAAAAA/VAZuryZ2YIk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "zofronio",
        "commentId": "UgwsWt4bDWOSjNEahCZ4AaABAg"
    },
    {
        "publishedAt": "2009-11-21T22:47:19.000Z",
        "likeCount": 0,
        "comment": "At 00:48 its stair fail taken to the extreme",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-sc759mgjIwA/AAAAAAAAAAI/AAAAAAAAAAA/WdM4V1IY4rE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jdog0496",
        "commentId": "UgxyMAEj2qDOv1HcS_F4AaABAg"
    },
    {
        "publishedAt": "2009-11-20T01:25:23.000Z",
        "likeCount": 0,
        "comment": "looks tasty",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-a986TmA43BM/AAAAAAAAAAI/AAAAAAAAAAA/zBo5nbizrzo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Christopher McCuaig",
        "commentId": "Ugy3IELlbMavzZU2kUd4AaABAg"
    },
    {
        "publishedAt": "2009-11-18T02:31:01.000Z",
        "likeCount": 0,
        "comment": "Fancy sound effects haha",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-ZgtRjQqszBI/AAAAAAAAAAI/AAAAAAAAAAA/0Ip9yHRuHdw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ToxyFlog",
        "commentId": "UgxNhrtKI_tTASDs6zl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-11-09T19:13:15.000Z",
        "likeCount": 0,
        "comment": "good video!!! sound ok!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-iZA66kxL0tc/AAAAAAAAAAI/AAAAAAAAAAA/S7AJDWS-az0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "customx74",
        "commentId": "UgwzaS2z96wRT0UaqLt4AaABAg"
    },
    {
        "publishedAt": "2009-11-06T01:19:30.000Z",
        "likeCount": 0,
        "comment": "this guy is a BOSS. Period.\r\n\r\n5/5 escrima demo",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-TtuT0RUXtT8/AAAAAAAAAAI/AAAAAAAAAAA/gbizBDQ-pzM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Monte H",
        "commentId": "UgzTN37RgDE5vGQ8_JV4AaABAg"
    },
    {
        "publishedAt": "2009-11-04T16:53:22.000Z",
        "likeCount": 0,
        "comment": "good sound effects:)",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-uhJZ4J054Hk/AAAAAAAAAAI/AAAAAAAAAAA/mM60m-9bLAc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "JANNE ES",
        "commentId": "Ugz3eQY3zC4ajQBGoEt4AaABAg"
    },
    {
        "publishedAt": "2009-11-01T03:24:12.000Z",
        "likeCount": 0,
        "comment": "this is some good stuff and it looks like these guys know how to apply their chi sao.  honestly, its way better then the softie chi sao where they just tap the chest.  In this version, it seems like chi sao with REAL presure applied.  In a fight ppl will chi sao you if you initiate it, its natural, and what we just saw looks like real stuff.  cheers",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-rSV3NSeR3rg/AAAAAAAAAAI/AAAAAAAAAAA/4mfJuor0Zq0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ssj7gokurpg",
        "commentId": "Ugy6mqQtUeXTqs8Qh6B4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-10-29T03:23:42.000Z",
        "likeCount": 0,
        "comment": "VERY   SHIT!!!  COME  IN  ITALY    FOR  EAT\n!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-0qQLVQ8Ban8/AAAAAAAAAAI/AAAAAAAAAAA/K1Z0ynYfBkI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "angelo rossi",
        "commentId": "UgxDwYXjePtGyvC0-VN4AaABAg"
    },
    {
        "publishedAt": "2009-10-23T00:20:04.000Z",
        "likeCount": 0,
        "comment": "Exactly my thought.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-OOpTeaZ5f-w/AAAAAAAAAAI/AAAAAAAAAAA/vBtzqhyPHGY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SinnerJ",
        "commentId": "UgzKmtZrCfTUld8sIc54AaABAg"
    },
    {
        "publishedAt": "2009-10-18T22:05:46.000Z",
        "likeCount": 0,
        "comment": "What&#39;s funny about Wing Chun now, is that after Yip Man&#39;s death, anyone can be a grandmaster. Even his son, who&#39;s never been in a fight in his entire life.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-hemOIabaggY/AAAAAAAAAAI/AAAAAAAAAAA/wIpAbclbxJU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "theScytheofGod",
        "commentId": "UgzYKPF1AycTBbhpX4R4AaABAg"
    },
    {
        "publishedAt": "2009-10-18T22:03:39.000Z",
        "likeCount": 0,
        "comment": "It&#39;s just a myth, man, propragated by the Ching rebels. No woman ever created a martial art. (not that they couldn&#39;t)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-hemOIabaggY/AAAAAAAAAAI/AAAAAAAAAAA/wIpAbclbxJU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "theScytheofGod",
        "commentId": "Ugw-0KpdHXhTZZOeAh14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-10-07T23:13:51.000Z",
        "likeCount": 0,
        "comment": "i have a question-\r\nis wing tsun realy kung fu? like jackie chan is using ?\r\ni need a answer because i found a wing tsun school but i dont want to lean somthing wrong.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-EKCPnzw9cjk/AAAAAAAAAAI/AAAAAAAAAAA/c8yvcT86lko/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "serienfan1",
        "commentId": "UgxpTFgfrJBN3rqk9th4AaABAg"
    },
    {
        "publishedAt": "2009-10-07T16:43:13.000Z",
        "likeCount": 0,
        "comment": "Thats why i wear a helm xD",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-C7g7ofSjJHU/AAAAAAAAAAI/AAAAAAAAAAA/_MT_gkTtUTs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "sneeuwwolven",
        "commentId": "UgwgjlUyoFBjE-CAszJ4AaABAg"
    },
    {
        "publishedAt": "2009-10-06T13:29:17.000Z",
        "likeCount": 0,
        "comment": "eat dog \n\n= \n\neat a excrement of dog",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-6cfODP8WKh8/AAAAAAAAAAI/AAAAAAAAAAA/6ASDi6rduAw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "amliketocomment",
        "commentId": "UgyrgBMdkwZV1U6QKCt4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-10-05T03:52:40.000Z",
        "likeCount": 0,
        "comment": "This white guy is a damn lucky man. I wish I can taste some dog!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-FR0pXuE_l8M/AAAAAAAAAAI/AAAAAAAAAAA/iyl0VaZk9cM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ppshchik",
        "commentId": "UgwoU1gjFGJe5qLOGDx4AaABAg"
    },
    {
        "publishedAt": "2009-09-30T16:37:16.000Z",
        "likeCount": 0,
        "comment": "Animals are there to be EATEN. Its eat or be eaten in this world.\n\nMan stands at the top of the foodchain. Thus man eats other animals. Without animals as food, there wouldnt be enough nutrition, protein, even food for everyone in the world.\n\nIf you cant stand other people enjoying the pleasure of meat, than go live in a cave. Humans are omnivores. We eat everything. \n\nAlways has and always will. Go live in a cave and pick berries. Not me.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-JUvY2F11PV8/AAAAAAAAAAI/AAAAAAAAAAA/5GXA5JC15rA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Elbottoo",
        "commentId": "Ugw34wecnQvfz61Tmwl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-09-26T06:41:50.000Z",
        "likeCount": 0,
        "comment": "in india, dont they say that we eat cows?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-0pYbOwoOybI/AAAAAAAAAAI/AAAAAAAAAAA/eREQzf6wMrI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "shaochia vang",
        "commentId": "UgzjMjaOBF-4iSWRZ_J4AaABAg"
    },
    {
        "publishedAt": "2009-09-25T08:43:33.000Z",
        "likeCount": 0,
        "comment": "Dam that had to hurt",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-CRFvXgr8ovE/AAAAAAAAAAI/AAAAAAAAAAA/CB5nFSLeYj4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Patrick Hanisee",
        "commentId": "Ugx6_7eArshWjLhlzWp4AaABAg"
    },
    {
        "publishedAt": "2009-09-22T09:43:29.000Z",
        "likeCount": 0,
        "comment": "Nonsense. You&#39;d be generalized negatively regardless. The reason eating dogs is so touchy is that a lot of these people are actually having sex with their dogs. You can&#39;t expect a rational outlook on this, when for them, these are their girlfriend or boyfriend... Now THAT&#39;S sick...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-SLZkUxrkTHw/AAAAAAAAAAI/AAAAAAAAAAA/QeHhbwQ6lq4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "LO so",
        "commentId": "UgwyPipQyPFVDKJLtBR4AaABAg"
    },
    {
        "publishedAt": "2009-09-21T23:36:54.000Z",
        "likeCount": 0,
        "comment": "I love how enthusiastic, energetic, and almost pissed off he sounds. Its like visiting my family.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-JhIzAjIYxTY/AAAAAAAAAAI/AAAAAAAAAAA/c-Zc_ZsH1Io/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "hourof10",
        "commentId": "UgwEfJaBSTXLormngdR4AaABAg"
    },
    {
        "publishedAt": "2009-09-11T12:12:24.000Z",
        "likeCount": 0,
        "comment": "We don&#39;t know that.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-24JnocZ1mxY/AAAAAAAAAAI/AAAAAAAAAAA/R1mPY7GhggA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "seraph101",
        "commentId": "UgxCQNIFB-v1tvQlR8Z4AaABAg"
    },
    {
        "publishedAt": "2009-09-10T21:27:19.000Z",
        "likeCount": 0,
        "comment": "They have a secret restaurant in china that serve babies and kinda legal. Since you like to eat human, i thought you would like baby.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-1vkNHjpbcDQ/AAAAAAAAAAI/AAAAAAAAAAA/4Ep8QuogbnA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "BigiD16",
        "commentId": "UgzX18-oSmCAySw-d9p4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-09-08T12:11:20.000Z",
        "likeCount": 0,
        "comment": "Great video ! Thanks for posting . Viewers should be careful how they judge Mainland China Wing Chun after watching only 3 guys do a Form . It`s like taking 3 mediocre Wing Chun practioners from around the world , and then judging Yip Man based on Their performance! I learned One FoShan Style of Wing Chun , and I don`t recognize any of these 3 Styles/Forms .Something to think about .",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgzG57GkkrJEcyzn_2J4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-09-05T12:07:21.000Z",
        "likeCount": 0,
        "comment": "Great stuff! Of course ,they all look different or strange,since 99.99 %of WC outside of China came ,some how or other ,via the &quot;Yip Man &quot;Line .But be careful how you judge. Different does Not mean Not as Good ! I personally like the &quot;Yiu Choi &quot;guy. They usually won`t show anything!  And we can be sure he wasn`t a Sifu ,himself, even though he was Very good! Soft &amp;Loose means good Chi Kung,too !Anyway ,...fascinating !",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgwOAf2d9iVCHDXqMC14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-09-04T07:28:46.000Z",
        "likeCount": 0,
        "comment": "Awesome video , but scary trip for you &quot;ph8tel!&quot; Beautiful &quot; Fighting Chen Style Tai Chi&quot; ,and some Very good Wing Chun Chi Sao,though speeded up ;and , a Very Cool Wing chun 2-Man drill stuck right in the middle of it .Keep it coming &quot;ph8tel!&quot;",
        "videoId": "PI4zVUPluXU",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgzxozJUrElZWCY8q8l4AaABAg"
    },
    {
        "publishedAt": "2009-09-02T18:00:12.000Z",
        "likeCount": 0,
        "comment": "sick fucks",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-iBEuo_Z8vIQ/AAAAAAAAAAI/AAAAAAAAAAA/JvDTvDQ0orc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kung Zoo Gamer",
        "commentId": "Ugz8Uy99wUbO1xwaJLF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-09-02T17:59:58.000Z",
        "likeCount": 0,
        "comment": "what Jill said!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-iBEuo_Z8vIQ/AAAAAAAAAAI/AAAAAAAAAAA/JvDTvDQ0orc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kung Zoo Gamer",
        "commentId": "UgwMVcIt08-DQYZXa9t4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-09-02T14:46:11.000Z",
        "likeCount": 0,
        "comment": "Yeah , you can tell that he left the Bong Sao section out on purpose . It`s his Fake Form for public showing . &quot; Yiu Choi &quot; Line -they do that -Very Secretive . Great Stuff! Hey ph8tel ,...can you tell us about the circumstances in which you filmed this ? Thanks for posting this -post more .",
        "videoId": "nxRR3CBRbVM",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugwod_lKQIT6hXjXH7t4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-09-02T14:41:05.000Z",
        "likeCount": 0,
        "comment": "Definitely &quot; Yiu Choi &quot;Classic , Great techniques , built for Speed and Chi Kung! &quot;Force = Mass times Vilocity !&quot; Buffed - out guys don`t like the Science , but it`s a Fact !Really good .Can see he is doing it too quickly &amp;reluctantly. Like to know circumstances in which ph8tel filmed it.They  don`t generally show Forms publicly.KF Bros. may not`ve been around or a Relative may have pushed him to show something .They often Improvise ,or have a fake Form for Demos ,when asked to show something.",
        "videoId": "lSsDsr4WIYY",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugwk87_NgDB271PjaiF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-08-29T10:21:21.000Z",
        "likeCount": 0,
        "comment": "The 3rd pair of guys were &quot;Yiu Choi&quot; style -VERY good ,though young.  The 7th pair was &quot;Ling Nam &quot; style also quite good. I think ph8tel is right. When he whips out the camera their &quot;Face&quot;is involved and their school`s ,so they get a little aggressive. But  , compared to what? Just surf the WC Net  to answer that .  Q about Wu Shu vrs. Kung Fu is simple. Wu Shu is Martial Arts and KF is Extra ,Amazing Skill at Anything, from hard work.  A Common term . All around Great video ! Thanks ph8tel !",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgyJi4-92bRxtfs9YIR4AaABAg"
    },
    {
        "publishedAt": "2009-08-27T22:51:18.000Z",
        "likeCount": 0,
        "comment": "3:14 that stick went flying.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-LM1r1e3l4Qg/AAAAAAAAAAI/AAAAAAAAAAA/S-bt02xNEKA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "GrifMoNeY",
        "commentId": "Ugww7qHrxf69qr-VfLV4AaABAg"
    },
    {
        "publishedAt": "2009-08-27T19:08:17.000Z",
        "likeCount": 0,
        "comment": "I would love to grab the dog butcherer , And tie HIM or HER up and torture it to be &quot;tender&quot; THEN EAT THEM",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NQ8j2mbBPh0/AAAAAAAAAAI/AAAAAAAAAAA/eSUo9LdHp44/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pigscale",
        "commentId": "UgxE1CqW7PnRGvxmQfZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-08-25T14:37:03.000Z",
        "likeCount": 0,
        "comment": "craft....As far as Aggressive goes , ...I think it`s exactly what ph8tel said,...&quot; on camera ,...the Face is involved so everyone tries to Win. As soon as you try to win at Chi Sao ,we stiffen up ,and ...quality takes a nose dive. Also ,what are you comparing this to for Aggression? Check out other Wing Chun videos  -would an elbow to the head , a chop to the throat , or a stomp to the knee be less aggressive?...Actually , the 3rd pair of players were Excellent ! Pure &quot; Yiu Choi&quot; by the way.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugz0pRzDc7IWFpUzHC94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-08-25T14:26:04.000Z",
        "likeCount": 0,
        "comment": "Wow -Great video ! I just wish I had come across it earlier . There seems to be alot of confusion in the Wing Chun world. Wu Shu means Martial Arts .Kung Fu is just the common term. Actually Kung Fu means some special attainment or something extra. You can have Kung Fu in any sport,hobby,art,or craft....As far as Aggressive goes , ...I think it`s exactly what ph8tel said,...&quot; on camera ,...the Face is involved so everyone tries to Win. As soon as you try to win at Chi Sao ,we stiffen up ,and ...",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugzcu6esSfiCh6QMLjF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-08-25T02:02:39.000Z",
        "likeCount": 0,
        "comment": "Great video ! All of the matches were quite advanced compared to what we usually see on-line.Those sloppy fingers will cost them an eye ,though ,if they`re not careful. The 3rd and the 5th pair of guys were the best ,in my opinion.And the 3rd pair were the most advanced .They showed secrets that are not generally seen -a Classic example of &quot; The Snake in Clouds .&quot;",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "Ugw6NTN3-Jh_I9LiYZR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-08-23T09:44:20.000Z",
        "likeCount": 0,
        "comment": "They are usually extremely &quot;Shy&quot; about showing any Forms in public- it is often &#39;Forbidden &quot; . We were lucky you caught this ! Cheers !",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgydSsD4skXkzwtPe6Z4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-08-23T09:20:51.000Z",
        "likeCount": 0,
        "comment": "Just stumbled across this . Very Nice ! Definitely &quot;Yiu Choi &quot; Line . We see too little of this Style ,although it is The Biggest Branch current in FoShan at present .",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-qM1Vuzm1uSA/AAAAAAAAAAI/AAAAAAAAAAA/HPyrsPTXmvE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Steven J. Anderson",
        "commentId": "UgyBKNJs-1xf7oDFS1J4AaABAg"
    },
    {
        "publishedAt": "2009-08-20T22:46:55.000Z",
        "likeCount": 0,
        "comment": "China is sick, but i see some white people in there eatting dogs. I quess asian is not the only race that eat dogs...Crackers are sick to.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-u68qokBFGQA/AAAAAAAAAAI/AAAAAAAAAAA/n9_OHKV9J4k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Long Moua",
        "commentId": "UgweiNJMOw5026yGp1x4AaABAg"
    },
    {
        "publishedAt": "2009-08-19T06:14:23.000Z",
        "likeCount": 0,
        "comment": "china sick fuck",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Da1FnUSr_RA/AAAAAAAAAAI/AAAAAAAAAAA/CM9gIa7j8s8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tralala T",
        "commentId": "Ugy3hMrdgBBn04QxF9B4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-08-13T22:21:44.000Z",
        "likeCount": 0,
        "comment": "lol some were funny but some were brutal!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XGKrwtAzvIY/AAAAAAAAAAI/AAAAAAAAAAA/Kxrum1SWJlQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Brian Colditz",
        "commentId": "UgygaRrLzW6YVWq-PeV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-08-03T23:18:59.000Z",
        "likeCount": 0,
        "comment": "why dont u say stuff about eating cows chicken or pigs? they&#39;re ALL meat. eating dogs r cruel but its the same but other animals too. ppl have pig as pets, why dont u say shyte about that. oh and if ur SOO asahmed to be born chinese then why dont u just die, much easier for ignorant dumbass like u",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-xK5m-PiMT8c/AAAAAAAAAAI/AAAAAAAAAAA/vskYiazU4BY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Sukii",
        "commentId": "UgzoF2ThjnSPLnAMxUR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-07-26T15:31:22.000Z",
        "likeCount": 0,
        "comment": "FFFUUUCCCKKK  YOU !!!!!Go To Hell !!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-YLSYHiF_nEI/AAAAAAAAAAI/AAAAAAAAAAA/w0AbGiCnJLQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Roma Boltovsky",
        "commentId": "UgzFPa87uBVfR1RAT8F4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-07-22T23:53:15.000Z",
        "likeCount": 0,
        "comment": "The capacity for the dog to feel pain is no different to a cow or lamb so why don&#39;t you complain about that retards?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-zOm2bGkMyOM/AAAAAAAAAAI/AAAAAAAAAAA/S9VqLkca5BU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Yorosero",
        "commentId": "UgyUaSOn_HvuhJBtwfN4AaABAg"
    },
    {
        "publishedAt": "2009-07-22T23:51:02.000Z",
        "likeCount": 0,
        "comment": "Chinese have saying that goes like &quot;the smell of dog meat being cooked makes even the gods sway&quot;. There is nothing wrong with eating dog meat you ignorant fools.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-zOm2bGkMyOM/AAAAAAAAAAI/AAAAAAAAAAA/S9VqLkca5BU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Yorosero",
        "commentId": "Ugyoi-D2oagIwlt7MVF4AaABAg"
    },
    {
        "publishedAt": "2009-07-22T14:57:20.000Z",
        "likeCount": 0,
        "comment": "Wing chun probably did not come from a woman, but legends and stories are nice. :)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-24JnocZ1mxY/AAAAAAAAAAI/AAAAAAAAAAA/R1mPY7GhggA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "seraph101",
        "commentId": "Ugw_fXu2Argjo-adYeF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-07-22T01:03:06.000Z",
        "likeCount": 0,
        "comment": "U Fucking retarded, eating cats &amp; dogs?!?! Why don&#39;t eat Ur proper childs!! Hu?!\nI&#39;m ashamed to be born chinese, now I&#39;m ready to turn exclusively vegetarian &amp; save animals being!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-wU8ohWHUnXQ/AAAAAAAAAAI/AAAAAAAAAAA/y9QQ_NljWRk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SwissDragon88",
        "commentId": "UgylYF-KFKRdYC1t3G94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-07-21T22:08:36.000Z",
        "likeCount": 0,
        "comment": "nother fukers i hope china gets nuked!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-D8qWuANw-gs/AAAAAAAAAAI/AAAAAAAAAAA/xUy0T32ZAXM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "knuck7",
        "commentId": "Ugxh9A93fwdMSq9YpYR4AaABAg"
    },
    {
        "publishedAt": "2009-07-20T23:26:55.000Z",
        "likeCount": 0,
        "comment": "im chinese but not like those mainland fucks.only thing i like about mainland china is the pla(military)",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-8j3M3wz9lPo/AAAAAAAAAAI/AAAAAAAAAAA/9rGYsu5brVA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ryan97303",
        "commentId": "Ugy8bpvjGXSUqORdUHx4AaABAg"
    },
    {
        "publishedAt": "2009-07-20T00:59:57.000Z",
        "likeCount": 0,
        "comment": "From all those gun shots and explosions, these snowboarders surely must be in America,",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-l38QnGX3cDw/AAAAAAAAAAI/AAAAAAAAAAA/3qVbOSfj56g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dylan Brittain",
        "commentId": "Ugy2h5targQp5iXt7ld4AaABAg"
    },
    {
        "publishedAt": "2009-07-19T03:54:37.000Z",
        "likeCount": 0,
        "comment": "i know. but they do that with cows, pigs and chickens too.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-qclMqV0a7XM/AAAAAAAAAAI/AAAAAAAAAAA/vqrgCb-OQBM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "daglug1",
        "commentId": "UgxkuUrWa7LfE0-ItD94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-07-15T02:24:40.000Z",
        "likeCount": 0,
        "comment": "I don&#39;t eat dogs myself. But what makes it wrong to eat dogs but right to eat cow, pig ect?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-qclMqV0a7XM/AAAAAAAAAAI/AAAAAAAAAAA/vqrgCb-OQBM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "daglug1",
        "commentId": "UgwhjurV_resLvdj6a54AaABAg"
    },
    {
        "publishedAt": "2009-07-08T18:20:23.000Z",
        "likeCount": 0,
        "comment": "wing chun or kung fu in general has no gender base. women can practice martial arts just like men do.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-aMaaX8jiZr4/AAAAAAAAAAI/AAAAAAAAAAA/vKBlvJ2WhGs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "KurosakiIchigo",
        "commentId": "Ugx9AQR3HVGcqSF-hg94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-07-08T11:36:27.000Z",
        "likeCount": 0,
        "comment": "Fucking faggots. Why do you kill Dogs???!!! CUNTS!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-m-pIaVaTHq0/AAAAAAAAAAI/AAAAAAAAAAA/Ohk3u5ydZuw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "metalistas1993",
        "commentId": "UgxyTGCnHx7B3DGNwc14AaABAg"
    },
    {
        "publishedAt": "2009-07-02T06:41:39.000Z",
        "likeCount": 0,
        "comment": "disguisting no wonder why australia&#39;s dirtiest restaurants are chinese one&#39;s that&#39;s why i never eat chinese",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-91dn0JdGGrU/AAAAAAAAAAI/AAAAAAAAAAA/NqDu5gxosyI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kevinwadeheat",
        "commentId": "UgzroemQIopzm2K76VV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-06-26T01:27:52.000Z",
        "likeCount": 0,
        "comment": "I cant believe you actually ate that, PIGS!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-7Wo-2_nEiAE/AAAAAAAAAAI/AAAAAAAAAAA/Z0RQx2BzQnk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Najwa Jweiles",
        "commentId": "Ugyy39bAsgY8HxYdCq14AaABAg"
    },
    {
        "publishedAt": "2009-06-25T05:45:50.000Z",
        "likeCount": 0,
        "comment": "fuck them they are pure pussies",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-kgN8j3-MPB4/AAAAAAAAAAI/AAAAAAAAAAA/Zvev3K___LE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kimmynkim",
        "commentId": "UgzYgtLaywdG-8xwgDV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-06-25T00:03:52.000Z",
        "likeCount": 0,
        "comment": "which form was that man doing at the end? it looks sort of like chum kiu...",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-_V7-Thitr0k/AAAAAAAAAAI/AAAAAAAAAAA/bSxR28jdcPY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "egglot12",
        "commentId": "UgyXxVc2q0RGRbsxqql4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-06-24T23:44:31.000Z",
        "likeCount": 0,
        "comment": "tHEY ALSO SERVE FOETUS SOUP, BUDSSS!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-bS3tP85k5Hk/AAAAAAAAAAI/AAAAAAAAAAA/MFcPxD2eU8w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "GALAXY",
        "commentId": "Ugw01BMrsDpIMUJhCE94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-06-23T00:24:06.000Z",
        "likeCount": 0,
        "comment": "is that hard to do? iv always wanted to try.",
        "videoId": "FvqTcm4hC6o",
        "authorThumbnail": "https://yt3.ggpht.com/-BfkZ6Tz0tkM/AAAAAAAAAAI/AAAAAAAAAAA/5EvJhrue2mE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Henry Eckert",
        "commentId": "UgzKUVO4zqK2shL67F94AaABAg"
    },
    {
        "publishedAt": "2009-06-21T01:00:40.000Z",
        "likeCount": 0,
        "comment": "Bzzzt. Leviticus says eating pigs is an abomination.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-UYbmkvKqq6A/AAAAAAAAAAI/AAAAAAAAAAA/kOLVYn_KeI4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "spinemelter2000",
        "commentId": "Ugwdgjp3xO1p7a4b6Od4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-06-18T18:45:12.000Z",
        "likeCount": 0,
        "comment": "Fucking asswholes. GOD DAMNIT! THAT IS FUCKIN MEAN",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-eMcY6JpcPnw/AAAAAAAAAAI/AAAAAAAAAAA/tVqmw6SrHs8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Muscle Everything",
        "commentId": "UgzfENZ7tpdqtoaY5vl4AaABAg"
    },
    {
        "publishedAt": "2009-06-17T20:24:52.000Z",
        "likeCount": 0,
        "comment": "i loved the bullets in the background it made it sound like they were getting shot down",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-q7OrmE0Qqus/AAAAAAAAAAI/AAAAAAAAAAA/285tQaPtL2c/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "keenandunleavy",
        "commentId": "UgzHa4_52B3fhgpWt1h4AaABAg"
    },
    {
        "publishedAt": "2009-06-17T19:32:48.000Z",
        "likeCount": 0,
        "comment": "there are some sounds from gothic XD",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-x8zdepqoGTc/AAAAAAAAAAI/AAAAAAAAAAA/mtlvLYpwjS8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "DJFKOsdeM",
        "commentId": "UgxDrCwm-An4IpTs4fR4AaABAg"
    },
    {
        "publishedAt": "2009-06-16T05:13:07.000Z",
        "likeCount": 0,
        "comment": "Ip Ching is Ip Man&#39;s youngest son.\r\n\r\nPerhaps men practice it because it is a powerful style despite its originator...you have to remember the system was original founded and named after women but men helped to develop the system to what it is today....the way we know it today was probably not Wing Chun in its first conception...eventually weapons were added and probably more techniques, etc...",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "Ugy44DEhPkWTz6qKxY94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-06-15T10:28:11.000Z",
        "likeCount": 0,
        "comment": "do u mean 太師公?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-LVt5Qa-qoGk/AAAAAAAAAAI/AAAAAAAAAAA/MGzzwpeZ7aA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Leon Hudson",
        "commentId": "Ugy1Rz3ueFeCcCEI3Y14AaABAg"
    },
    {
        "publishedAt": "2009-06-15T10:24:10.000Z",
        "likeCount": 0,
        "comment": "i miss HK. i m going back soon. i cn tell wen i here the noise from the street.\r\n\r\n\r\nwing chun is good.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-LVt5Qa-qoGk/AAAAAAAAAAI/AAAAAAAAAAA/MGzzwpeZ7aA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Leon Hudson",
        "commentId": "UgxJ0EILQgQ76qGWWpN4AaABAg"
    },
    {
        "publishedAt": "2009-06-11T19:22:20.000Z",
        "likeCount": 0,
        "comment": "these are american and europeans eating it you moron",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-tO7f_jmjW_s/AAAAAAAAAAI/AAAAAAAAAAA/RSOnukX9g0w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "audisport",
        "commentId": "Ugwe8QORogY5iVNBE7J4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-06-07T07:46:37.000Z",
        "likeCount": 0,
        "comment": "I want to go to hong kong and train wc there. I want to live there too. why do guys mostly learn wc if it was made by a woman? I do see a lot of women practicing it. I see a lot of women practicing wushu. Anyways, is he related to Yip Man?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-2F8gSpQLISo/AAAAAAAAAAI/AAAAAAAAAAA/Qx7odeaoUTY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xiaolintraditional90",
        "commentId": "UgxowWgPv35OXm-cTFF4AaABAg"
    },
    {
        "publishedAt": "2009-06-05T05:00:56.000Z",
        "likeCount": 0,
        "comment": "Brute force, head hunting, no elbow....poor basics.Tsktsk tsk.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-d4cyUZ9UfoE/AAAAAAAAAAI/AAAAAAAAAAA/JRtXznbbgO8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "arosajas",
        "commentId": "Ugx_A59yh48qU-_CLxx4AaABAg"
    },
    {
        "publishedAt": "2009-06-02T09:46:46.000Z",
        "likeCount": 0,
        "comment": "chaos... i know that every lineage are different, but they should have a common core principles and theories. and a technique should based on that principles. if they know what the &quot;meaning&quot; behind the movement, they should not have so much difference IMO...",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-DcBWnzsEe1M/AAAAAAAAAAI/AAAAAAAAAAA/uy5X8Qm8kUM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "khoirus sobri",
        "commentId": "UgyfwUqv6VF0q8DRhLF4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:36:35.000Z",
        "likeCount": 0,
        "comment": "actually i mean blue shirt hes also the guy talking and said french snickers",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "UgyqbwbkWNwMnbwwwGd4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:35:49.000Z",
        "likeCount": 0,
        "comment": "lol i heard of my sifu doing this, hes the guy with tthe green shirt in the first 2 sec on the table. he said its really good, and my si-hing&#39;s were all freaking out when eating this",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "UgyVyJD2lWW6iWSD91R4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:16:09.000Z",
        "likeCount": 0,
        "comment": "omg no angles, no strength, and no footwork :(",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "UgxA-zn4saonIHbVIsZ4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:14:00.000Z",
        "likeCount": 0,
        "comment": "YAY CHRIS CHAN",
        "videoId": "iAGX1qUwjus",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "UgxWKC_o4DpZjkAHa-B4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:13:03.000Z",
        "likeCount": 0,
        "comment": "yip man",
        "videoId": "gmWbLpUty9w",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "Ugwn4kGddAWmLfV09Zt4AaABAg"
    },
    {
        "publishedAt": "2009-05-29T02:11:25.000Z",
        "likeCount": 0,
        "comment": "YAY ITS HELMUT",
        "videoId": "gmWbLpUty9w",
        "authorThumbnail": "https://yt3.ggpht.com/-_d2FLnnrb7M/AAAAAAAAAAI/AAAAAAAAAAA/4A9lSoRy1KA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jabangbang",
        "commentId": "UgyO6h7_3SpiDXNU14l4AaABAg"
    },
    {
        "publishedAt": "2009-05-26T09:37:25.000Z",
        "likeCount": 0,
        "comment": "geeeez I&#39;m sure you are grand fucking master.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-YcaSLddQFTI/AAAAAAAAAAI/AAAAAAAAAAA/ZhXTVLYZogY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "phazemekaniks",
        "commentId": "UgzWgghKIj4eYpaKC1V4AaABAg"
    },
    {
        "publishedAt": "2009-05-26T00:27:24.000Z",
        "likeCount": 0,
        "comment": "I hear ya...he&#39;s my Si Gung ;)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-AmeXm9ij0oQ/AAAAAAAAAAI/AAAAAAAAAAA/rmIIX1ztY7Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rgvwingchun",
        "commentId": "UgwxWl3RR67MJ5IKnqN4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-05-24T02:20:03.000Z",
        "likeCount": 0,
        "comment": "Ip Ching is amazing! He&#39;s my Si Tai Gung, so I have tremendous respect for him.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-8FGw455JOiE/AAAAAAAAAAI/AAAAAAAAAAA/Oz8uRKB7Mug/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Pradiip79",
        "commentId": "Ugx32Y8OVQdrW9wRe2Z4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-05-24T01:35:43.000Z",
        "likeCount": 0,
        "comment": "i hope those bastards chinese &amp; korean die \r\nthey eat cats &amp; dogs &amp; doneky meat !!\r\nslanted eye burn in hell !!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-W9PkeBHgNlI/AAAAAAAAAAI/AAAAAAAAAAA/oM91r0Eto5g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "boulmaouahibe",
        "commentId": "Ugwm6p4ZdaYGMGzlgvd4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-05-21T17:08:44.000Z",
        "likeCount": 0,
        "comment": "Pigs are not cute! They are fit only for human consumption, as they are tools of SATAN and must be eaten to prevent his EVIL from spreading via the PESTILENCE he sent forth from the depths of HELL.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-FMbeqV4-G7o/AAAAAAAAAAI/AAAAAAAAAAA/Giwk-YH-Oc8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ancalites",
        "commentId": "UgylXrO2heD0FConqn54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-05-18T13:40:51.000Z",
        "likeCount": 0,
        "comment": "It looks quite interesting. But the question that comes to my mind after watching wing chun in mainland China is, how do these communist wing chun or any other kung fu practitioners treat the internal aspect of the art? Like &quot;qi&quot;, &quot;fa  jing&quot; etc?",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-cXkSCo7908g/AAAAAAAAAAI/AAAAAAAAAAA/l3Iori2XkdM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "himalayanspirit",
        "commentId": "UgzGcyrihbf_Dgp_NbR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-05-16T21:47:06.000Z",
        "likeCount": 0,
        "comment": "i can tell you are properly educated you dumb piece of shit.  Get a fuckng life and stop trolling.  I don&#39;t care what you say, and I doubt any other other asians care either.  We&#39;re smart, and you don&#39;t got shit. what do you have you piece of shit? Stop convincing yourself you are good looking, you are just trying to make your piece of shit self feel better.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TQ9CJ8iGOzA/AAAAAAAAAAI/AAAAAAAAAAA/YTdoNSlHllk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xxh3llfir3xx",
        "commentId": "UgwFWrJcWjOE8CZQjcN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-05-16T02:57:40.000Z",
        "likeCount": 0,
        "comment": "JAJAJAJAJA you damn troll. Go get a life.  I bet you don&#39;t have a girlfriend do you? Go get your hispanic ass back to Mexico and mow my lawn, bitch. Don&#39;t complain about how you are spanish, you pendejo&#39;s are all the same.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TQ9CJ8iGOzA/AAAAAAAAAAI/AAAAAAAAAAA/YTdoNSlHllk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xxh3llfir3xx",
        "commentId": "Ugxino1nQOmDcxhCH5x4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-05-04T21:59:27.000Z",
        "likeCount": 0,
        "comment": "I love dogs. They are so cute and fluffy! How heartless! ;(",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-FMbeqV4-G7o/AAAAAAAAAAI/AAAAAAAAAAA/Giwk-YH-Oc8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ancalites",
        "commentId": "UgyrlUK7DLiorRpm5D54AaABAg"
    },
    {
        "publishedAt": "2009-05-04T13:24:45.000Z",
        "likeCount": 0,
        "comment": "00:28 Goodbye Knee",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-3kr_b4raNS8/AAAAAAAAAAI/AAAAAAAAAAA/wwL5B8LhwrE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "VandalCatt",
        "commentId": "UgzKuwRGda0ZQVBrtYV4AaABAg"
    },
    {
        "publishedAt": "2009-04-22T04:45:40.000Z",
        "likeCount": 0,
        "comment": "I got the track from the instrumental version of Deltron 3030.  All beats by Dan the Automator.",
        "videoId": "PI4zVUPluXU",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyAGBtGc05TTcNjoFN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-04-22T04:30:12.000Z",
        "likeCount": 0,
        "comment": "anyone know the music in this video? it&#39;s sick",
        "videoId": "PI4zVUPluXU",
        "authorThumbnail": "https://yt3.ggpht.com/-zaAF4_3qRxo/AAAAAAAAAAI/AAAAAAAAAAA/XgxcA-GdlfU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Robotaki",
        "commentId": "UgzMA6VeFYn9DUM0WsJ4AaABAg"
    },
    {
        "publishedAt": "2009-04-22T02:21:43.000Z",
        "likeCount": 0,
        "comment": "Wu Shu (martial art) thats the spoken word in Mandarin (the national spoken language in China). As for Gung Fu (martial art) thats the term they used in Hong Kong, as this is the spoken language (Cantonese) over there.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-RHWr0DDLUB0/AAAAAAAAAAI/AAAAAAAAAAA/a71GLhoS6DY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mikalji",
        "commentId": "UgznKYqxO9Pc9aGgdBF4AaABAg"
    },
    {
        "publishedAt": "2009-04-19T17:39:58.000Z",
        "likeCount": 0,
        "comment": "I might have a useful suggestion for you. First off I think it might be time to retire the old Kawasaki. I know this will be hard but the money  saved from burnt clothing  alone will be worth it. I just  retired my Burton Avalanche and tho I can no not do some tricks that involved the nose rope, my new board however does not catch fire.  If you do decide to keep the Kawi, try thinning the mixture or getting new reeds. Or coat the bottom with Ghi, which u can get from a Yak, next time in Nepal",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyvfPKLOGq8J-lm-PF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-04-17T15:33:52.000Z",
        "likeCount": 0,
        "comment": "how can you compare a dog to a cow?a dog is a domestical animal it&#39;s the man&#39;s best friend.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TtZcl1dfG-w/AAAAAAAAAAI/AAAAAAAAAAA/65dyxnQpqao/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Michel Angielczyk",
        "commentId": "Ugxniuvody2yYTB1R9J4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-04-13T23:56:20.000Z",
        "likeCount": 0,
        "comment": "FCK those who say we cant eat dogs.Read your fcking bible. every animal God made is clean and can be eaten. FUCK YOU DOG LOVERS!!!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-SuJSXGHbv_0/AAAAAAAAAAI/AAAAAAAAAAA/QxtLwzTRuVQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Carl Jay",
        "commentId": "UgxQs9xp9iipf2Eali14AaABAg"
    },
    {
        "publishedAt": "2009-04-13T22:17:28.000Z",
        "likeCount": 0,
        "comment": "Yes I am.",
        "videoId": "iAGX1qUwjus",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwbJf7L5h8Ybk-WTaB4AaABAg"
    },
    {
        "publishedAt": "2009-04-12T14:06:59.000Z",
        "likeCount": 0,
        "comment": "your ignorance gives me a headache.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-A6kPCHpuq8M/AAAAAAAAAAI/AAAAAAAAAAA/DLyCjqsiRDY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gordon Freeman",
        "commentId": "Ugx4zquSV0URKc0md-x4AaABAg"
    },
    {
        "publishedAt": "2009-04-12T13:59:24.000Z",
        "likeCount": 0,
        "comment": "mmm.... french poodle",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-A6kPCHpuq8M/AAAAAAAAAAI/AAAAAAAAAAA/DLyCjqsiRDY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gordon Freeman",
        "commentId": "UgzVRbjS3cIRoK3Rc8B4AaABAg"
    },
    {
        "publishedAt": "2009-04-10T21:53:31.000Z",
        "likeCount": 0,
        "comment": "Master Presas, Thank You for the dynamic video representing the FMA on youtube. I did not have the priveledge of meeting your brother or yourself although I must admit I had the chance. I thought that we would all be around forever, and we have lost so many. Keep doing what you do so well. Sincerely, Master Ron Saturno",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-cO43SgrN0gw/AAAAAAAAAAI/AAAAAAAAAAA/ikm_dpVSkX8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ron Saturno",
        "commentId": "UgxZKkc96hHlvAlG_-x4AaABAg"
    },
    {
        "publishedAt": "2009-04-08T11:02:03.000Z",
        "likeCount": 0,
        "comment": "ok ok we as homo sapeins should all stop eating, even plants, they are all living organisms humans should starve to death because some westerners think they are superior by not eating dogs rather they eat bigger animals like cows.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gfX1942Y2DY/AAAAAAAAAAI/AAAAAAAAAAA/30K2JaZ6ItY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "obsidianstatue",
        "commentId": "UgxVm8UD0qeX18YuMkx4AaABAg"
    },
    {
        "publishedAt": "2009-04-05T05:48:45.000Z",
        "likeCount": 0,
        "comment": "Hahahahaha best comment yet. Thanks",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwI05bxdEtyskh_9aB4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-04-05T03:56:41.000Z",
        "likeCount": 0,
        "comment": "in terms of food, what makes a dog or cat so different from a cow or a chicken? its all meat, and people need to eat",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-izljwCX_o4E/AAAAAAAAAAI/AAAAAAAAAAA/lvtdbaccsx4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "gamehazard101",
        "commentId": "UgzQl9Iig1MXvRieCCF4AaABAg"
    },
    {
        "publishedAt": "2009-04-02T08:50:59.000Z",
        "likeCount": 0,
        "comment": "a dog , a pig, a cow, same fucking shit to me....in France they eat snails and frog legs...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-IX_kdCi_Nkk/AAAAAAAAAAI/AAAAAAAAAAA/75q5znNnT2o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jesse jones",
        "commentId": "UgxexSm-8F2yn48fut94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-30T20:22:40.000Z",
        "likeCount": 0,
        "comment": "lovely skills! What is the guy on? He&#39;s going at 100 miles an hour!",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-IQHFw5dcsdo/AAAAAAAAAAI/AAAAAAAAAAA/70O4eFbgJJo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tong Liu",
        "commentId": "UgwvbFJ8dOP5wpOkSqN4AaABAg"
    },
    {
        "publishedAt": "2009-03-28T01:31:44.000Z",
        "likeCount": 0,
        "comment": "the sounds make the video blow",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-G6NCedhJjxw/AAAAAAAAAAI/AAAAAAAAAAA/yMmOpGy6SG4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Chunkler",
        "commentId": "Ugwb9f-TEJwMZSyiZpF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-22T01:48:00.000Z",
        "likeCount": 0,
        "comment": "I have never eaten a dog nor do I plan do but you people being against it is only do to the fact that you guys are too CLOSED-MINDED!!!!\n\nGuess what??!?! There are OTHER cultures in the world that see things differently than you!!! If they want to see dogs are both meat and pets, LET THEM!!!!\n\nI don&#39;t see the Islamic people running around bashing people who eat bacon.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vn0IqQk5qKE/AAAAAAAAAAI/AAAAAAAAAAA/FVcFd4_UHc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lixiaochenx277",
        "commentId": "UgyLatJO3jsRyzJR4CF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-22T01:43:22.000Z",
        "likeCount": 0,
        "comment": "@vac2311\nSo you must be saying that the pig you ate the other day was  NOT a happy living creature. Those cows, pigs, and chickens are all okay to eat because these animals MUST have been severely depressed!!!\nDogs however!?!? WE CAN&#39;T EAT THEM!!! THEY ARE HAPPY CREATURES!!! \n*sarcasm of course*\n\nTherefore: Your argument makes no sense.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vn0IqQk5qKE/AAAAAAAAAAI/AAAAAAAAAAA/FVcFd4_UHc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lixiaochenx277",
        "commentId": "UgxUmEgC9aq97Pblshl4AaABAg"
    },
    {
        "publishedAt": "2009-03-21T22:04:49.000Z",
        "likeCount": 0,
        "comment": "don&#39;t eat dog, eat a liberal",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-3q48h_2LZG4/AAAAAAAAAAI/AAAAAAAAAAA/93DZHtP1tTo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kelloggcerealxoxo",
        "commentId": "UgyJCn5rZXlzLTkdN_h4AaABAg"
    },
    {
        "publishedAt": "2009-03-21T21:56:54.000Z",
        "likeCount": 0,
        "comment": "cambodians eat rats, cambodians are jungle monkies. shit, I will eat cambodians for dinner. What have cambodians contributed to this planet. Cambodians eat disgusting things like the Vietnamese.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-3q48h_2LZG4/AAAAAAAAAAI/AAAAAAAAAAA/93DZHtP1tTo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kelloggcerealxoxo",
        "commentId": "UgyasBXAB1LJp75BGRB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-03-14T12:02:38.000Z",
        "likeCount": 0,
        "comment": "wahahaha this is PURE OWNAGE!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-8m1L279FK9M/AAAAAAAAAAI/AAAAAAAAAAA/GvgSsYl-dMM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "dragzz1",
        "commentId": "UgwOoSQadbWbXEF2t8Z4AaABAg"
    },
    {
        "publishedAt": "2009-03-12T19:40:39.000Z",
        "likeCount": 0,
        "comment": "i highly fucking doubt that beef u ate for dinner lived in a &quot;humane&quot; environment and  was righteously &quot;stunned&quot; to death. seriously, you need to get a reality check if you think ur slaughter houses treat animals like loved-pets.\n\nu make me laugh.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vn0IqQk5qKE/AAAAAAAAAAI/AAAAAAAAAAA/FVcFd4_UHc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lixiaochenx277",
        "commentId": "UgxZYGEWPuagEZmV-T94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-12T19:38:24.000Z",
        "likeCount": 0,
        "comment": "@mammothsoulja,\nDumbass here just agreed with his own comment.\n\nAnywho,\nSo ur one of the ignorant dumbasses who thinks in America, all the animals are treated fairly and &quot;stunned&quot; to death. Seriously, u americans think too highly of ur country.\n\nAnd come on, u want to kill me b/c of my point-of-view  on what animal some people want to eat? I think we all know who&#39;s the crazy insane dumbass here is.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vn0IqQk5qKE/AAAAAAAAAAI/AAAAAAAAAAA/FVcFd4_UHc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lixiaochenx277",
        "commentId": "Ugxyf35IbKDYOVmMPwh4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-11T23:48:00.000Z",
        "likeCount": 0,
        "comment": "yea ur basically fucked buddy .\nwe dont beat the cows and chickens.\nOH YEAH i belive be stun them so they dont feel any pain! how about that?\nWe take care of them we dont just take some random thing off the street.\nWe make sure it has a good life.\nso fuck your values buddy",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-c15kTGBVUvg/AAAAAAAAAAI/AAAAAAAAAAA/XSR6ub6mrME/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hava Rabbitstien",
        "commentId": "UgwEKCRiy4oeqJcMadJ4AaABAg"
    },
    {
        "publishedAt": "2009-03-11T23:41:54.000Z",
        "likeCount": 0,
        "comment": "Lol a little much but i agree with what u are pointing out , dont eat dog",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-c15kTGBVUvg/AAAAAAAAAAI/AAAAAAAAAAA/XSR6ub6mrME/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hava Rabbitstien",
        "commentId": "UgxrDF2xExfDlRUIWSJ4AaABAg"
    },
    {
        "publishedAt": "2009-03-11T23:40:15.000Z",
        "likeCount": 0,
        "comment": "ur fucked. \n\n i like pigs too i think they are awesome but honestly, its a dog.\nYou dont go around eating dogs.\nso yea.\nyou post a video of you eating a dog and i will shut up",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-c15kTGBVUvg/AAAAAAAAAAI/AAAAAAAAAAA/XSR6ub6mrME/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Hava Rabbitstien",
        "commentId": "UgyQv8E-FPmhS7D5i2B4AaABAg"
    },
    {
        "publishedAt": "2009-03-10T00:34:54.000Z",
        "likeCount": 0,
        "comment": "more &quot;sifus&quot; should do demos like this with fluidity and continuity instead of just blab blab blab",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-rSV3NSeR3rg/AAAAAAAAAAI/AAAAAAAAAAA/4mfJuor0Zq0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ssj7gokurpg",
        "commentId": "UgyyjoysGObmYlXYSux4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-09T23:25:01.000Z",
        "likeCount": 0,
        "comment": "Americans are sooooo fuckin stupid. Seriously, apparently your ignorant values are above the rest of the world.\n\nTell me, what the fuck is the difference between a dog and a pig. Oh, dogs are sooo much cuter right? \n\nThat just goes to show how retarded Americans are, only valuing anything that&#39;s good looking.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vn0IqQk5qKE/AAAAAAAAAAI/AAAAAAAAAAA/FVcFd4_UHc4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lixiaochenx277",
        "commentId": "UgzVdCr0renHPYoMQfF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-03-09T23:08:43.000Z",
        "likeCount": 0,
        "comment": "Just like water..flowing and crashing...be water my friends!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-TzEpHLwwsLg/AAAAAAAAAAI/AAAAAAAAAAA/eByoiGJ8-yk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Getoffofmycloud",
        "commentId": "UgwfHpbD5CIhGEKTOVB4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-03-09T23:05:30.000Z",
        "likeCount": 0,
        "comment": "its like these 2 are drunk?",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-TzEpHLwwsLg/AAAAAAAAAAI/AAAAAAAAAAA/eByoiGJ8-yk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Getoffofmycloud",
        "commentId": "UgyXEOQJaKCFWE1WEyp4AaABAg"
    },
    {
        "publishedAt": "2009-03-08T22:24:30.000Z",
        "likeCount": 0,
        "comment": "kk eating cow and pig along with chicken is a totally different thing than eating a cat or dog...huge difference...these motherfuckers put these innocent beautiful animals through inhumane torture for their humor and consumption....i wish i could make them feel the pain they put on these animals....hatrid grows with each sickening video i see...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-1DJG58VBTwc/AAAAAAAAAAI/AAAAAAAAAAA/p4eN6qOB_o0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Mckenna",
        "commentId": "Ugwrdiy-Jy9bKAVTke94AaABAg"
    },
    {
        "publishedAt": "2009-03-07T23:31:06.000Z",
        "likeCount": 0,
        "comment": "i LLOOVVEE the sound affects",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-sATB1Cn0StY/AAAAAAAAAAI/AAAAAAAAAAA/usHM3q2upkU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Baxten123",
        "commentId": "UgzjIS1n2EXGpuse6hh4AaABAg"
    },
    {
        "publishedAt": "2009-03-05T15:46:18.000Z",
        "likeCount": 0,
        "comment": "im chinese and i wouldt be very proud of it if its real..",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-bRsJ5TxHU5g/AAAAAAAAAAI/AAAAAAAAAAA/0m5zYKNabKk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mayuyunese",
        "commentId": "Ugww4s2dv49iHd9aq9V4AaABAg"
    },
    {
        "publishedAt": "2009-02-23T11:04:32.000Z",
        "likeCount": 0,
        "comment": "I like the break dance at the end",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-uiz92Yf_IR4/AAAAAAAAAAI/AAAAAAAAAAA/ElSgnYWZEKY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "JoeFromCO",
        "commentId": "UgyOvMNDjtx2fgk7zpp4AaABAg"
    },
    {
        "publishedAt": "2009-02-22T23:33:01.000Z",
        "likeCount": 0,
        "comment": "Haha, the sound effects are great",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XY2RXnzd9GY/AAAAAAAAAAI/AAAAAAAAAAA/qmsi7VZ-BZc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "nomadicsaan",
        "commentId": "Ugxr4OQRc5TINvYd_-Z4AaABAg"
    },
    {
        "publishedAt": "2009-02-20T08:17:05.000Z",
        "likeCount": 0,
        "comment": "that made my nipples hard\r\ngot to luv disarming tecniques",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-UX-BboPtHoA/AAAAAAAAAAI/AAAAAAAAAAA/47KR6zBoXUs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "BenderBlender",
        "commentId": "UgxTe8X8ptIzTjbVKt94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-02-19T21:23:54.000Z",
        "likeCount": 0,
        "comment": "sounds like a battle and the mountain is winning!!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-GoTUdZ0lJ7k/AAAAAAAAAAI/AAAAAAAAAAA/cUf-25LWhLQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Pat Rick",
        "commentId": "UgwIVL6XL1Wjwk65XEx4AaABAg"
    },
    {
        "publishedAt": "2009-02-19T15:35:41.000Z",
        "likeCount": 0,
        "comment": "I don&#39;t know how they get it... I personally wouldn&#39;t go deeper... but I know some Chinese eat babies for soup or fried...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gVS4zBbLU0w/AAAAAAAAAAI/AAAAAAAAAAA/jR-nmymqD9Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Bernardo Benedicto",
        "commentId": "UgyAeWngbugPTsSLSy14AaABAg"
    },
    {
        "publishedAt": "2009-02-18T02:22:01.000Z",
        "likeCount": 0,
        "comment": "French poodle...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BCkvAiLoZmo/AAAAAAAAAAI/AAAAAAAAAAA/BUfbNqy4bqI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "K U",
        "commentId": "UgxRLwueLCK6vz5bNWp4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-02-15T20:57:15.000Z",
        "likeCount": 0,
        "comment": "wow his father is 100?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-L4Gox5uoyfs/AAAAAAAAAAI/AAAAAAAAAAA/QLCMP8pUzEI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "sz42781",
        "commentId": "UgzMDX7EaQxR_kmu_BN4AaABAg"
    },
    {
        "publishedAt": "2009-02-15T01:30:48.000Z",
        "likeCount": 0,
        "comment": "the music is awesome",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-u1ecrY8BTnY/AAAAAAAAAAI/AAAAAAAAAAA/A2wK01xTocM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Carl Kohnstamm",
        "commentId": "UgxARKAXm3VJkQaTuSZ4AaABAg"
    },
    {
        "publishedAt": "2009-02-14T19:37:13.000Z",
        "likeCount": 0,
        "comment": "0:21 was the best lol. -machine gun- -smack-",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-oJkeRBdygQA/AAAAAAAAAAI/AAAAAAAAAAA/sLYqsnknIXc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dhampir50",
        "commentId": "Ugy0peoJto0sSiFoEYF4AaABAg"
    },
    {
        "publishedAt": "2009-02-10T12:42:27.000Z",
        "likeCount": 0,
        "comment": "Human Fetus... I&#39;m not kidding...\n\nWell I don&#39;t know China&#39;s such a big place... maybe that&#39;s from middle or west china... And I think it&#39;s culture... what you think is tabboo on your eyes, is acceptable to their eyes, and vice versa...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gVS4zBbLU0w/AAAAAAAAAAI/AAAAAAAAAAA/jR-nmymqD9Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Bernardo Benedicto",
        "commentId": "Ugwck_PRFKgjdg_9JYl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-02-08T14:07:35.000Z",
        "likeCount": 0,
        "comment": "nope... I&#39;ve been there... My family&#39;s from Southern China, Xiamen to be precise... why?\n\nI&#39;ve Seen fetus soup... that is worse i tell you...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gVS4zBbLU0w/AAAAAAAAAAI/AAAAAAAAAAA/jR-nmymqD9Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Bernardo Benedicto",
        "commentId": "UgzSvAeqVyPTUQY9znV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-02-08T06:19:37.000Z",
        "likeCount": 0,
        "comment": "Dog eats human, dog gets killed.\nHuman eats dog, human gets publicity. \nWHAT THE FUCK?!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-s8mqSJarjzg/AAAAAAAAAAI/AAAAAAAAAAA/eAmZN5oMz9Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SirenitySilver",
        "commentId": "UgzGTIGrqRlZiQJ6R2l4AaABAg"
    },
    {
        "publishedAt": "2009-02-07T22:57:13.000Z",
        "likeCount": 0,
        "comment": "lol the guy at end got man handled",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-7eXjXCbbHUo/AAAAAAAAAAI/AAAAAAAAAAA/qa5uqzQHS-4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "OverwatchSIX",
        "commentId": "UgyifEzWhdwokp-nDCB4AaABAg"
    },
    {
        "publishedAt": "2009-02-06T15:08:45.000Z",
        "likeCount": 0,
        "comment": "Im 1/2 Chinese I dont eat Dog...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gVS4zBbLU0w/AAAAAAAAAAI/AAAAAAAAAAA/jR-nmymqD9Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Bernardo Benedicto",
        "commentId": "UgzZfTfYR-6huXCrGx54AaABAg"
    },
    {
        "publishedAt": "2009-02-01T19:34:52.000Z",
        "likeCount": 0,
        "comment": "p.s. the whole snow was powder so of course there gonna fall",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-hQJCHZwidWQ/AAAAAAAAAAI/AAAAAAAAAAA/QNFO5X2eL6U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jay Parker",
        "commentId": "UgxEdaUkTCmo9009ydl4AaABAg"
    },
    {
        "publishedAt": "2009-02-01T19:33:06.000Z",
        "likeCount": 0,
        "comment": "the one at :35 was so kool those falls were wicked",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-hQJCHZwidWQ/AAAAAAAAAAI/AAAAAAAAAAA/QNFO5X2eL6U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jay Parker",
        "commentId": "Ugz7jESAvJmtBdy3HSx4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-01-31T05:57:24.000Z",
        "likeCount": 0,
        "comment": "Is this supposed to be rascist? :(",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-wpPF6EefFVA/AAAAAAAAAAI/AAAAAAAAAAA/aZrFBIBNUug/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jesscookie",
        "commentId": "Ugxyl-xwdEsUYExaQKl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-01-29T04:50:20.000Z",
        "likeCount": 0,
        "comment": "Ip Ching is pimp...Hes one of my favorite wing chun masters. Love that move at 1:36!  \nThanks for posting! :)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-4y8AAewPpug/AAAAAAAAAAI/AAAAAAAAAAA/0OSuxT_TklU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "keithdoeskungfu",
        "commentId": "UgxUyRXTB56xBKa7k454AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-01-29T00:20:36.000Z",
        "likeCount": 0,
        "comment": "i dont understand how that is gross, i mean theres no diference from eating a dog, from deer? But dogs are smart and loyal so dont eat them!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-DAevlfcmvUM/AAAAAAAAAAI/AAAAAAAAAAA/KcoB2Ccv31Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "xXTrueSShotX",
        "commentId": "UgzyXKqiH9cuWzHj1Yl4AaABAg"
    },
    {
        "publishedAt": "2009-01-28T19:50:25.000Z",
        "likeCount": 0,
        "comment": "some of these are crazzy jumps",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-PTjZpbe8nG4/AAAAAAAAAAI/AAAAAAAAAAA/jW--Xq2xnIw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ian Graham",
        "commentId": "Ugx12FebtvzJf6dXRol4AaABAg"
    },
    {
        "publishedAt": "2009-01-27T01:28:28.000Z",
        "likeCount": 0,
        "comment": "Great video ph8tel.....love the sound effffffects...",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-RTu0PJa0oMY/AAAAAAAAAAI/AAAAAAAAAAA/sWJvz7w16dI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lee South",
        "commentId": "Ugww229U0H9pF6Eqh-p4AaABAg"
    },
    {
        "publishedAt": "2009-01-22T23:10:56.000Z",
        "likeCount": 0,
        "comment": "Thank you for watching it.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxZ8J-cfNTNKoKs7MR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-01-22T22:04:42.000Z",
        "likeCount": 0,
        "comment": "holy shit!!!!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-94fwuiv91OU/AAAAAAAAAAI/AAAAAAAAAAA/KD2oFl6ARdA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "sidepoosh",
        "commentId": "Ugw02KBWrAIlhsRvJKl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-01-17T19:13:56.000Z",
        "likeCount": 0,
        "comment": "omg r they really eating french poodle? do they eat maltese? if they do... (message withheld for alot of reasons...)",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-5N0RoYuUCRw/AAAAAAAAAAI/AAAAAAAAAAA/Yg7jIzKGgPc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RosieTheDogRox",
        "commentId": "UgzfoAZUMzPDOuRs_Lh4AaABAg"
    },
    {
        "publishedAt": "2009-01-15T23:39:25.000Z",
        "likeCount": 0,
        "comment": "chinky bastards",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-iLKLaBk8jUc/AAAAAAAAAAI/AAAAAAAAAAA/OZvdvd8vw6U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Unwin96",
        "commentId": "UgwD-JGRkpGKjxM3BDh4AaABAg"
    },
    {
        "publishedAt": "2009-01-14T01:25:11.000Z",
        "likeCount": 0,
        "comment": "0:59 was ridiculous",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-0eRKATZPDC4/AAAAAAAAAAI/AAAAAAAAAAA/bQMsddq2SOw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cynoodles",
        "commentId": "UgwfJRUCe21FdbMjduh4AaABAg"
    },
    {
        "publishedAt": "2009-01-12T22:26:37.000Z",
        "likeCount": 0,
        "comment": "haha the one at :35 wasnt smart to start with",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-CVhcDWA7F9s/AAAAAAAAAAI/AAAAAAAAAAA/CinVF1wqCEk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Frank Dapolito",
        "commentId": "Ugx8FNjSoDZM86eY9iF4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-01-11T06:25:59.000Z",
        "likeCount": 0,
        "comment": "They are all very sloppy with poor controll of body unity in turns and waste too much energy on their hands instead of proper placement of the controll elemant. the elbow!the kicks are missing mostly and when used are at bad angles.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-rwDXTE1ua8E/AAAAAAAAAAI/AAAAAAAAAAA/LhVsXXjKmYU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jon Rister",
        "commentId": "UgyndJYvdZpR4MMtvTV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2009-01-08T05:34:11.000Z",
        "likeCount": 0,
        "comment": "Someone keeps telling me how smart chinese people are.. I say not!.. They lack common sense and wisdom..Eating animals that have such beautiful social spirits.. that is earthly wrong !!!! Eating anything that breathes and moves is wrong period. and those americans eating a dog? .what were they trying to demonstrate? hey I have been to China and ate a dog..here&#39;s your medal.. you morons.. pathetic people..",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TTv1HYLC9HM/AAAAAAAAAAI/AAAAAAAAAAA/8iRe0FAl7TU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "iiuva",
        "commentId": "UgwC6SM6XIodnrRA9MB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-01-07T11:36:24.000Z",
        "likeCount": 0,
        "comment": "yes, this is wing chun!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-FK8EbaRadF0/AAAAAAAAAAI/AAAAAAAAAAA/PBiG9O0Bdio/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "basstip",
        "commentId": "Ugz_y3hJMi8a5bnuzLB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2009-01-02T23:48:31.000Z",
        "likeCount": 0,
        "comment": "That&#39;s part Of the PleasurE !!!!!!!!!!!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Xaqx3lZYB_k/AAAAAAAAAAI/AAAAAAAAAAA/XdQIK24-1M8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Nicolas Huckle",
        "commentId": "UgwMDCSUAAkx1c4moId4AaABAg"
    },
    {
        "publishedAt": "2008-12-31T03:50:04.000Z",
        "likeCount": 0,
        "comment": "yes it is",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-zi3VYokh5bU/AAAAAAAAAAI/AAAAAAAAAAA/9AjWOJJkxr0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Deus215",
        "commentId": "UgxWB4I8fDyWw3A4jeN4AaABAg"
    },
    {
        "publishedAt": "2008-12-27T04:09:14.000Z",
        "likeCount": 0,
        "comment": "its in china smartass",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-EQHXuA7qrr4/AAAAAAAAAAI/AAAAAAAAAAA/gds8ihvg1jw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "vegano1",
        "commentId": "Ugzv5QLDG7IMjNIGlxR4AaABAg"
    },
    {
        "publishedAt": "2008-12-25T13:21:59.000Z",
        "likeCount": 0,
        "comment": "Talk about smart dog shouldn&#39;t be eaten. Give me a break people. If intelligent shouldn&#39;t be killed then why not killed all the the fags and lesbian for food too then. If your human u should know better not to fuck ur own gender. Even animals don&#39;t fuck their own gender. So that proves the fact that we humans are dumber then animals by far. It comes down to whoever is below the food chain. Even monkey eat their kind. when they are closest to humans.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-kdeUaod1x4A/AAAAAAAAAAI/AAAAAAAAAAA/ab00ZCdSNz0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cheu vang",
        "commentId": "UgzR7cEWPL_A3c2uxz54AaABAg"
    },
    {
        "publishedAt": "2008-12-19T03:08:49.000Z",
        "likeCount": 0,
        "comment": "Amen",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugwvg1pCh4f_wDtIg9J4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-12-17T18:20:25.000Z",
        "likeCount": 0,
        "comment": "lol you have taken the background from a war game like medal of hornor or battlefield or something!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-MdNbn3I5oF8/AAAAAAAAAAI/AAAAAAAAAAA/hLlA1sLsRqA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "olex2174",
        "commentId": "Ugx7DZ1llH-HKJ3nck94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-12-15T09:40:43.000Z",
        "likeCount": 0,
        "comment": "You white folks should do some research on Yahoo or Google to find out how many whales, seals, sharks, and polar bears were slaughtered by Western countries per year before you criticize others. Those countries include good old U.S.A., Canada, Norway, Sweden, Iceland, etc..... Bunch of hypocrites!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BEjubpgwXho/AAAAAAAAAAI/AAAAAAAAAAA/Z_60iSu-t1I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "angelsbridal",
        "commentId": "UgzAYnzyhJX-_ml-p954AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-12-15T09:29:57.000Z",
        "likeCount": 0,
        "comment": "This is really nothing when comparing to Canadian whack and slaughter nearly 300,000 seals per year. Isn&#39;t seal a really friendly and smart animal? By the way, Canada is where that hypocrite Paul Watson the captain of Sea Shepherd vessel in the Whale Wars. You white folks really have double standard when the issue involved Asian, huh? Don&#39;t you?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BEjubpgwXho/AAAAAAAAAAI/AAAAAAAAAAA/Z_60iSu-t1I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "angelsbridal",
        "commentId": "UgyeVRmXJ_0hOKmvz5h4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-12-15T09:17:17.000Z",
        "likeCount": 0,
        "comment": "Yeah, but you must be from Taiwan who does not really want to admit you are Chinese(Chun Guo Ren). You will only admit yourself as Chinese(in English) because that&#39;s the only way Westerners will call you. Cows are consider holy in India. Does that mean everyone should not eat beef? Aren&#39;s seals smart? Canadian government allow their people to whack and kill nearly 300,000 seals per year. Why don&#39;t you fuck them?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BEjubpgwXho/AAAAAAAAAAI/AAAAAAAAAAA/Z_60iSu-t1I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "angelsbridal",
        "commentId": "UgxDGTzKENTVXQo3gDB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-12-14T19:58:37.000Z",
        "likeCount": 0,
        "comment": "yh I agree, we slaughter loads of animals but as soon as somebody slaughters a dog its all OMFG!. Don&#39;t get me wong I eat meat but why is a dogs life any more special than a cow or chicken",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-WPPVKOOwFVE/AAAAAAAAAAI/AAAAAAAAAAA/c2s2loWt4g8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Patrick Bateman",
        "commentId": "UgxFUzJ481z1Qn0XlWd4AaABAg"
    },
    {
        "publishedAt": "2008-12-11T06:16:03.000Z",
        "likeCount": 0,
        "comment": "yes, it is.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-Z7H7OqWbnvo/AAAAAAAAAAI/AAAAAAAAAAA/gwnldmWqk4U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "BalwantSingh8",
        "commentId": "UgwxsULzHmAvVhHpkGV4AaABAg"
    },
    {
        "publishedAt": "2008-12-07T04:59:29.000Z",
        "likeCount": 0,
        "comment": "thats like saying. you know, if i go outside, there is a possibility that someone might shoot a bullet at me, or a lighting bolt will hit me. sometimes you just need to go out and not care if you break a couple dozen bones.",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-KEmFRIlh7qo/AAAAAAAAAAI/AAAAAAAAAAA/N6oCSSn66cI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ilovedagreenday",
        "commentId": "UgwXlAjTc4DxaGFd3jl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-12-06T07:52:18.000Z",
        "likeCount": 0,
        "comment": "LOL!!!!!!!!!!!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-7xwJu6esmIo/AAAAAAAAAAI/AAAAAAAAAAA/KaKdcEDDwfQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Garrett West",
        "commentId": "UgwpfWIZiaFJyZBHWll4AaABAg"
    },
    {
        "publishedAt": "2008-12-06T04:49:39.000Z",
        "likeCount": 0,
        "comment": "What the HELL if you know that you might or will get hurt why would you put yourself in a position where you will get scraps and broken bones im pretty crazy when snowboarding but im not that stupid",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-D25NWLEzkkE/AAAAAAAAAAI/AAAAAAAAAAA/cX8w4BowmCY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jesse_94_XJ",
        "commentId": "UgwRN_K3vg6GZhA3Pd94AaABAg"
    },
    {
        "publishedAt": "2008-12-02T21:54:24.000Z",
        "likeCount": 0,
        "comment": "haha ya....mine ended with a concussion last yr",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-eish2KmEUas/AAAAAAAAAAI/AAAAAAAAAAA/tTDYy3HoabI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Fire4Effect",
        "commentId": "Ugy5kFE7L75pM8U3XL14AaABAg"
    },
    {
        "publishedAt": "2008-12-01T07:06:08.000Z",
        "likeCount": 0,
        "comment": "like what the fuck.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-k4e3IsHs6Qc/AAAAAAAAAAI/AAAAAAAAAAA/5cbo3fk8jVU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "bob lobob",
        "commentId": "UgxWWJcEkHDq938cuOp4AaABAg"
    },
    {
        "publishedAt": "2008-11-27T19:45:17.000Z",
        "likeCount": 0,
        "comment": "&quot;Dogs are intelligent, I know. I can practise telepathy with my dog&quot; You have spoken volumes about yourself with this brief statement, Thank you ) Between the high-pitched typeing and the dog telepathy you have drawn me a great mental picture of the person behind the screen. Made my day, thanks again)",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-PysKGNAhptc/AAAAAAAAAAI/AAAAAAAAAAA/YNRNVkLQvHM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "punyo4u",
        "commentId": "UgynjcsKBrfIbxt1jBV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-11-25T22:48:33.000Z",
        "likeCount": 0,
        "comment": "Things in China are changing. Everytime I go back it is very different. I do wonder if our friend &quot;Masteringwushu&quot; isn&#39;t an aspiring party member. If you see some of my older vids, they are all outside and at night. Not really illegal, but not really encouraged. Because of the MA aspect of Falun Gong, things were tense for all of us. \n\nBTW I love Prahaa! Great city and gorgeous women.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgzeyGOwnMSfuuWMY8J4AaABAg"
    },
    {
        "publishedAt": "2008-11-24T13:52:45.000Z",
        "likeCount": 0,
        "comment": "this is what I was told in China. There is no such thing as Gung Fu in China. There is ONLY Wu Shu. Wu Shu was all styles and everything. The person who told me this was a Party (communist) member so this was the official Party view. I have learned not to argue with party members, even when they correct my English. ( I am a native speaker of English )",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxLuKMB75EHN9S_KR94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-11-24T02:19:23.000Z",
        "likeCount": 0,
        "comment": "lol soft snow softens and it hurts just for a bit kinda like a 5 min ow ow ow rant and then it ends... but landing on hard snow sucks!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-HA-GztjXzuA/AAAAAAAAAAI/AAAAAAAAAAA/7z19qvihJ68/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "shitsons",
        "commentId": "UgxB_1eR0wBe2qox7XV4AaABAg"
    },
    {
        "publishedAt": "2008-11-17T20:55:31.000Z",
        "likeCount": 0,
        "comment": "the guy in the middle has the best structure, the guy in the red was sloppy, the guy in the thong seemed to waste too much energy.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-t1d0GQJf6u0/AAAAAAAAAAI/AAAAAAAAAAA/uGXvJXulTLg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "PathofCultivation",
        "commentId": "UgxJE9rN_GVWw8UJ0xl4AaABAg"
    },
    {
        "publishedAt": "2008-11-12T01:00:02.000Z",
        "likeCount": 0,
        "comment": "if you even land right you can get a compound fracture in your leg so if you fall it fucking kills",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-t1ESazi_UPE/AAAAAAAAAAI/AAAAAAAAAAA/guSX2o0WUrA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cameronclgn3",
        "commentId": "Ugyv-1PKNLtL-hrz4kd4AaABAg"
    },
    {
        "publishedAt": "2008-11-10T05:10:02.000Z",
        "likeCount": 0,
        "comment": "depends what u land on, powder softens you alot most of the time but it still hurts quite a bit",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-3FJNMrdwNaw/AAAAAAAAAAI/AAAAAAAAAAA/H-XznOcKPYw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "koolio888",
        "commentId": "UgzdaJlyKeFIVTZTRr54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-11-09T21:39:28.000Z",
        "likeCount": 0,
        "comment": "Stupid question but how much does it hurt when you fall while trying to land a jump?",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BrR1ZixK9JE/AAAAAAAAAAI/AAAAAAAAAAA/iqhJci1DNn4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Daniel Gould",
        "commentId": "Ugw7BLArOT7l2ufzKB54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-11-09T21:39:02.000Z",
        "likeCount": 0,
        "comment": "Not seen the other video where he&#39;s not falling, then? Guy&#39;s really good, everyon falls now and then, it just looks worse coz it&#39;s a lot in one go :p",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-BrR1ZixK9JE/AAAAAAAAAAI/AAAAAAAAAAA/iqhJci1DNn4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Daniel Gould",
        "commentId": "UgzPPEw04s1GEd6uNWt4AaABAg"
    },
    {
        "publishedAt": "2008-11-08T00:19:48.000Z",
        "likeCount": 0,
        "comment": "makes sense since Ernesto Presas studied multiple martial arts (including jujutsu) in order to expand the capabilities of his base FMA.  I mean, c&#39;mon, everybody is human: we all have two arms and two legs.  We all can move the same; you&#39;re going to see overlaps amongst martial arts.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-IYXA1EInCEE/AAAAAAAAAAI/AAAAAAAAAAA/mAYXufFC3DQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "OneAngryAsian",
        "commentId": "UgxJNiNaM0YUGcfZ2EN4AaABAg"
    },
    {
        "publishedAt": "2008-11-06T21:05:30.000Z",
        "likeCount": 0,
        "comment": "at least there trying",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-XcG-rXQlCcA/AAAAAAAAAAI/AAAAAAAAAAA/8VMtT6P9DR8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "AustinChole10",
        "commentId": "UgxCSgKD3xPgpY417yh4AaABAg"
    },
    {
        "publishedAt": "2008-10-30T07:41:10.000Z",
        "likeCount": 0,
        "comment": "All of them had major, uberly epic FAILS",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-1yMDfyYdN3Y/AAAAAAAAAAI/AAAAAAAAAAA/ezU1v1YLD7s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "James J",
        "commentId": "Ugwv7vx4E1iDC485yUB4AaABAg"
    },
    {
        "publishedAt": "2008-10-30T07:20:41.000Z",
        "likeCount": 0,
        "comment": "NASTY THAT WHITE GUYS IS JUST NASTY",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-u5SyDPCnvFU/AAAAAAAAAAI/AAAAAAAAAAA/q90T3pyRQ4Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "markkodonto",
        "commentId": "UgxNu_77QU-80PZw5aZ4AaABAg"
    },
    {
        "publishedAt": "2008-10-29T23:38:41.000Z",
        "likeCount": 0,
        "comment": "yes it is, this was in &#39;98 when he and some students from his school went back for a visit.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-zi3VYokh5bU/AAAAAAAAAAI/AAAAAAAAAAA/9AjWOJJkxr0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Deus215",
        "commentId": "UgxOXQbFNkNuZOSMmgB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-10-27T14:46:08.000Z",
        "likeCount": 0,
        "comment": "And i train Martial-Arts since 1992. Every single day!I am also instructing my own group of martial-artists. But we are never trying to win against one another by doing chi-sao. Same thing is to &quot;beat&quot; the wooden dummy. No need to, cause you don´t need to hit it hard for learning how to use your own body-mechanics, and gaining a sense of feeling for your ancles. The harder you hit it, the lesser you will learn... Thats just my opinion. Greetz, Col.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-rI-MInBw-pU/AAAAAAAAAAI/AAAAAAAAAAA/7yafJCOs3nw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ColDuSack",
        "commentId": "UgyHzUPpDex6AWg_r0l4AaABAg"
    },
    {
        "publishedAt": "2008-10-27T14:45:28.000Z",
        "likeCount": 0,
        "comment": "Hmmmmm....looks to me like those two don´t understand the idea behind chi-sao. as i have learned: chi-sao is a game to gain a sense of feeling of contact, and not to try to beat the other one you are &quot;playing&quot; with. It is surely no sign of quality to act like both of them did. And this is the reason why i do not want to &quot;wear&quot; a title like Si-Hing, Si-Fu or GM or something in this manner. It is because i feel a shame to be compared with people who act like this and call themselves &quot;masters&quot;.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-rI-MInBw-pU/AAAAAAAAAAI/AAAAAAAAAAA/7yafJCOs3nw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ColDuSack",
        "commentId": "UgxGrDrJ4RMvJWiFlX14AaABAg"
    },
    {
        "publishedAt": "2008-10-22T21:53:39.000Z",
        "likeCount": 0,
        "comment": "F U.... You are not american",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-8mDR7peo2N8/AAAAAAAAAAI/AAAAAAAAAAA/n8u5ktTI3cE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Constantine",
        "commentId": "Ugyk0xSVBbeXpQgW_454AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-10-16T17:54:44.000Z",
        "likeCount": 0,
        "comment": "og why r they eating it!!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-XYFvXIu46qw/AAAAAAAAAAI/AAAAAAAAAAA/fgfLZhPQZok/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "aslizz",
        "commentId": "Ugwdzb47LirGOh4fELN4AaABAg"
    },
    {
        "publishedAt": "2008-10-01T21:24:56.000Z",
        "likeCount": 0,
        "comment": ":53 almost landed a backflip:)",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-0IZvkk-vTOM/AAAAAAAAAAI/AAAAAAAAAAA/KgMHtsFoJ4Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tipibo",
        "commentId": "UgwaDIW4MRgcXaNtmLN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-09-30T04:17:43.000Z",
        "likeCount": 0,
        "comment": "eata dogg snicker ???? dumb ass I HOPE U ROTT IN HELL",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-L5fIqS5dET8/AAAAAAAAAAI/AAAAAAAAAAA/bzwm64fVht8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Brenda Batres",
        "commentId": "Ugxmy1CH_-fLPV4qlHl4AaABAg"
    },
    {
        "publishedAt": "2008-09-26T22:17:21.000Z",
        "likeCount": 0,
        "comment": "ima a christian/catholic but im sure any god in any religon thinks that wat is given is a holy thing (if that makes sens) meaning anything given to a person was given to them for a purpose....and pretty much that is my opinion... hate if u want to thats cool i just have a very very stong opinion on this subject",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vit9z0-3bJ8/AAAAAAAAAAI/AAAAAAAAAAA/axVoYgVb4Qc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Queen",
        "commentId": "UgzTBrYRcQMhaHWv70F4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-09-24T07:25:06.000Z",
        "likeCount": 0,
        "comment": "can u give the address of this school?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-HkycXtUWcIw/AAAAAAAAAAI/AAAAAAAAAAA/tHdsrHfuEIc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jiman dont know",
        "commentId": "UgwfkPIvnZ4NngbJKsV4AaABAg"
    },
    {
        "publishedAt": "2008-09-24T06:20:02.000Z",
        "likeCount": 0,
        "comment": "if i ever saw a china man eating a cat i would pull out my rifle",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TxkSBQ1rHHg/AAAAAAAAAAI/AAAAAAAAAAA/jH3SSmGEPh4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "AnimeTalk",
        "commentId": "UgzgRQekkuSweg6kyXB4AaABAg"
    },
    {
        "publishedAt": "2008-09-20T22:21:55.000Z",
        "likeCount": 0,
        "comment": "ur not a real american....a real american knows that a mans best friend that has stuck with him for ages that help defend his own land and protect his own family...u are not american idc wat othere countries do but here in america we dont eat our best friend and we dont eat anything that helps us improve out lives wither it be help elders or the blind....how u feel if u had a dog and we ate him or her.again u are not an american and im srry that u have the guts to eat gods gift to the people.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Vit9z0-3bJ8/AAAAAAAAAAI/AAAAAAAAAAA/axVoYgVb4Qc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Queen",
        "commentId": "UgwDOa8p8J80jip345l4AaABAg"
    },
    {
        "publishedAt": "2008-09-12T06:03:21.000Z",
        "likeCount": 0,
        "comment": "great vid...check out my page. i have a similar boat and some great vids of it.",
        "videoId": "cvtXtJRwO6U",
        "authorThumbnail": "https://yt3.ggpht.com/-9SfSFowPplA/AAAAAAAAAAI/AAAAAAAAAAA/z0YbalEmAvY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "snielsen",
        "commentId": "UgwDU9IlCC-00EuStuR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-09-12T00:42:57.000Z",
        "likeCount": 0,
        "comment": "is this guy pro? he&#39;s really good!",
        "videoId": "LEAexG6YkX4",
        "authorThumbnail": "https://yt3.ggpht.com/-1DqEWfaQD90/AAAAAAAAAAI/AAAAAAAAAAA/5lMGUZbDtvA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kevin Fournier",
        "commentId": "Ugw2w1Nn636g0ONMUTp4AaABAg"
    },
    {
        "publishedAt": "2008-09-02T00:28:31.000Z",
        "likeCount": 0,
        "comment": "way better",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-AmeXm9ij0oQ/AAAAAAAAAAI/AAAAAAAAAAA/rmIIX1ztY7Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rgvwingchun",
        "commentId": "UgyUGPGWk8VCapDw6HZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-09-02T00:28:13.000Z",
        "likeCount": 0,
        "comment": "no joke! I have the video. It was a chi sao seminar after the 2005 VT World Conference. I beleive at Edmond Fong&#39;s school",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-AmeXm9ij0oQ/AAAAAAAAAAI/AAAAAAAAAAA/rmIIX1ztY7Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "rgvwingchun",
        "commentId": "UgyiAxpIlUIJUA7TX6F4AaABAg"
    },
    {
        "publishedAt": "2008-08-30T11:34:17.000Z",
        "likeCount": 0,
        "comment": "Agreed, nor was Yip man the only student of his master for that matter, and so on and so on.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-hWUGpkS20JY/AAAAAAAAAAI/AAAAAAAAAAA/GLhh-qZYvIw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Ryan D",
        "commentId": "UgyYfTmZLd-Xm2eOL4V4AaABAg"
    },
    {
        "publishedAt": "2008-08-25T10:37:32.000Z",
        "likeCount": 0,
        "comment": "&gt;,&gt; speak Louder it seems like only a few openmined people can actually understand what ur saying. I totaly agree with u by the way.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NtYeWoqybZ0/AAAAAAAAAAI/AAAAAAAAAAA/HBwzKn-Orj0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Kepler Auguste",
        "commentId": "UgwaaB7ZfcMjXCsjCv14AaABAg"
    },
    {
        "publishedAt": "2008-08-20T02:58:59.000Z",
        "likeCount": 0,
        "comment": "yes he is better then gary lam. However that doesn&#39;t mean gary isn&#39;t good as well.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-3RpYgd7s_BI/AAAAAAAAAAI/AAAAAAAAAAA/jz09EVKIC5Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SRO3",
        "commentId": "UgwR_BiDyy5NiW6pjF54AaABAg"
    },
    {
        "publishedAt": "2008-08-19T16:53:02.000Z",
        "likeCount": 0,
        "comment": "I don&#39;t mind them eating REAL hot dogs, just as long as the dogs are put out of their misery first before cut up and cooked.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-gvKDoL8WoV8/AAAAAAAAAAI/AAAAAAAAAAA/FHZBCtt4bZY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tarbaby Bruce",
        "commentId": "UgzNuUQWXXmxBh5BkRV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-08-18T03:31:43.000Z",
        "likeCount": 0,
        "comment": "Wing Chun was invented by a woman!!",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-mZEjwqbSAwY/AAAAAAAAAAI/AAAAAAAAAAA/rncfbQCyuEQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "codeagent47",
        "commentId": "UgybbR0DlxVDHCFmSnh4AaABAg"
    },
    {
        "publishedAt": "2008-08-11T21:47:12.000Z",
        "likeCount": 0,
        "comment": "its just one of those days...",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-C2ONol-QKro/AAAAAAAAAAI/AAAAAAAAAAA/zCgWBiG5Dkg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "coonhound34",
        "commentId": "UgwP34RVsUCEOYgAuE14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-08-05T14:46:09.000Z",
        "likeCount": 0,
        "comment": "Very different styles... Guy on left seemed fast , but loose structure. other two seemed alright, but both needed structure tweakings also. middle seemed closest to being stable. All three forms though had very interesting tweakings of the actual forms. Can these forms be incorporated in wingchunclub&#39;s suinumtao? Do the movements have advantages or are they just a different way of accomplishing the same things? Nice models though for adding moves to the neural paths.",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-X24N1YceHu8/AAAAAAAAAAI/AAAAAAAAAAA/9lNMZFrecCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Brian Schuch",
        "commentId": "UgxRCjc_k62UPqxgPrN4AaABAg"
    },
    {
        "publishedAt": "2008-08-01T04:27:28.000Z",
        "likeCount": 0,
        "comment": "now your comparing a snail to a dog. R u a retard cause ill understand then. and its &quot;march&quot;. dumb ass",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mSZv3OudLiQ/AAAAAAAAAAI/AAAAAAAAAAA/Yd5TR6TGq8g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Genghis Khan",
        "commentId": "Ugwwxf5wvilAzwlPMNl4AaABAg"
    },
    {
        "publishedAt": "2008-08-01T04:23:28.000Z",
        "likeCount": 0,
        "comment": "dumb fuck. all animals r different. some much smarter then others. Dogs save lives. There are police dogs, life guard dogs and blind people dogs. real &quot;bitch ass&quot;.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mSZv3OudLiQ/AAAAAAAAAAI/AAAAAAAAAAA/Yd5TR6TGq8g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Genghis Khan",
        "commentId": "UgzSdfXazCXnUtoip3J4AaABAg"
    },
    {
        "publishedAt": "2008-08-01T04:15:49.000Z",
        "likeCount": 0,
        "comment": "i dont give a shit how it tastes. dumbass. y eat mans best friend",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-mSZv3OudLiQ/AAAAAAAAAAI/AAAAAAAAAAA/Yd5TR6TGq8g/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Genghis Khan",
        "commentId": "UgxWbu4duxQWS6dgYot4AaABAg"
    },
    {
        "publishedAt": "2008-08-01T00:36:46.000Z",
        "likeCount": 0,
        "comment": "i thought snow was soft lol till i found out.......",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-C2ONol-QKro/AAAAAAAAAAI/AAAAAAAAAAA/zCgWBiG5Dkg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "coonhound34",
        "commentId": "Ugww3rQLnRCUbG8QO_d4AaABAg"
    },
    {
        "publishedAt": "2008-07-30T17:19:17.000Z",
        "likeCount": 0,
        "comment": "Hey man its Austin i just got registerd on youtube lol i stole your name and well kinda email lol see ya",
        "videoId": null,
        "authorThumbnail": "https://yt3.ggpht.com/-VbNaB5kmwMk/AAAAAAAAAAI/AAAAAAAAAAA/Eik8fjWQLoA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel1",
        "commentId": "Ugziv_Flf6CJo5cqyE14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-07-27T10:49:34.000Z",
        "likeCount": 0,
        "comment": "mi fate skifo!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-oIeshA7Kx4I/AAAAAAAAAAI/AAAAAAAAAAA/PyEivd8YlKY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mario Scaramuzzino",
        "commentId": "Ugz386VgKzLidSquf3l4AaABAg"
    },
    {
        "publishedAt": "2008-07-22T20:34:03.000Z",
        "likeCount": 0,
        "comment": "I love it, smooth flowing sticking hands.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-m4307ZkUaNU/AAAAAAAAAAI/AAAAAAAAAAA/dQmjSoxPEvo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Fong Sifu",
        "commentId": "UgyS-8ThHWxyUIgYHZ54AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-07-22T05:40:09.000Z",
        "likeCount": 0,
        "comment": "nice stuff.  Matt Damon used Eskrima techniques in the Bourne film series (remember the first movie where he kills the guy with a small pen)!",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-wEepp9X5aoc/AAAAAAAAAAI/AAAAAAAAAAA/PhF0ORkcUow/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jam Productions",
        "commentId": "Ugy5St-df4vreA-e2tR4AaABAg"
    },
    {
        "publishedAt": "2008-07-17T04:47:46.000Z",
        "likeCount": 1,
        "comment": "Silas awesome video. Watched it about six times in a row-Michael Shapcott",
        "videoId": "17iBWOBIakg",
        "authorThumbnail": "https://yt3.ggpht.com/-Jb0qHOEhGfg/AAAAAAAAAAI/AAAAAAAAAAA/EP97wIpSaYA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Michael Shapcott",
        "commentId": "Ugz8i0Uvn1vaPlwZh4l4AaABAg"
    },
    {
        "publishedAt": "2008-07-14T16:07:49.000Z",
        "likeCount": 0,
        "comment": "Pork is taboo for Muslims,\r\nBeef is unacceptable for Hinduists.\r\nIf you don&#39;t want to eat dog,\r\ndon&#39;t judge others who eat for it is human rights violation.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NVuxhx5dzyM/AAAAAAAAAAI/AAAAAAAAAAA/6Sa-YOkTO4U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "CelestialKindred",
        "commentId": "Ugwk_omDlPt9M4S0eh14AaABAg"
    },
    {
        "publishedAt": "2008-07-04T02:51:42.000Z",
        "likeCount": 0,
        "comment": "these people will eat anything aslong as it smells good.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-ivi3LX_dGuA/AAAAAAAAAAI/AAAAAAAAAAA/qTf5DO9v6mY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "moewishaw",
        "commentId": "UgwSJUGRNCVRgvGeTfh4AaABAg"
    },
    {
        "publishedAt": "2008-07-02T19:55:04.000Z",
        "likeCount": 0,
        "comment": "i trained the kombatan system to lakan ranking. it is more organized than many martial arts systems. the problem is that the actual training does not bridge to reality and relies on predetermined attack patterns in order to appear to work. notice the way the gm queues the attacker on where to hit him. you can watch other videos that will show the same thing. many people that practice this can fight, but it is not due to the system.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-JFSL_jH-RWA/AAAAAAAAAAI/AAAAAAAAAAA/8AjijJyNQwM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "awakenedstream",
        "commentId": "UgwHP8KelgFfOlx5w-l4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-06-24T03:57:01.000Z",
        "likeCount": 0,
        "comment": "if you hate chinese people dont buy everything thats &quot;made in china&quot; lol!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-WJcKdaArJBA/AAAAAAAAAAI/AAAAAAAAAAA/BL7DJrQ5W9o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "daniel tan",
        "commentId": "UgxbvMterga8A26u77x4AaABAg"
    },
    {
        "publishedAt": "2008-06-20T03:24:38.000Z",
        "likeCount": 0,
        "comment": "why would you lower your self and become a barbarian by eating dog, only savage societys with no soul eat dog, look at the countrys that eat a loyal friend like the dog, china and korea,,,, they have no respect for mans loyal friend,,,, ugly devils",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-TxkSBQ1rHHg/AAAAAAAAAAI/AAAAAAAAAAA/jH3SSmGEPh4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "AnimeTalk",
        "commentId": "UgxnT81AsLubLY-H-LB4AaABAg"
    },
    {
        "publishedAt": "2008-06-14T23:26:56.000Z",
        "likeCount": 0,
        "comment": "those sick bastards",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-OQs0mfCV-Z0/AAAAAAAAAAI/AAAAAAAAAAA/q9R4A9qqxH0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Marcos Carvallo",
        "commentId": "UgzOFdK-XjE8VD6hjPV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-06-10T21:06:53.000Z",
        "likeCount": 0,
        "comment": "dont like the sound effects... a good song with some sick guitar tunes would have been better!!\n\nyeya",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-nIyT_y73USc/AAAAAAAAAAI/AAAAAAAAAAA/zRJa5mb-mW8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "TrevaBoarder",
        "commentId": "UgyWN58SD0Jxe1ryxDJ4AaABAg"
    },
    {
        "publishedAt": "2008-06-10T04:15:30.000Z",
        "likeCount": 0,
        "comment": "Now I know why there are so many fucking deceases in China, SARS, and all the other fucking shit that the Chinese government does not want anyone to know about, these people better change for the better",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-hjtalPkI0g8/AAAAAAAAAAI/AAAAAAAAAAA/6oUlcVrvsXg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Castilliaisme",
        "commentId": "UgzQZcW9AF2lZbeioON4AaABAg"
    },
    {
        "publishedAt": "2008-06-04T17:26:00.000Z",
        "likeCount": 0,
        "comment": "the two on the outside look like the yuen kay sao method",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-vYm-pfQsN9M/AAAAAAAAAAI/AAAAAAAAAAA/B4Y050TiTH0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Christopher Carpousis",
        "commentId": "UgxVLMIrEuXwTVllEWd4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-05-26T21:27:46.000Z",
        "likeCount": 0,
        "comment": "Like the video. When was it shot?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-Jv4t1t6tA9U/AAAAAAAAAAI/AAAAAAAAAAA/4agGW6ZzI3o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "maccsta",
        "commentId": "UgwoZz_3vPZYnIuQ1Q14AaABAg"
    },
    {
        "publishedAt": "2008-05-25T04:39:43.000Z",
        "likeCount": 0,
        "comment": "last one was the best",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-VaOPGwQrCvQ/AAAAAAAAAAI/AAAAAAAAAAA/ewmdjetVf3w/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "DonutSalad",
        "commentId": "UgxBhnY-ARn9KFRS4kR4AaABAg"
    },
    {
        "publishedAt": "2008-05-12T17:36:34.000Z",
        "likeCount": 0,
        "comment": "&quot;They say there is a strong correlation between the way a country treats its animals and the way it treats its people. In the animal markets of China, some element of humanity seems to be missing, some dimension of sympathetic understanding capable of imagining pain, fear and suffering in others.&quot;",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-yZbC-VTKjtQ/AAAAAAAAAAI/AAAAAAAAAAA/Y63QHOUeo5k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "JKM12988",
        "commentId": "Ugzssp8vSlZi-C1pGcB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-05-04T21:54:14.000Z",
        "likeCount": 0,
        "comment": "theres some footage with some of Master Kwok&#39;s students training with him and EVERYBODY and their mother had a camera going!! I got to see the full video from my sifu, but one guy even put his extra camcorder filming on top of jong!!! It was pretty hilarious to see him do that",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgyRLRbfLzWhgwDniy94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-05-04T18:06:34.000Z",
        "likeCount": 0,
        "comment": "somebody told me that Ip Ching doesn&#39;t allow video taping of his lessons and such... HA!  check this out!!!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgzOw9wQ1VqQR5n7VVZ4AaABAg"
    },
    {
        "publishedAt": "2008-05-03T18:40:47.000Z",
        "likeCount": 0,
        "comment": "thats cold how u gonna eat a poodle",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-_NIu8SzLNDo/AAAAAAAAAAI/AAAAAAAAAAA/PAQaXw2rz5I/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "fozzyozzy106",
        "commentId": "Ugxu1AnWd4HNx2Nr5at4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-05-02T22:22:35.000Z",
        "likeCount": 0,
        "comment": "thanks for sharing, very good sample of some traditional wushu well put into practice!",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-Mdc9FrBfy9A/AAAAAAAAAAI/AAAAAAAAAAA/eWr5hE_z6oI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "masteringwushu",
        "commentId": "UgwdBvzVDek0Z3Bkkqp4AaABAg"
    },
    {
        "publishedAt": "2008-04-19T20:41:41.000Z",
        "likeCount": 0,
        "comment": "That was pretty sweet keep it up and make another one",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-SKjiQVljKcg/AAAAAAAAAAI/AAAAAAAAAAA/ETQF15eSQak/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Sean Neary",
        "commentId": "UgyKVBfeQg6rVywq2vZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-04-17T10:04:37.000Z",
        "likeCount": 0,
        "comment": "peop where do we draw the line on what we eat?is it down to how intelligent an animal is?elephants that are being slaughtered be the thousand every year mourn their dead.they will return to  places to where the ancestors several generations ago  died or were killed.what does it take for an animal to have rights?add to the food chain the needless crulety to animals worldwide and you must ask yourself what gives us the right to desacreate this beautiful planet.help someone this week .thank you.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-3xyHVnb2DAk/AAAAAAAAAAI/AAAAAAAAAAA/HUaHM4ZdDLc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "PAUL INCE INCAXX",
        "commentId": "Ugxrbl9lkqc1tUEAd7d4AaABAg"
    },
    {
        "publishedAt": "2008-04-13T01:21:10.000Z",
        "likeCount": 0,
        "comment": "He is certainly amazing.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxwMjq4J3lQVBRfBw54AaABAg"
    },
    {
        "publishedAt": "2008-04-07T17:40:42.000Z",
        "likeCount": 0,
        "comment": "nice sound effects",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-99QIlAyAvKc/AAAAAAAAAAI/AAAAAAAAAAA/WDXl9niga38/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "EyeTrack",
        "commentId": "Ugwwvdk1q9q1wsBErAt4AaABAg"
    },
    {
        "publishedAt": "2008-04-04T22:31:08.000Z",
        "likeCount": 0,
        "comment": "these people are rat terds",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-tpI-3mhd_nE/AAAAAAAAAAI/AAAAAAAAAAA/JrFKG3tBA9Q/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Timmy Eldridge",
        "commentId": "Ugxh-PVlIeRgzsEu8p54AaABAg"
    },
    {
        "publishedAt": "2008-04-03T17:19:04.000Z",
        "likeCount": 0,
        "comment": "No, SiFu",
        "videoId": "lSsDsr4WIYY",
        "authorThumbnail": "https://yt3.ggpht.com/-FsOI97hMRNQ/AAAAAAAAAAI/AAAAAAAAAAA/tGr-yxrsXpA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Andree Assing",
        "commentId": "UgyRQUos8fG0mVgBBqt4AaABAg"
    },
    {
        "publishedAt": "2008-03-25T06:23:18.000Z",
        "likeCount": 0,
        "comment": "for the benifit of the person who posted the video and those wo don&#39;t speak or understand filipino my reply to lopoex states:\n\nyes my friend, Master Presas&#39; art is effective in practical combat. it would be better if you would actually meet him to understand more the essence of the Kombatan style. His gym is located at Globe Building Quiapo Philippines.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-wAm-alnuRck/AAAAAAAAAAI/AAAAAAAAAAA/GpKNVJwW304/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Roel Robles",
        "commentId": "Ugy1TUWN8ARnMiHFmcJ4AaABAg"
    },
    {
        "publishedAt": "2008-03-25T06:19:10.000Z",
        "likeCount": 0,
        "comment": "oo pards effective ang art ni master presas sa mga practical na labanan mas maganda kung hanapin mo siya para mas maunawaan mo yung kagandahan ng kombatan, ang gym niya matatagpuan sa globe building quiapo.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-wAm-alnuRck/AAAAAAAAAAI/AAAAAAAAAAA/GpKNVJwW304/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Roel Robles",
        "commentId": "Ugxrz3aac71ssbiNIEV4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-03-22T23:52:59.000Z",
        "likeCount": 0,
        "comment": "Thanks!",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugz1Hr1QWYuBLrta1bR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-03-19T00:23:34.000Z",
        "likeCount": 0,
        "comment": "If you Noticed. Non of em are wearing helmets. Well, no helmets makes you look pro...Right.?",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-j-YSm4j3CLI/AAAAAAAAAAI/AAAAAAAAAAA/TUwLGod105k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Bohdan Kotik",
        "commentId": "UgyCd_N-hIXAcBNsmxV4AaABAg"
    },
    {
        "publishedAt": "2008-03-13T20:51:22.000Z",
        "likeCount": 0,
        "comment": "this is such a cool vid those sound affects are amazing i wanna see u in the future doing more",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-lRNp5j2myKA/AAAAAAAAAAI/AAAAAAAAAAA/GUl0yaM9bag/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Banny132",
        "commentId": "UgyvLOLW-ogg3-oKA8Z4AaABAg"
    },
    {
        "publishedAt": "2008-03-11T19:42:22.000Z",
        "likeCount": 0,
        "comment": "poor.",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-tqAA7K0Wt5E/AAAAAAAAAAI/AAAAAAAAAAA/8_Zgj03JB60/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dan Chalmers",
        "commentId": "UgyA6oDnsZTWH5A-SzF4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-03-04T22:15:57.000Z",
        "likeCount": 0,
        "comment": "Can anyone translate please? All I understood was &quot;The Problem , tangible your den sock greed INEEXPECT your na iyun. PERO toward real HOSTILE MABILIS.Ngayon the interrogation usable your ba iyung mga intentional your?&quot; I think I was just insulted but I am not sure",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxfgagIcwMx9w48C-V4AaABAg"
    },
    {
        "publishedAt": "2008-03-03T18:34:49.000Z",
        "likeCount": 0,
        "comment": "Yes this is Mainland china. The schools were from all over southern china from guandong to macau",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwLEZFU6wDF31KLS-t4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-03-01T14:12:54.000Z",
        "likeCount": 0,
        "comment": "Now that is just a silly comment. Why are dogs considered so important? Because they are affectionate and &quot;companions&quot;? \nThey are a source of food",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-YZB6VyErzuY/AAAAAAAAAAI/AAAAAAAAAAA/honPCfkHAdo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "PeterMMM",
        "commentId": "UgwrC3iPA_vAIYnQ_DJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-03-01T05:59:08.000Z",
        "likeCount": 0,
        "comment": "very nice rolling indeed. being soft with a partner which is hard really give the emphasis of ying and yang theory. is this from mainland china?",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-84uvLQMZL9Q/AAAAAAAAAAI/AAAAAAAAAAA/ByRmgTpV4rA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "WCpercival",
        "commentId": "UgxcRrmvupoOCNIf81x4AaABAg"
    },
    {
        "publishedAt": "2008-02-28T00:49:12.000Z",
        "likeCount": 0,
        "comment": "...no coment",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-52vysmabrSw/AAAAAAAAAAI/AAAAAAAAAAA/oNhyr41KcxE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "HotBrittM8",
        "commentId": "UgwKGu3lXa93EHJFgLt4AaABAg"
    },
    {
        "publishedAt": "2008-02-24T18:42:14.000Z",
        "likeCount": 0,
        "comment": "jeez man you are good. where do you get all of this footage",
        "videoId": "LEAexG6YkX4",
        "authorThumbnail": "https://yt3.ggpht.com/-wCqGtAYlTg8/AAAAAAAAAAI/AAAAAAAAAAA/SinV0qU4KGE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tylerjoe51",
        "commentId": "UgwB9dBNTJKf8WdJNyd4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-02-24T04:13:06.000Z",
        "likeCount": 0,
        "comment": "whered ya get the sound effects?",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-QLs-5inChPQ/AAAAAAAAAAI/AAAAAAAAAAA/RMvmjz5zM10/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Nick Ferraro",
        "commentId": "UgwQ3mO6DGiZJNdmAJ54AaABAg"
    },
    {
        "publishedAt": "2008-02-23T19:57:20.000Z",
        "likeCount": 0,
        "comment": "nice timing with the sound affects from some war game. that takes skill",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-wCqGtAYlTg8/AAAAAAAAAAI/AAAAAAAAAAA/SinV0qU4KGE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tylerjoe51",
        "commentId": "UgwLAXhuJmsW5Di_FYZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-02-23T01:21:11.000Z",
        "likeCount": 0,
        "comment": "The one doing Chi Sau is Yip Ching, son of Yip Man, and also the old fellow at the end is one of the sons of Yip Man. According to Moy Yat, Yip Ching was one of the best Chi Sau practitioners and has a great deal of Wing Chun knowledge. In my opinion, TRUE!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-7l0NYEPpCPQ/AAAAAAAAAAI/AAAAAAAAAAA/YE_qX6F5VfM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "wingchunone",
        "commentId": "Ugx6yXPbDTkAWRU_5xZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-02-19T02:32:08.000Z",
        "likeCount": 0,
        "comment": "yeah, what about cows, pigs, chicken... the same, isnt it?\nyou have to admit it...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-iyQSpetzLZw/AAAAAAAAAAI/AAAAAAAAAAA/joAp_FtIhsg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Foodfast123",
        "commentId": "Ugx5P1cAQHIyfBau1kJ4AaABAg"
    },
    {
        "publishedAt": "2008-02-17T16:43:59.000Z",
        "likeCount": 0,
        "comment": "VIDEOS on YOUTUBE :\r\n\r\nv=ArUxgB8oy2g\r\nv=rz7UNxnOI3M\r\nv=-J0ypnRvkpw",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-N85FPOZTnu0/AAAAAAAAAAI/AAAAAAAAAAA/JIYBxYmb3-c/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Quairiat George",
        "commentId": "UgzhIO23XkuqadYBcDR4AaABAg"
    },
    {
        "publishedAt": "2008-02-16T20:11:04.000Z",
        "likeCount": 0,
        "comment": "Superb. As good as I&#39;ve seen for the filipino arts on youtube. And good, clear, extended footage too. Thanks for it.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-ict6E1T4mBc/AAAAAAAAAAI/AAAAAAAAAAA/GlxjQ6Fp6fU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike",
        "commentId": "UgwFnck_hbHL_fqFHjp4AaABAg"
    },
    {
        "publishedAt": "2008-02-15T08:48:43.000Z",
        "likeCount": 0,
        "comment": "interesting... thanks 4 sharing us the vids...",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-4hA9lrlWjts/AAAAAAAAAAI/AAAAAAAAAAA/B4Z-YhUrHVA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Wing Chun Lifeforce",
        "commentId": "UgwD6fE95ZYPvpdkXmV4AaABAg"
    },
    {
        "publishedAt": "2008-02-13T21:42:47.000Z",
        "likeCount": 0,
        "comment": "I think Wing Chun is a very good self defense for women who try to fight their way away from sex predetors.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-GQtVAziEt_M/AAAAAAAAAAI/AAAAAAAAAAA/g8Mtwxh-cko/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lar M",
        "commentId": "UgxxPkU3baTq9suuDTp4AaABAg"
    },
    {
        "publishedAt": "2008-02-12T17:56:00.000Z",
        "likeCount": 0,
        "comment": "thanks 4 sharing your experience with us...",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-4hA9lrlWjts/AAAAAAAAAAI/AAAAAAAAAAA/B4Z-YhUrHVA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Wing Chun Lifeforce",
        "commentId": "UgyEk2m8uqMH1huSGDx4AaABAg"
    },
    {
        "publishedAt": "2008-02-12T17:25:44.000Z",
        "likeCount": 0,
        "comment": "hahaha ur quick...",
        "videoId": "PI4zVUPluXU",
        "authorThumbnail": "https://yt3.ggpht.com/-4hA9lrlWjts/AAAAAAAAAAI/AAAAAAAAAAA/B4Z-YhUrHVA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Wing Chun Lifeforce",
        "commentId": "UgytAJ5V2XhDoxB91lZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-02-12T09:58:14.000Z",
        "likeCount": 0,
        "comment": "there is a new avalange risk...the name is............ PEAPLE!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-vTBNOye_ozo/AAAAAAAAAAI/AAAAAAAAAAA/ojbpWPY9kRI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mystic god creation",
        "commentId": "Ugxhk3DM-Y902IxxEB14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-02-11T19:46:25.000Z",
        "likeCount": 0,
        "comment": "Just discovered your wing chun vids, and I&#39;m now enjoying ploughing through them all! Many, many thanks for this. I love seeing wing chun as practised in its homeland. I hope you make many more!\r\n\r\nCheers,\r\n\r\nScott.",
        "videoId": null,
        "authorThumbnail": "https://yt3.ggpht.com/-G_3KjDyZzA8/AAAAAAAAAAI/AAAAAAAAAAA/DH2H3GyY0wA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "sc0jack",
        "commentId": "Ugxx3ci_o6NXpY4GJn14AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-01-31T14:25:08.000Z",
        "likeCount": 0,
        "comment": "It&#39;s just meat so shut the FUCK UP &amp; eat it!!!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-hnrElvcpmGo/AAAAAAAAAAI/AAAAAAAAAAA/Qd-V4LepR9Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "givingheadtothedead",
        "commentId": "UgywzIs_AywQEwKbBn54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-30T16:56:15.000Z",
        "likeCount": 0,
        "comment": "ummm theres a difference between gassing them and torturing them needlessly jackass! You think China would even consider doing something humane and spending time and money on gas chambers for animals when they have so much fun torturing them?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-arz1PRXeGy4/AAAAAAAAAAI/AAAAAAAAAAA/zGzup6KI4Yc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul R.",
        "commentId": "UgxrA9pUVwSHL_3Sbbh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2008-01-27T05:18:59.000Z",
        "likeCount": 0,
        "comment": "That is an abomination..prepare to burn in hell!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-F52gvwXKNcs/AAAAAAAAAAI/AAAAAAAAAAA/hYH_rQUAq9c/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "The Thrill",
        "commentId": "UgynVCrwNXOsxluil5d4AaABAg"
    },
    {
        "publishedAt": "2008-01-25T05:49:00.000Z",
        "likeCount": 0,
        "comment": "that one guys helmet shattered",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-MnRsYoeIrpM/AAAAAAAAAAI/AAAAAAAAAAA/fpFkFKkZcec/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cheesesteak999",
        "commentId": "Ugwg_FaCQWoC8UqSaiJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-22T21:57:56.000Z",
        "likeCount": 0,
        "comment": "In Sweden it&#39;s allowed to slaughter &quot;pet&quot; animals, such as dogs. As long as you don&#39;t sell it and only uses the meat for own consumption. And I don&#39;t get it, sure dogs are our friends and I own 2 myself, but I can&#39;t see why eating a dog is more diffrent than eating cowflesh? I don&#39;t eat meat, just useless deaths.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-PJPRVJ1pp8s/AAAAAAAAAAI/AAAAAAAAAAA/1UYO_ksOg5k/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Brbh Prbh",
        "commentId": "UgwAWhIqP5_KaK5ujBx4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-15T23:36:56.000Z",
        "likeCount": 0,
        "comment": "wow im sry but u ppl are very rude and r very much racist...and &quot;pavlinamil&quot; did u see every f*ing chinese person on the earth?? i dont think so so shut ur f*ing mouth...and &quot;ilovecocacola&quot; dont use chink or maybe next time to clear things up start ur comments by sayin-&gt;&gt; hi imma racist?",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-c5xiLALTzrw/AAAAAAAAAAI/AAAAAAAAAAA/9lcJw8zPI_o/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cutieprincess431",
        "commentId": "Ugxwp15qj4D-mdqEivV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-14T00:43:53.000Z",
        "likeCount": 0,
        "comment": "do u rhink??... he looked to tense to me.... sure he had alot of control...all 3 seemed to want to impresss with speed and power... SLT concentrates more on structure and rotation of joints. thats my understanding..it all looked rather impressive though...what do you think?",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-XJ_eo4vvrs8/AAAAAAAAAAI/AAAAAAAAAAA/bzUo1f86NCc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jason",
        "commentId": "UgzmGTiorxn9j2zVFAl4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-11T15:57:49.000Z",
        "likeCount": 0,
        "comment": "at the 2;33 mark, is that sifu Chris Chan in the background?????",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-fx_xL54_0lI/AAAAAAAAAAI/AAAAAAAAAAA/zUShMt6eRQY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Idontcare_626",
        "commentId": "Ugysv-P4-rvkHZBdJXx4AaABAg"
    },
    {
        "publishedAt": "2008-01-10T10:08:48.000Z",
        "likeCount": 0,
        "comment": "ouch... i&#39;m definitely wearing my helmet this year :D",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-F54a9b4kRvM/AAAAAAAAAAI/AAAAAAAAAAA/1La63DYRfg8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jan Willem",
        "commentId": "UgzY6MlpqOQ6tK9ZGix4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2008-01-05T06:34:38.000Z",
        "likeCount": 0,
        "comment": "They start with full power, their Chi Sau somehow like dog fighting, and their SiFu ask me&quot; you think at real fighting is more like chi sau or dog fighting&quot;?\r\nI did Chi Sau with them, they use a lot of power, they could even grap you with 2 hands tide, is one of a kind, and open my sign.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-8feZWSy4ukE/AAAAAAAAAAI/AAAAAAAAAAA/ehpRCnpj-mM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Edward Louk",
        "commentId": "UgwabcsNl-sfX9JxKjF4AaABAg"
    },
    {
        "publishedAt": "2007-12-26T06:23:58.000Z",
        "likeCount": 0,
        "comment": "I thought the sounds were for Mission impossible on 64 lol... I swear if u die it sounds like that... been awhile since the 64 :P",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-HMLH9JxcBBg/AAAAAAAAAAI/AAAAAAAAAAA/dbDpYYAvQF8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "I hate everyone",
        "commentId": "UgyQsZHzSq2fUIDKObx4AaABAg"
    },
    {
        "publishedAt": "2007-12-24T14:11:21.000Z",
        "likeCount": 0,
        "comment": "Kinda fcked up coz like dogs Cmon eating freakin dogs.. sad for dogs dieing for ppl to fckin eat...",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-B-BbQJH_hKE/AAAAAAAAAAI/AAAAAAAAAAA/lMu1n8NjOxw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mirzairl",
        "commentId": "UgyOFtiN7ReLRWGJaxN4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-12-16T21:39:10.000Z",
        "likeCount": 0,
        "comment": "ROFLCOPTER DOWN!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-1b18KdV8iqE/AAAAAAAAAAI/AAAAAAAAAAA/GB1Q6utYocA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "luk far",
        "commentId": "UgwgBgEO94YY2UZ3dAB4AaABAg"
    },
    {
        "publishedAt": "2007-12-05T02:51:10.000Z",
        "likeCount": 0,
        "comment": "LOL, hella resturants in china sell dog, me and my dad we at a resturant with his friends, then they ordered dog meat.. O.o",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-4Fa8tLHyOQo/AAAAAAAAAAI/AAAAAAAAAAA/VAV0cuYqiqI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David",
        "commentId": "UgxUjzWJLZr6bc5-qGN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-11-19T16:23:35.000Z",
        "likeCount": 0,
        "comment": "lol wow. Did anyone understand that comment? What is that about Korea? So random.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgztZbp_UrRgYgxx34Z4AaABAg"
    },
    {
        "publishedAt": "2007-11-18T18:52:06.000Z",
        "likeCount": 0,
        "comment": "Most of you are beyond funny. This continues to be my least watched yet most commented on Vid. \nThings to remember:\n\n1. Dog is NOT cheap. \n\n2. Not everyone in China eats it.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugy0tYmpOehHRlYSebd4AaABAg"
    },
    {
        "publishedAt": "2007-11-12T20:42:43.000Z",
        "likeCount": 0,
        "comment": "That guy on the left had very powerful, fast, well performed form.  Definitely the most practiced guy of the three.",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-ZWGZ6bdr1lc/AAAAAAAAAAI/AAAAAAAAAAA/MJznBI5ZfCs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "John Davenport",
        "commentId": "Ugx3kpeZk7ZGvxv4RRN4AaABAg"
    },
    {
        "publishedAt": "2007-11-05T20:50:02.000Z",
        "likeCount": 0,
        "comment": "P.S. -- The guy in white is Andres &quot;Andy&quot; Castillo Sr.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-Xff9LZgoBmA/AAAAAAAAAAI/AAAAAAAAAAA/vLcc3zbV0Sg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "UniversalWarrior",
        "commentId": "Ugy-ZYapGEWt_5XAPjJ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-11-05T20:48:01.000Z",
        "likeCount": 0,
        "comment": "Hi, all. This is Alex France and that was OUR (Alex Ercia&#39;s, Dave Ducay&#39;s, Ed Gracia&#39;s, Ed Castillo&#39;s, Mel Orpilla&#39;s, Vince Uttley&#39;s, and everyone else&#39;s Philippine Martial Arts Academy &amp; Cultural Center! Anyone and everyone could film whatever they wanted at PMAACC as long as it was okay with the subject. Great to see these old clips!  --Alex(ander Bautista Bayot France)",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-Xff9LZgoBmA/AAAAAAAAAAI/AAAAAAAAAAA/vLcc3zbV0Sg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "UniversalWarrior",
        "commentId": "UgwN0GsPAh4czHLnToR4AaABAg"
    },
    {
        "publishedAt": "2007-11-03T21:02:20.000Z",
        "likeCount": 0,
        "comment": "It is unfortunate that many of the chi sao matches here ended in actual fighting. (no, I will not post those vids, it is not chi sao and I do not want to go to jail next time I am in China) Please bear that in mind when giving your opinion on the form shown. Just as in physics, the act of observation does change the event. \nThanks again for watching.\nPh8",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxjuqLXuVOfmDJvzAh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-11-03T21:01:47.000Z",
        "likeCount": 0,
        "comment": "Thanks for watching my videos! I would like to point out one thing I think has been overlooked in all of the posted criticism. When I put my big Sci-fi looking XL-1S camera on my shoulder, things change. People get much more aggressive and try to show off. I have seen the most fluid and relaxed Sifus turn pushy and aggressive just by my filming of them. (continued on next comment)",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugw8TBoYdrw4DCKibZZ4AaABAg"
    },
    {
        "publishedAt": "2007-11-02T17:34:06.000Z",
        "likeCount": 0,
        "comment": "now all of you can understand how vegans feels when you EAT MEAT PERIOD.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-Z0L99zteDMU/AAAAAAAAAAI/AAAAAAAAAAA/ttBVHDZ_qrY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "purebloodgoodness",
        "commentId": "UgyW8ge9K3LK4W0_mhl4AaABAg"
    },
    {
        "publishedAt": "2007-10-24T15:46:01.000Z",
        "likeCount": 0,
        "comment": "Not well done , in my opinion.",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-MVHZZM0Z6Eg/AAAAAAAAAAI/AAAAAAAAAAA/1-yO2W39y-s/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mottahead",
        "commentId": "Ugz2Vom-KBaKN76vKEF4AaABAg"
    },
    {
        "publishedAt": "2007-10-19T14:47:05.000Z",
        "likeCount": 0,
        "comment": "I shot this back in 1995 I think. It was the closing day for the cultural center in Vallejo CA.  I do have more footage including the Hula dancers and others. If people are  interested then I can post more.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgzmyfF0oi79Jj6Cx6V4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-10-19T04:20:51.000Z",
        "likeCount": 0,
        "comment": "Master Alex,\r\n\r\nDo you remember what year this was? Also, it is a pleasure watching GM Erning. \r\n\r\nAlso, maybe you can try to get a copy of this video since you &amp; Kuya Lito are in it. I know your personally video copy of this seminar is kinda grainy in quality.\r\n\r\nJust a thought.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-FED2_u4QR7A/AAAAAAAAAAI/AAAAAAAAAAA/7Mskt_hCz3U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tabimina BalintawakUSA",
        "commentId": "UgymwiMDie4ieZclWuR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-10-17T18:41:46.000Z",
        "likeCount": 0,
        "comment": "hey! thats Master Alex Ercia (red pants &amp; white shirt)&amp; GM Lito Concepcion (jeans &amp; black sweater) &quot;dummying&quot; for Great GM Erning Presas. I&#39;m not 100% sure if the guy in white pants &amp; shirt is Master Alex France.\r\n\r\nthanks for posting. I&#39;ll send this video link to Master Alex Ercia.",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-FED2_u4QR7A/AAAAAAAAAAI/AAAAAAAAAAA/7Mskt_hCz3U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tabimina BalintawakUSA",
        "commentId": "Ugz1YkdAs49A2YuLDzt4AaABAg"
    },
    {
        "publishedAt": "2007-10-15T01:04:35.000Z",
        "likeCount": 0,
        "comment": "word on the streets is the father use to glide around the room redirecting his students. Lots of open ribs everywhere. anybody down for a texas b.b.q.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "UgzAUvyV0f4yWVhmFjF4AaABAg"
    },
    {
        "publishedAt": "2007-10-05T18:11:32.000Z",
        "likeCount": 0,
        "comment": "jaywesty7: That&#39;s because chi sao is not a fighting application, it&#39;s a training application, it&#39;s meant to develop nerve sensitivity in arms and legs so that in a fight every moment is executed without involving executive decision making, i.e. your brain, because the brain is just to slow cmopared to your nervous system.\nThe only real fighting practice in Wing Tsun is done with loads and loads of protective gear usually, otherwise someone is not walking home with all bones intact.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-yCBrwmnHfz4/AAAAAAAAAAI/AAAAAAAAAAA/f6HNNcEqStg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lingam2",
        "commentId": "Ugyb7cfUrG_TKmDr_u94AaABAg"
    },
    {
        "publishedAt": "2007-10-05T18:10:22.000Z",
        "likeCount": 0,
        "comment": "jaywesty7: That&#39;s because chi sao is not a fighting application, it&#39;s a training application, it&#39;s meant to develop nerve sensitivity in arms and legs so that in a fight every moment is executed without involving executive decision making, i.e. your brain, because the brain is just to slow cmopared to your nervous system.\nThe only real fighting practice in Wing Tsun is done with loads and loads of protective gear usually, otherwise someone is not walking home with all bones intact.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-yCBrwmnHfz4/AAAAAAAAAAI/AAAAAAAAAAA/f6HNNcEqStg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lingam2",
        "commentId": "UgxwhkM48tVTT2hpmFx4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-10-01T10:30:27.000Z",
        "likeCount": 0,
        "comment": "CHEERS BUDDY!! I was startin&#39; ta think i was on me own on this one!",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-QUdiCSrPpO4/AAAAAAAAAAI/AAAAAAAAAAA/-4D6Aa6Wmzc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Isa ahmad",
        "commentId": "UgyfalDtrhsrAVLMpuB4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-09-29T03:30:33.000Z",
        "likeCount": 0,
        "comment": "It is still amazing to watch Ernesto Presas move and teach!",
        "videoId": "t4EOIs7IYf0",
        "authorThumbnail": "https://yt3.ggpht.com/-luLZz0JgZUM/AAAAAAAAAAI/AAAAAAAAAAA/KnOqFi6zBuU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "drklight2000",
        "commentId": "UgzTAW43-B3lPcM-7JJ4AaABAg"
    },
    {
        "publishedAt": "2007-09-19T18:00:22.000Z",
        "likeCount": 0,
        "comment": "i&#39;ve been studying wing chun in uk. grandmaster ip chun is my sigong (sifu&#39;s sifu). i have to agree with those of you who have realised that this standard of wing chun is very poor. firstly they are way too tense which is not pure wing, they rely on strength instead of positioning, energy, technique &amp; reaction (subtle). this is not yip man&#39;s wing chun. i hate to criticize others but for anyone looking for quality wing chun, go to hong kong. just my two cents.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-QUdiCSrPpO4/AAAAAAAAAAI/AAAAAAAAAAA/-4D6Aa6Wmzc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Isa ahmad",
        "commentId": "UgyBQ9jnQrjDjttlyZl4AaABAg"
    },
    {
        "publishedAt": "2007-09-19T17:49:32.000Z",
        "likeCount": 0,
        "comment": "if you live in hong kong i would train at the ving tsun athletic association with grandmaster ip chun. he is my sigong. i live in england. that standard in mainland china is very poor. its not technically correct.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-QUdiCSrPpO4/AAAAAAAAAAI/AAAAAAAAAAA/-4D6Aa6Wmzc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Isa ahmad",
        "commentId": "UgxDAMZqc3ZzUE-zdWx4AaABAg"
    },
    {
        "publishedAt": "2007-09-15T16:05:10.000Z",
        "likeCount": 0,
        "comment": "very very good",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-q_7XsQuhf0Q/AAAAAAAAAAI/AAAAAAAAAAA/-z6PCClA0GI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Know Won",
        "commentId": "Ugy8yYJVE-ABYvcUZS14AaABAg"
    },
    {
        "publishedAt": "2007-09-08T18:28:09.000Z",
        "likeCount": 0,
        "comment": "wow i can&#39;t believe u r sacred to recieve a post from me. u seem to be running away. i guess u r the caged animal.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "Ugz-TczbCRbSwh1vReV4AaABAg"
    },
    {
        "publishedAt": "2007-09-08T18:26:21.000Z",
        "likeCount": 0,
        "comment": "wow i can&#39;t believe u r scared to recieve a post from me. you seem to be running away. i guess u r the caged animal",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "UgzbOsfDgElphzD1vwR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-09-08T14:29:56.000Z",
        "likeCount": 0,
        "comment": "Have you ever crossed trained  muay thai,jiujitsu/judo aikido  , boxer. how about another wing chun person from a different lineage. or been in a real street fight? Because god forbid and start throwing those wild techniques you are going to get hurt really bad. the chun was made to close the gap. the circle on these fellows are very wide. If they are masters of this art then there circle should be small. and this will help with forward energy. when proper footwork and rooting is applied.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "Ugye6ySj5g4J4B8DcP54AaABAg"
    },
    {
        "publishedAt": "2007-09-08T14:11:30.000Z",
        "likeCount": 0,
        "comment": "the form is ok, but his shifting is weak. Missing power/energy. It will get him in trouble in a fight. and now it is time for a fling",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "UgyQwUaRwDsn9kwsG614AaABAg"
    },
    {
        "publishedAt": "2007-09-08T02:24:39.000Z",
        "likeCount": 0,
        "comment": "sorry to tell you young fellow but you don&#39;t know me or my experience in this art. but I have touched hands with wing chun people from different family lines. I have fought mma fighters,muay thai,jiujitsu,karate,and boxers. Plus i was a cop and a bouncer in nyc. so ph8tel. do your homework. who have you trained with yourself. hahaha",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "UgxOs3lMVyAJvWZhcwh4AaABAg"
    },
    {
        "publishedAt": "2007-09-02T16:48:56.000Z",
        "likeCount": 0,
        "comment": "this is the worse for the birthplace of this great combat art. floating elbows crappy footwork. actually no footwork. all long arm techniques no close qurater movements. the old guy w/the keys is fast but I would run right through him like a caged animal",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-bY18Eh5WD1g/AAAAAAAAAAI/AAAAAAAAAAA/8TCTlukg7ws/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dam Pham",
        "commentId": "UgxZ3cxrBhxv9dzQndN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-09-01T07:40:58.000Z",
        "likeCount": 0,
        "comment": "is that yip mans son at the end? the really old guy?",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-XlHamgLqY-Y/AAAAAAAAAAI/AAAAAAAAAAA/w7oZD9jmgk0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "heckald",
        "commentId": "Ugyn_bISXYpEvYFpuBR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-09-01T04:54:36.000Z",
        "likeCount": 0,
        "comment": "What? No Bong Sao?",
        "videoId": "nxRR3CBRbVM",
        "authorThumbnail": "https://yt3.ggpht.com/-pxHidBEw6B0/AAAAAAAAAAI/AAAAAAAAAAA/iNZupi2hBIQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "narayanr",
        "commentId": "UgySMw_9UmIjHBpfSdZ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-08-25T03:43:53.000Z",
        "likeCount": 0,
        "comment": "Classic, simply classic;...well, I&#39;d say, you know?",
        "videoId": "lSsDsr4WIYY",
        "authorThumbnail": "https://yt3.ggpht.com/-pxHidBEw6B0/AAAAAAAAAAI/AAAAAAAAAAA/iNZupi2hBIQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "narayanr",
        "commentId": "UgzBYQzUv9QrwmSzj9d4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-08-24T23:52:54.000Z",
        "likeCount": 0,
        "comment": "I wish I&#39;d been inside this room! Very high skill I can only aspire to. Nice one Ching.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-tGZ7fsqZiSw/AAAAAAAAAAI/AAAAAAAAAAA/XBUoJBPMbEc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Paul Park",
        "commentId": "UgzLGDJraIQ0RS0X3Bp4AaABAg"
    },
    {
        "publishedAt": "2007-08-23T22:04:47.000Z",
        "likeCount": 0,
        "comment": "these guys are greehorns",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-x819WRvWK3w/AAAAAAAAAAI/AAAAAAAAAAA/RYbiqES2E_4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Michael Mc Ilwrath",
        "commentId": "UgzMtzTJTnGvyDApsxJ4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-08-19T10:33:43.000Z",
        "likeCount": 0,
        "comment": "cool what do a dirboard cost?",
        "videoId": "FvqTcm4hC6o",
        "authorThumbnail": "https://yt3.ggpht.com/-UbS4IGatVVw/AAAAAAAAAAI/AAAAAAAAAAA/9lTGW-kwueI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "NobodyCares",
        "commentId": "UgyYbzfQ0He6wmugb9l4AaABAg"
    },
    {
        "publishedAt": "2007-08-17T03:18:23.000Z",
        "likeCount": 0,
        "comment": "It would be nice if they actually did hung ga.",
        "videoId": "QfAQkufmjuA",
        "authorThumbnail": "https://yt3.ggpht.com/-CfNHngUhQ2s/AAAAAAAAAAI/AAAAAAAAAAA/x_nmZlp_bTA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "cfG21",
        "commentId": "UgzePUjTKFSq82zgPzJ4AaABAg"
    },
    {
        "publishedAt": "2007-08-16T22:29:32.000Z",
        "likeCount": 0,
        "comment": "I never offer my opinion on the styles in any video I shoot. I show all of the WC that I come across. I have been in  Academies across the world and only want to share what I have seen. I posted that the man in white &quot;called himself a sifu&quot; because we were not introduced and what I heard was second hand. \n\nNow about the &quot;armchair critics&quot;... Welcome to the internet, they are everywhere. If you read every posting there are some relevant points made throughout.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgzZEaYPEIrRG5Rdj_54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-08-16T21:14:03.000Z",
        "likeCount": 0,
        "comment": "I am proud to be a student of the Sifu from this clip. He has been training for over 20 years and is far from being as bad as people like to make out. The problem with posting these videos is that there are far too many &quot;arm chair critics&quot; who would rather critise other people then get on with their own training. Besides you posted this video so why would you do that if it was so bad?",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-9uEegUhYXbA/AAAAAAAAAAI/AAAAAAAAAAA/XhM7WzMbVNo/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "danjh1980",
        "commentId": "UgzDodjb3BMLOBAGQZh4AaABAg"
    },
    {
        "publishedAt": "2007-08-11T23:46:49.000Z",
        "likeCount": 0,
        "comment": "some of u ppl r stupid wats wrong with eatin diff animals o so u just kill cows pigs fish and chicken for meat wat if u were a chicken, cow , fish, or pig would u like to be the only one eaten",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-yrEPL7BXNIQ/AAAAAAAAAAI/AAAAAAAAAAA/hBvETbByzjA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "celina ho",
        "commentId": "Ugz6ewWkAFCnK7ZIs4F4AaABAg"
    },
    {
        "publishedAt": "2007-08-09T09:45:58.000Z",
        "likeCount": 0,
        "comment": "This is inside Huang Fei Hong Museum",
        "videoId": "QfAQkufmjuA",
        "authorThumbnail": "https://yt3.ggpht.com/-7PuPvI2s0TU/AAAAAAAAAAI/AAAAAAAAAAA/bNg7vKZ3oyY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dion Scholten",
        "commentId": "UgyVO2ZDgG-wDj1wEbd4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-08-08T12:29:30.000Z",
        "likeCount": 0,
        "comment": "cool clip!!\ni was there in june this year, going back in october!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-TebwmnS_qLY/AAAAAAAAAAI/AAAAAAAAAAA/6kRzhYi1Xmg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "lbu",
        "commentId": "UgzyVvb9Lx_5CuD5qpl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-07-24T14:08:25.000Z",
        "likeCount": 0,
        "comment": "the old guy at the end was friknn fast!!!",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-XlHamgLqY-Y/AAAAAAAAAAI/AAAAAAAAAAA/w7oZD9jmgk0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "heckald",
        "commentId": "UgyoZyOCeZSJb067EJ94AaABAg"
    },
    {
        "publishedAt": "2007-07-23T07:58:51.000Z",
        "likeCount": 0,
        "comment": "too stiff loosen up they looked like they were forcing eachother",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-XlHamgLqY-Y/AAAAAAAAAAI/AAAAAAAAAAA/w7oZD9jmgk0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "heckald",
        "commentId": "Ugy_3oThOwi_51ILnTp4AaABAg"
    },
    {
        "publishedAt": "2007-07-16T21:59:05.000Z",
        "likeCount": 0,
        "comment": "I shot them all myself in Fat San PRC",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxCaUujki-4_ff8Fb14AaABAg"
    },
    {
        "publishedAt": "2007-07-06T02:50:36.000Z",
        "likeCount": 0,
        "comment": "White people deny that racisms exists. But for all the non-white people you know racisms is everywhere. All the blacks, spanish, asians and basically all the non-white race knows not to trust the white man. We put up with your bullshit but we do not trust you never did never will. Open up your eyes and you will see that all the non-white race is change your white future by spreading our non-white seed into your white women.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-WnugLsROA84/AAAAAAAAAAI/AAAAAAAAAAA/8vCTf4sF1x8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "tdtour",
        "commentId": "UgwBa6cSY6HZscpeVC14AaABAg"
    },
    {
        "publishedAt": "2007-07-05T16:00:34.000Z",
        "likeCount": 0,
        "comment": "questo non sembra wing chun,cosi&#39;rigidi sono quelli dello shaolin",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-krK85fZqmeE/AAAAAAAAAAI/AAAAAAAAAAA/kGNfCWZUSq4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mario 86",
        "commentId": "UgykyeiWkSm168NID5R4AaABAg"
    },
    {
        "publishedAt": "2007-06-25T01:03:22.000Z",
        "likeCount": 0,
        "comment": "I heard it taste nice, havent been to china in ages to try it out. I think all those brainwashed westerners should be ashamed for disgracing asians for it, its rude and ignorant to comment on other cultures for their practices",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-NT9dbC-3DAQ/AAAAAAAAAAI/AAAAAAAAAAA/0FoY2OYEp_Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Max Turner",
        "commentId": "Ugy9x2q1noXBnc95Ya54AaABAg"
    },
    {
        "publishedAt": "2007-06-25T00:43:28.000Z",
        "likeCount": 0,
        "comment": "the 2 from 0:43 are really good",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-NT9dbC-3DAQ/AAAAAAAAAAI/AAAAAAAAAAA/0FoY2OYEp_Y/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Max Turner",
        "commentId": "Ugzts9GpIgoo1dTk2Fd4AaABAg"
    },
    {
        "publishedAt": "2007-06-21T18:43:44.000Z",
        "likeCount": 0,
        "comment": "needs more power",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-AwPREHW6Q6I/AAAAAAAAAAI/AAAAAAAAAAA/RhMTxB-Vop4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "slate1",
        "commentId": "UgxLdWziIRgFUf_JkQ14AaABAg"
    },
    {
        "publishedAt": "2007-06-16T18:33:46.000Z",
        "likeCount": 0,
        "comment": "holy crap those would hurt",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-4SS38eoOUS0/AAAAAAAAAAI/AAAAAAAAAAA/somWDOQpUdw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "SquireGaming",
        "commentId": "UgzzOsihIsYb6MaPovt4AaABAg"
    },
    {
        "publishedAt": "2007-06-13T18:24:57.000Z",
        "likeCount": 0,
        "comment": "sweet...Texas WCKFC here",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxQTIbMidcGAPHBrOJ4AaABAg"
    },
    {
        "publishedAt": "2007-06-13T18:22:08.000Z",
        "likeCount": 0,
        "comment": "LOLOLOLOL",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgykAHQrkUpR1TvU4ER4AaABAg"
    },
    {
        "publishedAt": "2007-06-13T18:21:29.000Z",
        "likeCount": 0,
        "comment": "This is beautiful everytime I see it....so calm and collected...and teaching his students...not beating them to learn...Si Gung is pretty neat.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxrGtqny_Ls6yKuDsR4AaABAg"
    },
    {
        "publishedAt": "2007-06-12T19:58:07.000Z",
        "likeCount": 0,
        "comment": "Love it. I wonder what family.",
        "videoId": "pE1DzZ7Xn9k",
        "authorThumbnail": "https://yt3.ggpht.com/-R5pF9IJcaXo/AAAAAAAAAAI/AAAAAAAAAAA/9uKw-mfoOjg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "preptic",
        "commentId": "UgxUnJaQul79XICC_Y14AaABAg"
    },
    {
        "publishedAt": "2007-06-12T18:30:42.000Z",
        "likeCount": 0,
        "comment": "There is so much going on here, especially in the chi sao of Yip Ching, way more than meets the eye. Anyone who studies Wing Chun would certainly appreciate this video.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-IyC2W5qS9Nw/AAAAAAAAAAI/AAAAAAAAAAA/MakK3G2k8wI/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Chet Hardin",
        "commentId": "UgyLQ4riPoUD47IEUpF4AaABAg"
    },
    {
        "publishedAt": "2007-06-09T01:07:36.000Z",
        "likeCount": 0,
        "comment": "I wouldn&#39;t say better......these guys are too aggressive, or in other words, very tense. \r\nBecause they&#39;re too tense, I&#39;m not sure if their fundamentals(forms) are good enough.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-Wtdym81mDgU/AAAAAAAAAAI/AAAAAAAAAAA/4lyCEjuevn8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "namiejames",
        "commentId": "UgwrouJMmYVtTvfVAYZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-06-01T22:38:26.000Z",
        "likeCount": 0,
        "comment": "Yo! that is yammy, I am starving now!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-WK_SVn0ucoA/AAAAAAAAAAI/AAAAAAAAAAA/4ohmi2t3GUY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Escape Warriors",
        "commentId": "UgyBOEUmzNcoLu3H8VR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-05-28T04:23:42.000Z",
        "likeCount": 0,
        "comment": "see everyone,white people eat dogs too!",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-8l9s9v1D7DM/AAAAAAAAAAI/AAAAAAAAAAA/9H2ZrBRYbAs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "crazysword987",
        "commentId": "UgzwQr1EqhiY0wSYOaF4AaABAg"
    },
    {
        "publishedAt": "2007-05-20T14:06:35.000Z",
        "likeCount": 0,
        "comment": "i think so",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-Icg_SzyYuuE/AAAAAAAAAAI/AAAAAAAAAAA/wl_RoBQ5Bt4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gallardo499cv",
        "commentId": "UgzrNWFnkbFK91m0mkl4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-05-19T01:41:24.000Z",
        "likeCount": 0,
        "comment": "KOOL!!! Krumping... Chinese style!!!",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-pdHmnl1fvoE/AAAAAAAAAAI/AAAAAAAAAAA/diJh55q_0Qs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "browntuka",
        "commentId": "UgzhDk2fXV7WlSpuc6p4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-05-17T09:40:58.000Z",
        "likeCount": 0,
        "comment": "Sweet Ass vid, looks so fun!!",
        "videoId": "TDy0B20kZlY",
        "authorThumbnail": "https://yt3.ggpht.com/-j6mHT2pBNxg/AAAAAAAAAAI/AAAAAAAAAAA/oraur5Juypg/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Lucas La Grange",
        "commentId": "Ugweix1mXj48jKpVdmV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-05-15T14:33:28.000Z",
        "likeCount": 0,
        "comment": "So whats this latest story from Scott Faker?",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-B6HrWB1uhpA/AAAAAAAAAAI/AAAAAAAAAAA/sMYJyiFdSRM/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Gwohsau",
        "commentId": "Ugz-N4dDwX4OGatTcsB4AaABAg"
    },
    {
        "publishedAt": "2007-05-15T10:10:22.000Z",
        "likeCount": 0,
        "comment": "You are an idiot.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugy4eUiYmHaV65BvNiV4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-05-11T16:18:44.000Z",
        "likeCount": 0,
        "comment": "Is that the Arizona ex-con Ronald Hamburger at 2:45 sec? His face seemed asymmetrical and I wonder why? May be I go ask Scott Faker, brah ha ha ha !!!",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-iQO-z-VHea4/AAAAAAAAAAI/AAAAAAAAAAA/sXuyPZO_RVk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mofotox",
        "commentId": "Ugwb3s_XTgOd-KkvSGh4AaABAg"
    },
    {
        "publishedAt": "2007-05-11T15:29:02.000Z",
        "likeCount": 0,
        "comment": "are u really happy to eat dogs thats not good",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-EMIdILzOjlQ/AAAAAAAAAAI/AAAAAAAAAAA/3bUdHytlbHc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mohamed qatawneh",
        "commentId": "UgxTxBiqt_4bFUyCYgh4AaABAg"
    },
    {
        "publishedAt": "2007-05-04T23:40:42.000Z",
        "likeCount": 0,
        "comment": "My Sifu appears at the end of this video, playing Chi Sau with a member of the Athletic Association it appears.  Sifu Jones is his name.  Nice to see him in action.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-41EYmgQjuRo/AAAAAAAAAAI/AAAAAAAAAAA/g9Kf5dZ31eY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Stephen Lafferty",
        "commentId": "UgyAXT3sr0n-sRhgUiR4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-05-04T23:34:30.000Z",
        "likeCount": 0,
        "comment": "My Sifu, Steve Woodward appears at the end of the video, along with Sigung Rawcliffe.  I trained at the Athletic Association back in February 2007, a real eye-opener for Chi Sau!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-41EYmgQjuRo/AAAAAAAAAAI/AAAAAAAAAAA/g9Kf5dZ31eY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Stephen Lafferty",
        "commentId": "UgywLih8VoX38YI5e8x4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-04-20T07:28:03.000Z",
        "likeCount": 0,
        "comment": "chris chan rules!!!!!!!",
        "videoId": null,
        "authorThumbnail": "https://yt3.ggpht.com/-eJQkBWncHRM/AAAAAAAAAAI/AAAAAAAAAAA/jcUxNq_3f3E/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "GoldenDragons888",
        "commentId": "UgxqhCK-rEQFntDQfi94AaABAg"
    },
    {
        "publishedAt": "2007-04-03T06:26:39.000Z",
        "likeCount": 0,
        "comment": "its the person not the style...i know people who do tae bo that can beat people that do other shit.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-DmO6xUZ1Dck/AAAAAAAAAAI/AAAAAAAAAAA/aSaboxP_i80/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "nel3000",
        "commentId": "Ugy8bzYHhQhnZlCWAoZ4AaABAg"
    },
    {
        "publishedAt": "2007-03-23T20:41:55.000Z",
        "likeCount": 0,
        "comment": "dude that one guy falls and spins so fast ahah omg....",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-bqOmulDsIMc/AAAAAAAAAAI/AAAAAAAAAAA/n51LFH0SRK0/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David Janovjak",
        "commentId": "UgzecP8SRPSsrEJ5FPh4AaABAg"
    },
    {
        "publishedAt": "2007-03-20T12:31:20.000Z",
        "likeCount": 0,
        "comment": "hehe... it seems none of them wants to look bad in front of the crowd.... face is a funny thing.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-0oe_81teYQQ/AAAAAAAAAAI/AAAAAAAAAAA/5cVyvT_f2yA/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "David Fisher",
        "commentId": "UgwVlwfZFq4qZJq9vgd4AaABAg"
    },
    {
        "publishedAt": "2007-03-18T21:48:09.000Z",
        "likeCount": 0,
        "comment": "nice video.. cool tricks, great editing.. 5 stars :D",
        "videoId": "FvqTcm4hC6o",
        "authorThumbnail": "https://yt3.ggpht.com/-XMDR84AqBZY/AAAAAAAAAAI/AAAAAAAAAAA/vmAZO7E3160/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Roland Deschain",
        "commentId": "Ugzf1sEV8IXWh7gnsNZ4AaABAg"
    },
    {
        "publishedAt": "2007-03-18T15:26:17.000Z",
        "likeCount": 0,
        "comment": "It&#39;s good to see good, technically sound Wing Chun. Check out Carlos Lee if you want a good laugh.",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-QVMXISmdunU/AAAAAAAAAAI/AAAAAAAAAAA/Kw2I_2rYGC8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "weixiehong",
        "commentId": "UgwGhXd4z5mCwEJ6pHJ4AaABAg"
    },
    {
        "publishedAt": "2007-03-18T01:01:42.000Z",
        "likeCount": 0,
        "comment": "chen tai chi a regional kung fu style i think and wing chun nice",
        "videoId": "PI4zVUPluXU",
        "authorThumbnail": "https://yt3.ggpht.com/-SsYeaazBL_8/AAAAAAAAAAI/AAAAAAAAAAA/1wPfxHvW8sw/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "markohu",
        "commentId": "Ugzy7tMQrjV_igr97Gh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-03-03T22:31:22.000Z",
        "likeCount": 0,
        "comment": "omfg that looked really painful!!!",
        "videoId": "zHSfh87GnFc",
        "authorThumbnail": "https://yt3.ggpht.com/-vUnXYigOy08/AAAAAAAAAAI/AAAAAAAAAAA/l1FxtoxkZ7E/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "kaylynnemartens07",
        "commentId": "UgwmKqBZW26ap7mVM3V4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-03-02T01:09:33.000Z",
        "likeCount": 0,
        "comment": "nice and fast!!",
        "videoId": "QfAQkufmjuA",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "UgwVxEqLSSBEjvJCZf54AaABAg"
    },
    {
        "publishedAt": "2007-02-28T21:18:23.000Z",
        "likeCount": 0,
        "comment": "This just goes to show that Leung Ting nor William Cheung possess the only &quot;Traditional&quot; wing chun.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-jxUwFUthyzI/AAAAAAAAAAI/AAAAAAAAAAA/bxEHhtj50Cs/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "jlittle1978",
        "commentId": "UgyNnvFm992adtrlAfx4AaABAg"
    },
    {
        "publishedAt": "2007-02-02T19:53:08.000Z",
        "likeCount": 0,
        "comment": "The footwork should be kept simple and direct. That is why it seems to simple and seems like not enough.",
        "videoId": "gmWbLpUty9w",
        "authorThumbnail": "https://yt3.ggpht.com/-OAEZ2S8ytoE/AAAAAAAAAAI/AAAAAAAAAAA/pqfUOYeb3Wc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RM Puig",
        "commentId": "Ugznas8zCv-_xwNa-Op4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-01-31T04:02:25.000Z",
        "likeCount": 0,
        "comment": "Thanks, recognise Tanner Hall before he was famous?",
        "videoId": "60jGMzGQ3IE",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgxQw8jwGxJ-6A5eIKh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2007-01-30T21:14:02.000Z",
        "likeCount": 0,
        "comment": "Thanks for all of the letters people! Getting better each day. For those that do not know what happened, I was stopped in traffic on the freeway and a van going 100kph hit me from behind. Doctors said if it wasn&#39;t for the physical conditioning of Wing Chun I would not be here.",
        "videoId": "I4JZGgwmoi0",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugwuax1LwSaeWsCfBCd4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2007-01-30T05:38:03.000Z",
        "likeCount": 0,
        "comment": "Dude,What happen?",
        "videoId": "I4JZGgwmoi0",
        "authorThumbnail": "https://yt3.ggpht.com/-exeVxAhYDYY/AAAAAAAAAAI/AAAAAAAAAAA/V66kdw3tyyU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Sheng",
        "commentId": "UgyRTSDhNWgXML9lvQh4AaABAg"
    },
    {
        "publishedAt": "2007-01-06T03:44:29.000Z",
        "likeCount": 0,
        "comment": "The third match, the man in the black shirt has some skill.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-FnYsKt6FfZY/AAAAAAAAAAI/AAAAAAAAAAA/Vfrc6uvlZQE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dan F",
        "commentId": "UgyFm5RB5-6TrCE6My94AaABAg"
    },
    {
        "publishedAt": "2007-01-06T03:34:11.000Z",
        "likeCount": 0,
        "comment": "This only confirms that WC has been evolving over time and becoming better. That stuff on the video clip might have been old old style WC that never evolved. The WC that you see now a days in Hong Kong is way better.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-OAEZ2S8ytoE/AAAAAAAAAAI/AAAAAAAAAAA/pqfUOYeb3Wc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RM Puig",
        "commentId": "Ugyiub-mjUClpFKJ6tR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-12-15T03:39:32.000Z",
        "likeCount": 0,
        "comment": "love nena? in the backround. What year was this video made? O-zone Frenzy, so I guess 2004? Anyhow Looking forward to this years snowkiting season, keep the good movies coming. Grüße aus canada",
        "videoId": "TDy0B20kZlY",
        "authorThumbnail": "https://yt3.ggpht.com/-wTyrtL2QUh8/AAAAAAAAAAI/AAAAAAAAAAA/dU_VbEvIDwY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Alex K",
        "commentId": "Ugye5rVa93EVDq4Z3Jh4AaABAg"
    },
    {
        "publishedAt": "2006-12-15T03:36:46.000Z",
        "likeCount": 0,
        "comment": "movies like this surround my snowboard heart with warmth. respect to this kids air.",
        "videoId": "LEAexG6YkX4",
        "authorThumbnail": "https://yt3.ggpht.com/-wTyrtL2QUh8/AAAAAAAAAAI/AAAAAAAAAAA/dU_VbEvIDwY/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Alex K",
        "commentId": "UgzzEqiR3rW6ZFk4P3t4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-12-10T02:18:05.000Z",
        "likeCount": 0,
        "comment": "I&#39;ve been inside this room!!! and chi-sau&#39;d, good times   :)",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-ykvbxyLHha4/AAAAAAAAAAI/AAAAAAAAAAA/j0M4QnFNtqQ/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "nyman188891",
        "commentId": "UgxWoXg6ezn98i0-C1R4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-12-09T15:11:31.000Z",
        "likeCount": 0,
        "comment": "i&#39;d call that gor sau! quite tense eh.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-tqAA7K0Wt5E/AAAAAAAAAAI/AAAAAAAAAAA/8_Zgj03JB60/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Dan Chalmers",
        "commentId": "UgwDKFDZwBv5TsMn57B4AaABAg"
    },
    {
        "publishedAt": "2006-12-09T06:26:40.000Z",
        "likeCount": 0,
        "comment": "Rabies puts hair on you chest. But thank you for your concern.",
        "videoId": "cWf6e4abq_4",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyU1uWtCrfojd4ev6R4AaABAg"
    },
    {
        "publishedAt": "2006-12-03T22:49:34.000Z",
        "likeCount": 0,
        "comment": "Very insteresting",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-l-dBY4iECw0/AAAAAAAAAAI/AAAAAAAAAAA/IlO0M2B8QeU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "pedrong",
        "commentId": "Ugysm1E8cmYDdWt0nL94AaABAg"
    },
    {
        "publishedAt": "2006-11-29T02:59:00.000Z",
        "likeCount": 0,
        "comment": "yeah....I&#39;m just a beginning Biu Jee student and that looks pretty bad",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxmZg1ULXs_xZHfBKZ4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-11-24T07:56:38.000Z",
        "likeCount": 0,
        "comment": "Interesting....thank you!",
        "videoId": "RknpDfZWgFs",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "UgyDMl6CrijthVeUqgl4AaABAg"
    },
    {
        "publishedAt": "2006-11-24T03:00:48.000Z",
        "likeCount": 0,
        "comment": "One looks like a method that i&#39;d seen in the late 80s by a man name Ma Hon Chau (Eddie Ma of Z zen)in Hong Kong.I could be wrong.",
        "videoId": "tNm8HW8mL5c",
        "authorThumbnail": "https://yt3.ggpht.com/-exeVxAhYDYY/AAAAAAAAAAI/AAAAAAAAAAA/V66kdw3tyyU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Sheng",
        "commentId": "Ugx7WNltA4iiXazgAhF4AaABAg"
    },
    {
        "publishedAt": "2006-11-20T22:15:23.000Z",
        "likeCount": 0,
        "comment": "that was totally uncalled for. if you have the opportunity, he will know. whoever that was  didn&#39;t need to grab his neck like that. chi sao is training not a real fight. what an idiot. i doubt if he will get much knowledge with his &quot;rambo&quot; attitude. punk.",
        "videoId": "5XxtKwf_PdE",
        "authorThumbnail": "https://yt3.ggpht.com/-nBksm2L7al4/AAAAAAAAAAI/AAAAAAAAAAA/UEzKeK8fVNk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "blurfist",
        "commentId": "UgxFpf57KgWm3hiGH1B4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-11-17T05:20:46.000Z",
        "likeCount": 0,
        "comment": "nice Dai Jeung!!!",
        "videoId": "5XxtKwf_PdE",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxF1jM9Dro2Vdq3V8l4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-11-16T13:17:11.000Z",
        "likeCount": 0,
        "comment": "u serious? haha. that &#39;sifu&#39; does chi sao worse than i do. extremely stiff and a lot of use of strength. also crosses his arms a lot.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-gyZBq6W73qk/AAAAAAAAAAI/AAAAAAAAAAA/wIgLe6cYRgc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Jim Liu",
        "commentId": "UgyIHfnqaXn1fbecBs94AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-11-15T04:44:04.000Z",
        "likeCount": 0,
        "comment": "I&#39;m practicing in Texas under Jo Gau Jason and Sergio Solis.  I&#39;m about 3 months into Biu Gee and its freaking awesome!!!  Can&#39;t wait to have my first seminar with Sifu regarding Biu Gee stuff...should be sweet.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxlSoHuheIisto0EY94AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-11-14T03:50:03.000Z",
        "likeCount": 0,
        "comment": "DUDE..just got yer reply today!! my Jo Gau DAN ANDERSON is under  MASTER HEIMBERGER!!!YEW!!! close to the source ...but yer closer...can&#39;t wait to see more...are you praticing in Utah? i&#39;m over here in California..just started Chum Kiu..how about you?\r\nlate! MYKL",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "Ugxlxn9HkuDBcPZ0amh4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-11-14T02:38:53.000Z",
        "likeCount": 0,
        "comment": "I&#39;m part of the Wing Chun Kung Fu Council Texas Headquarters under Jason and Sergio Solis.  In fact, Sifu Heimberger is coming down in mid december for some training!!!!",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgxaQZC0JsTVMgRulzd4AaABAg"
    },
    {
        "publishedAt": "2006-11-11T21:52:49.000Z",
        "likeCount": 0,
        "comment": "I bet thats a hellva rush i would never do it, But it awesome to watch.",
        "videoId": "LEAexG6YkX4",
        "authorThumbnail": "https://yt3.ggpht.com/-exeVxAhYDYY/AAAAAAAAAAI/AAAAAAAAAAA/V66kdw3tyyU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Sheng",
        "commentId": "UgyWORzcL7MNxx0jSZF4AaABAg"
    },
    {
        "publishedAt": "2006-11-10T17:47:47.000Z",
        "likeCount": 0,
        "comment": "Black shirt was from the USWC team. He had been training for three months. The guy in white called himself a Sifu and was from the UK.",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwOrsU6kF_Q02ITmD14AaABAg"
    },
    {
        "publishedAt": "2006-11-10T03:38:59.000Z",
        "likeCount": 0,
        "comment": "Man, that was pretty awesome.I wished i could have been there,looked like good group of guys.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-exeVxAhYDYY/AAAAAAAAAAI/AAAAAAAAAAA/V66kdw3tyyU/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Mike Sheng",
        "commentId": "UgwjQACYHoThj-B4cOR4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-11-07T02:14:59.000Z",
        "likeCount": 0,
        "comment": "dude...where do you practice?? recently had a seminar with MASTER HEIMBERGER in Cali.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "Ugw0Z8Bsz0ZO3P12j214AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-10-31T17:33:53.000Z",
        "likeCount": 0,
        "comment": "Which different schools they represent?",
        "videoId": "Qlf0lDz9moU",
        "authorThumbnail": "https://yt3.ggpht.com/-iQO-z-VHea4/AAAAAAAAAAI/AAAAAAAAAAA/sXuyPZO_RVk/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "mofotox",
        "commentId": "Ugxwm5eRvp9v3052Vx94AaABAg"
    },
    {
        "publishedAt": "2006-10-31T00:58:21.000Z",
        "likeCount": 0,
        "comment": "I think so, but I am not sure. I shot this footage in 1999. I do remember a Sydney school being there but I do not know if it was this visit.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgzXyQU52Vg4uqzo4914AaABAg"
    },
    {
        "publishedAt": "2006-10-30T07:54:08.000Z",
        "likeCount": 0,
        "comment": "cool ive got a video of his brother ip chun in my favourites hes very good",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-Zc-q6cmohRg/AAAAAAAAAAI/AAAAAAAAAAA/UQPBWt4x6B4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "chris gilding",
        "commentId": "UgxwKkpMbYcvM66Gdfl4AaABAg"
    },
    {
        "publishedAt": "2006-10-29T19:05:09.000Z",
        "likeCount": 0,
        "comment": "Yes, this is his school.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyD5LMYX5JyeuDxaeN4AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-10-29T17:55:48.000Z",
        "likeCount": 0,
        "comment": "Anyone know the people being introduced?",
        "videoId": "iAGX1qUwjus",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgwlJkqaT9cXe5uc7JV4AaABAg"
    },
    {
        "publishedAt": "2006-10-29T13:12:52.000Z",
        "likeCount": 0,
        "comment": "is the guy in the burgundy top ip ching",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-Zc-q6cmohRg/AAAAAAAAAAI/AAAAAAAAAAA/UQPBWt4x6B4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "chris gilding",
        "commentId": "UgxqSHcod0p1fPpwTwd4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-10-27T21:45:26.000Z",
        "likeCount": 0,
        "comment": "Ip Ching is the best...recognize Si Gung IP Ching in this and Sifu Ron Heimberger in the other one as well as Sifu Sean Meyers....need to see more!!!!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "UgysLYE30DPqPu1k3-54AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-10-27T03:56:03.000Z",
        "likeCount": 0,
        "comment": "great video my friend thanks for posting!",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-8PtL4yaWbM4/AAAAAAAAAAI/AAAAAAAAAAA/gQoEqC8R1zE/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Herooo",
        "commentId": "UgxT02HDGboQu4VS_Wx4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-10-26T03:05:36.000Z",
        "likeCount": 0,
        "comment": "Great Video!  Probably one of the best with Wing Chun Chi Sao...the best thing is my SiGung and Sifu, MASTER RON HEIMBERGER, are in it!!!!   Very nice.  Hope to see some more.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-oqli6BM7u_A/AAAAAAAAAAI/AAAAAAAAAAA/ZTrO97LGOTc/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "prchdaword",
        "commentId": "Ugy-pc8Uraf5scCJhUR4AaABAg"
    },
    {
        "publishedAt": "2006-10-21T18:43:31.000Z",
        "likeCount": 0,
        "comment": "Yes. We were there for demonstration and competition. Several schools from around the world attended.",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyUBwC1pVP1UoaZgh54AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-10-21T12:44:00.000Z",
        "likeCount": 0,
        "comment": "MASTER HEIMBERGER is in this one..good schtuff!! are you a praticioner?",
        "videoId": "BkETsfqp5co",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "Ugzr29bMqtI6sOLuK_54AaABAg"
    },
    {
        "publishedAt": "2006-10-18T22:10:56.000Z",
        "likeCount": 0,
        "comment": "I shoot all of my videos myself. This was in mainland China. Guangdong province, Fat san (Fu Shan) city.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "Ugz-cLU939U2BHBaKN14AaABAg"
    },
    {
        "color": "rgb(54, 162, 235)",
        "publishedAt": "2006-10-18T21:56:00.000Z",
        "likeCount": 0,
        "comment": "Good eye. Recognize anyone else? More coming soon...",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-BtWgsj7pclE/AAAAAAAAAAI/AAAAAAAAAAA/114ywxrfdv8/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "ph8tel",
        "commentId": "UgyfSGLLu3JoCX18b_l4AaABAg"
    },
    {
        "color": "rgb(255, 99, 132)",
        "publishedAt": "2006-10-18T03:17:30.000Z",
        "likeCount": 0,
        "comment": "WOW this is Ip Ching, Wing Chun Kung Fu Council. Thanks..got any more!!!!",
        "videoId": "0FK0VRk1EQM",
        "authorThumbnail": "https://yt3.ggpht.com/-znQTp52zWfA/AAAAAAAAAAI/AAAAAAAAAAA/9SgzJ2Dmq0U/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "RyppR",
        "commentId": "UgyFuYWMuLmRCHKXD1d4AaABAg"
    },
    {
        "publishedAt": "2006-10-14T22:25:31.000Z",
        "likeCount": 0,
        "comment": "That was interesting.  Thanks for posting it.",
        "videoId": "C6KjJVA_7Lo",
        "authorThumbnail": "https://yt3.ggpht.com/-Q1-917r3TVk/AAAAAAAAAAI/AAAAAAAAAAA/V2e9a5cQaA4/s28-c-k-no-mo-rj-c0xffffff/photo.jpg",
        "author": "Tazz77",
        "commentId": "UgzwT2aTuTJg3nw7aaB4AaABAg"
    }
]
module.exports = comments