import React, {Component} from 'react'

class FeaturedTitle extends Component {

    render() {
      return (<div className="popular">
				<h3>Popular Videos</h3>
				<p><img src="http://ph8tel.com/images/l1.png" alt="likes"></img> Over 1,000,000 views<a href="https://www.youtube.com/user/ph8tel/about">*</a></p>
				<div className="clear"> </div>
              </div>)
    }
}

export default FeaturedTitle