import React, { Component } from 'react';
import './footer.css'
class Footer extends Component {
    render() {
        return (
            <div className="footer">

            <div className="wrap"> 

				<div className="box1">
				<h4>Policy &amp; Terms</h4>
						<ul>
							<li><a href="tems.html">Terms &amp; Conditions</a></li>
							<li><a href="privacy.html">Privacy Policy</a></li>

						</ul>
				</div>
				
				<div className="box1">
					<div className="hide-box">
				<h4>About Us</h4>
						<ul>
							<li><a href="contact.html">Contact Us</a></li>
							<li><a href="sitemap.html">Site Map</a></li>
						</ul>
				</div>
					</div>
				<div className="box1">
				<h4>Stay in touch on</h4>
						<ul className="social">
							<li><img src="http://ph8tel.com/images/facebook.png"  alt="social media" title="facebook"></img><a href="https://www.facebook.com/kyter">Facebook</a></li>
							<li><img src="http://ph8tel.com/images/instagram.png" alt="social media" title="instagram"></img><a href="https://www.instagram.com/ph8tel/">Instagram</a></li>
							<li><img src="http://ph8tel.com/images/youtube.png" alt="social media" title="youtube"></img><a href="https://youtube.com/ph8tel">YouTube</a></li>
						</ul>
				</div>
				<div className="clear"> </div>
			</div>
            </div>
        )
    }
}

export default Footer