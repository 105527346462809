import React, { Component } from 'react';
const allComments = require('../../comments')
class Comments extends Component {
    state = {
        comments: allComments.filter( comment => comment.videoId === this.props.videoId )
    }
    render() {
        return this.state.comments.map( comment => (
            <div>
                <h2>{comment.author}</h2>
                <img src={comment.authorThumbnail} alt={comment.author}></img>
                <p>{comment.comment}</p>
            </div>
        ))
    }
}
export default Comments