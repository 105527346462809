import React, { Component } from 'react';
import VideoBox from './components/vidbox/VideoBox'
import SearchBar from './components/searchbar/SearchBar'
import FeaturedBox from './components/featured/FeaturedBox'
import FeaturedTitle from './components/featured/FeaturedTitle'
import RecentTitle from './components/recent/RecentTitle'
import Footer from './components/footer/Footer'
import './App.css';

const data = require('./data')

class App extends Component {
    state = {
        allVideos: data,
        currentVideos: data[0],
        currentKey: 0,
        open: false
    }

    pageChange(e) {
      e.preventDefault()
      if (e.target.id === 'next') {
      this.setState({
        currentVideos: this.state.allVideos[this.state.currentKey],
        currentKey: this.state.currentKey <= 81 ? this.state.currentKey + 9 : 0
       })
      }
    }

    render() {

        return (
          <div className = "App" >
            <div className = "stage" >
              <div>
                <SearchBar />
                <VideoBox id = "vid-box" videos={ this.state.currentVideos } allVideos={this.state.allVideos}/> 
                <div>
                  <button id = "next" onClick={this.pageChange.bind(this)}>NEXT PAGE</button>
                </div>
              </div> 
              <div>
                <FeaturedTitle />
                <FeaturedBox />
                <RecentTitle />
              </div> 
            </div> 
            <Footer />
          </div>

        );
    }
}

export default App;