import React, { Component } from 'react';

class FeaturedBox extends Component {
    render() {
        return (
            <div className="grid1">
						<h3>Barbed Wire Removal with a Torn Bicept</h3>
						<iframe width="100%" title="Barbed Wire Removal with a Torn Bicept" src="https://www.youtube.com/embed/QsjpYurZ9zc?rel=0&amp;showinfo=0" frameBorder="0" allowFullScreen=""></iframe>
						<div className="time1">
							<span>2:50</span>
						</div>
						
						<div className="grid-info">
							<div className="video-share">
								<ul>
									<li><img src="http://ph8tel.com/images/likes.png" alt="social media" title="links"></img></li>
									<li><img src="http://ph8tel.com/images/link.png" alt="social media" title="Link"></img></li>
									<li><img src="http://ph8tel.com/images/views.png" alt="social media" title="Views"></img></li>
								</ul>
							</div>
							<div className="video-watch">
								<button> Comment</button>
							</div>
							<div className="clear"> </div>
							<div className="lables">
								
							</div>
						</div>
					</div>
        )
    }
}

export default FeaturedBox