import React, { Component } from 'react';
import Comments from '../comments/Comments.js'
import './vidbox.css'
class VideoBox extends Component {
	constructor(props){ 
		super(props);
        this.state = {props}
		this.state.singlePlayer = false;
		this.state.featuredVideo = null;
}

    featureVideo(e){
		e.preventDefault()
        this.setState({
			singlePlayer: true,
		    featuredVideo: e.target.id
		})
	}

	closeFeatured(e){
		e.preventDefault()
		this.setState({
			singlePlayer: false,
			featureVideo: null
		})
	}

    render() {
		if (this.state.singlePlayer) {
			return (
		    <div>
	     	  <button onClick={this.closeFeatured.bind(this)}>Back</button>
              <iframe key={this.state.featuredVideo} title={this.state.featuredVideo} width="560" height="315"  src={ "https://www.youtube.com/embed/" + this.state.featuredVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
			  <Comments videoId={this.state.featuredVideo} /> 
			 </div>
			)
		} else {
        return this.props.videos.map( video => (
            <div className="grid" key={video.videoId}>
				<h4>{video.title.length > 42 ? video.title.slice(0,30) : video.title}</h4>

					<img onClick={this.featureVideo.bind(this)} id={video.videoId} src={video.thumbnails.medium.url} alt={video.title}></img>
					<div className="grid-info">
						<div className="video-share">
							<ul>
                        		<li><a href={ "https://www.youtube.com/watch?v=" + video.videoId}><img src="http://ph8tel.com/images/likes.png" alt="likes" title="links"></img></a></li>
								<li><a href={"https://www.youtube.com/watch?v=" + video.videoId}><img src="http://ph8tel.com/images/link.png" alt="sentiment analysis" title="Link"></img></a></li>
								<li><a href={"https://www.youtube.com/watch?v=" + video.videoId}><img src="http://ph8tel.com/images/views.png" alt="watch on youtube" title="Views"></img></a></li>
							</ul>
						</div>
						<div className="video-watch">
							<a href={"https://www.youtube.com/watch?v=" + video.videoId}> Watch</a>
						</div>

					</div>

			</div>
		))
		}
		 
		
    }
}

export default VideoBox