import React, { Component } from 'react';

class RecentTitle extends Component {
    render() {
        return (
            <div className="Recent-Vodeos">
            <h3>Recent-Videos</h3>
            <div className="video1">
                <iframe title="Trail 18 part 1" src="https://www.youtube.com/embed/01C8dLzr7Kc?rel=0&amp;showinfo=0" frameBorder="0" allowFullScreen=""></iframe>
                <span>Trail 18 part 1</span>
                <p>The connector between M10 and Elk Mountain Road</p>
                <div className="clear"> </div>
            </div>
            <div className="video1 video2">
                <iframe title="Trail 18 part 2" src="https://www.youtube.com/embed/bWUJUoIIinI?rel=0&amp;showinfo=0" frameBorder="0" allowFullScreen=""></iframe>
                <span>Trail 18 part 2</span>
                <p>Ariiving onto Elk Mountain Road from the trail 20 split off.</p>
                <div className="clear"> </div>
            </div>

        </div> 
        )
    }
}

export default RecentTitle