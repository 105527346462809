import React, { Component } from 'react';

class SearchBar extends Component {

    render() {
        return (
                <div className="searchbar">
					<div className="search-left">
					</div>
					<div className="search-right">

					</div>
					<div className="clear"> </div>
                </div>
                );
    }
}
export default SearchBar